import { Box } from '@chakra-ui/react';
import ChampionnatFormuleForm from 'features/championnats/components/ChampionnatFormuleForm';
import { useState } from 'react';
import { CHAMPIONNAT_FORMULES_DEFAULT } from 'features/championnats/championnat.constants';
import { RoutePath } from 'router/routes.paths';
import BPCPageHeading from 'common/components/BPCPageHeading';

export default function OutilFormulePage() {
  const [methodeCalculPoints, setMethodeCalculPoints] = useState(
    CHAMPIONNAT_FORMULES_DEFAULT[0].formule!,
  );
  return (
    <Box>
      <BPCPageHeading
        helmetName="Formule"
        title="Testeur de formule"
        breadcrumbItems={[
          { to: RoutePath.home, name: 'Home' },
          { to: RoutePath.outils, name: 'Outils' },
        ]}
      />
      <ChampionnatFormuleForm
        methodeCalculPoints={methodeCalculPoints}
        onSubmit={setMethodeCalculPoints}
      />
    </Box>
  );
}
