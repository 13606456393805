import {
  TournoiPlayer,
  TournoiPlayerRealtime,
  TournoiRealtime,
} from 'features/tournois/tournoi.types';
import { Player } from './players.types';
import { deleteUndefinedKeys } from 'common/common.helper';
import { User } from 'firebase/auth';
import { getAudioPlayerName } from './players.helpers';
import { computeMoneyFromPriceList } from 'features/tournois/tournoi.helper';

export function mapUserToPlayerToCreate(user?: User): Omit<Player, 'id'> {
  const { email, displayName, photoURL } = user || {};
  return {
    email: email ?? '',
    name: displayName ?? '',
    photo: photoURL ?? undefined,
  };
}

export function mapPlayerToNewTournoiPlayer(
  player: Pick<Player, 'id' | 'photo' | 'name' | 'nameForAudio'>,
  tournoiBuyIn: number,
): TournoiPlayer {
  return {
    id: player.id,
    name: player.name,
    nameForAudio: getAudioPlayerName(player),
    photo: player.photo,
    depense: tournoiBuyIn,
    nbRecave: 0,
    nbKills: 0,
    killedBy: [],
  };
}

export function mapTournoiPlayerToTournoiPlayerRealtime({
  name: _name,
  photo: _photo,
  depense: _depense,
  nameForAudio: _nameForAudio,
  nbRecave: _nbRecave,
  nbKills: _nbKills,
  killedBy: _killedBy,
  prizeInEuro: _prizeInEuro,
  prizeInPercent: _prizeInPercent,
  ...restPlayer
}: TournoiPlayer): TournoiPlayerRealtime {
  deleteUndefinedKeys(restPlayer);
  return restPlayer;
}

export function mapTournoiPlayerRealtimeToTournoiPlayer(
  player: TournoiPlayerRealtime,
  playerList: Player[],
  tournoi: TournoiRealtime,
): TournoiPlayer {
  const { montantBuyIn, killsHistory, prizePool } = tournoi;
  const playerInList = playerList.find(({ id }) => id === player.id);

  const { nbRecave, nbKills, killedBy } = (killsHistory || []).reduce<
    Pick<TournoiPlayer, 'nbRecave' | 'nbKills' | 'killedBy'>
  >(
    (acc, { killerId, victimeId, isRecave }) => {
      if (!victimeId) {
        return acc;
      }
      if (killerId === player.id) {
        acc.nbKills = acc.nbKills + 1;
      }
      if (victimeId === player.id) {
        if (isRecave) {
          acc.nbRecave = acc.nbRecave + 1;
        }
        acc.killedBy.push(killerId);
      }
      return acc;
    },
    {
      nbRecave: 0,
      nbKills: 0,
      killedBy: [],
    },
  );

  const priceIndex =
    prizePool && player.position && player.position <= prizePool.length
      ? player.position - 1
      : undefined;

  return {
    ...player,
    nbRecave,
    nbKills,
    killedBy,
    name: playerInList?.name ?? '',
    photo: playerInList?.photo ?? '',
    depense: montantBuyIn * (nbRecave + 1),
    nameForAudio: playerInList ? getAudioPlayerName(playerInList) : '',
    prizeInPercent:
      priceIndex !== undefined && prizePool ? prizePool[priceIndex] : undefined,
    prizeInEuro:
      priceIndex !== undefined && prizePool
        ? computeMoneyFromPriceList({
            prices: prizePool,
            priceIndex,
            partialTournoi: tournoi,
          })
        : undefined,
  };
}
