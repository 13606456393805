import {
  VStack,
  Container,
  Heading,
  Flex,
  Divider,
  Box,
  Text,
  SimpleGrid,
} from '@chakra-ui/react';
import { BPCCard } from 'common/components/BPCCard';
import Loader from 'common/components/Loader';
import useFirebaseAuthentication from 'features/auth/useFirebaseAuthentication';
import CreerJoueurButton from 'features/players/components/CreerJoueurButton';
import {
  updatePlayer,
  useGetPlayerByUserId,
  useGetPlayersList,
} from 'features/players/players.api';
import { mapUserToPlayerToCreate } from 'features/players/players.mappers';
import { Player } from 'features/players/players.types';
import EditProfileForm from 'features/user/components/EditProfileForm';
import { useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Navigate, useNavigate } from 'react-router-dom';
import ConnectedLayoutHeader from 'router/layouts/ConnectedLayoutHeader';
import { RoutePath } from 'router/routes.paths';

//Cette page sert à lier un utilisateur authentifié à un player de la base de données
// 2 cas:
// - je me lie à un player déjà existant et qui n'a pas déjà de compte associé
// - je créé un nouveau player
export default function EditIncompleteProfilePage() {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const navigate = useNavigate();
  const { firebaseAuthUser } = useFirebaseAuthentication();
  const { email, uid, displayName, photoURL } = firebaseAuthUser || {};
  const { player: user, isLoading: isUserLoading } = useGetPlayerByUserId(
    firebaseAuthUser?.uid,
  );

  const [playerSelected, setPlayerSelected] = useState<Player>();
  const { data: players, isLoading: isPlayersLoading } = useGetPlayersList();
  const playersNotLinked = useMemo(
    () =>
      players.filter(
        ({ userAccountId, role }) => !userAccountId && role !== 'admin',
      ),
    [players],
  );

  if (isUserLoading || isPlayersLoading) {
    return <Loader fullScreen />;
  }
  if (user) {
    return <Navigate to={RoutePath.home} />;
  }

  return (
    <>
      <ConnectedLayoutHeader
        position={'sticky'}
        top={0}
        zIndex={3}
        mb={4}
        user={mapUserToPlayerToCreate(firebaseAuthUser)}
      />
      <Container maxW="md">
        <Helmet>
          <title>BPC - profil</title>
        </Helmet>
        <VStack py={20} rounded="md">
          <Heading size={'md'} textTransform={'uppercase'} mb={4}>
            Compléter le profil
          </Heading>
          {!playerSelected && (
            <>
              <Text size={'sm'} mb={2}>
                Sélectionne toi dans la liste
              </Text>
              <SimpleGrid spacing={2} columns={2}>
                {playersNotLinked.map((p, i) => (
                  <BPCCard
                    key={i}
                    avatarProps={{ name: p.name, src: p.photo }}
                    title={p.name}
                    onClick={() => setPlayerSelected(p)}
                  />
                ))}
              </SimpleGrid>
              <Flex width={'100%'} py={3} alignItems={'center'}>
                <Divider />
                <Box as="span" px={2}>
                  ou
                </Box>
                <Divider />
              </Flex>
              <CreerJoueurButton
                onSubmit={(newUser) => {
                  setIsLoading(true);
                  setErrorMessage(undefined);
                  updatePlayer({
                    ...newUser,
                    userAccountId: uid!,
                    role: 'user',
                  })
                    .then(() => navigate('/'))
                    .finally(() => setIsLoading(false));
                }}
                playerToComplete={mapUserToPlayerToCreate(firebaseAuthUser)}
                allowMultipleCreation={false}
              />
            </>
          )}
          {playerSelected && (
            <>
              <EditProfileForm
                user={{
                  ...playerSelected,
                  email: email ?? '',
                  name: displayName ?? playerSelected.name,
                  photo: photoURL ?? undefined,
                }}
                onSubmit={(newUser) => {
                  setErrorMessage(undefined);
                  setIsLoading(true);

                  updatePlayer({
                    ...newUser,
                    id: playerSelected.id,
                    userAccountId: uid!,
                    role: 'user',
                  })
                    .then(() => navigate('/'))
                    .finally(() => setIsLoading(false));
                }}
                isLoading={isLoading}
                errorMessage={errorMessage}
                submitButtonLabel="Valider la création du profil"
                onCancel={() => {
                  window.location.reload();
                }}
              />
            </>
          )}
        </VStack>
      </Container>
    </>
  );
}
