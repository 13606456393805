import { Box, Heading, StyleProps } from '@chakra-ui/react';
import { mapIpadRemToVh } from 'features/tournois/live/tournoi.live.helpers';
import { PropsWithChildren } from 'react';

export default function BPCCardRow({
  titre,
  titreSize,
  children,
  jumbo,
  contentStyleProps,
  ...restStyleProps
}: PropsWithChildren<{
  titre: string;
  titreSize?: string;
  jumbo?: boolean;
  contentStyleProps?: StyleProps;
}> &
  StyleProps) {
  return (
    <Box {...restStyleProps}>
      <Heading
        size={titreSize || jumbo ? undefined : 'xs'}
        fontSize={titreSize ?? mapIpadRemToVh(0.875)}
        textTransform="uppercase"
      >
        {titre}
      </Heading>
      <Box pt="1" fontSize={jumbo ? 'xl' : 'sm'} {...contentStyleProps}>
        {children}
      </Box>
    </Box>
  );
}
