import {
  Card,
  CardBody,
  StyleProps,
  Stack,
  VStack,
  Avatar,
  Text,
  AvatarProps,
  AvatarBadgeProps,
  AvatarBadge,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
} from '@chakra-ui/react';
import { FC } from 'react';
import { BiDotsVerticalRounded } from 'react-icons/bi';

import { useNavigate } from 'react-router-dom';

export const BPCCard: FC<
  {
    linkTo?: string;
    avatarProps: AvatarProps;
    avatarBadgeProps?: AvatarBadgeProps;
    title: string;
    subTitles?: string[];
    menuItems?: { name: string; color?: string; onClick: () => void }[];
    onClick?: () => void;
  } & StyleProps
> = ({
  linkTo,
  avatarProps,
  avatarBadgeProps,
  title,
  subTitles = [],
  menuItems,
  onClick,
  ...restStyleProps
}) => {
  const navigate = useNavigate();

  return (
    <Card
      cursor={linkTo || onClick ? 'pointer' : undefined}
      onClick={() => {
        if (onClick) {
          onClick();
        }
        if (!linkTo) {
          return;
        }
        if (linkTo.substring(0, 4) === 'http') {
          window.location.href = linkTo;
        } else {
          navigate(linkTo);
        }
      }}
      {...restStyleProps}
    >
      <CardBody position={'relative'}>
        {menuItems && (
          <Menu>
            <MenuButton
              onClick={(e) => e.stopPropagation()}
              as={IconButton}
              icon={<BiDotsVerticalRounded />}
              variant={'ghost'}
              colorScheme="gray"
              position={'absolute'}
              top={0}
              right={0}
            />
            <MenuList>
              {menuItems.map(({ name, onClick, color }, i) => (
                <MenuItem
                  key={i}
                  onClick={(e) => {
                    e.stopPropagation();
                    onClick();
                  }}
                  color={color}
                >
                  {name}
                </MenuItem>
              ))}
            </MenuList>
          </Menu>
        )}
        <Stack alignItems={'center'} spacing="4">
          <Avatar size="xl" {...avatarProps}>
            {avatarBadgeProps && (
              <AvatarBadge boxSize="1.25em" {...avatarBadgeProps} />
            )}
          </Avatar>
          <VStack alignItems={'center'} textAlign={'center'} spacing="0">
            <Text textTransform={'uppercase'}>{title}</Text>
            {subTitles.map((subTitle, i) => (
              <Text key={i} fontSize="sm">
                {subTitle}
              </Text>
            ))}
          </VStack>
        </Stack>
      </CardBody>
    </Card>
  );
};
