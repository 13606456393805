import {
  VStack,
  Flex,
  Divider,
  Box,
  Container,
  Center,
  Heading,
} from '@chakra-ui/react';
import { BPCLink } from 'common/components/BPCLink';
import BPCLogo from 'common/icons/BPCLogo';
import GoogleLoginButton from 'features/auth/components/GoogleLoginButton';
import SignUpForm from 'features/auth/components/SignUpForm';
import { Helmet } from 'react-helmet';
import { RoutePath } from 'router/routes.paths';

export default function SignUpPage() {
  return (
    <Container maxW="md">
      <Helmet>
        <title>BPC - Inscription</title>
      </Helmet>
      <VStack py={20} rounded="md">
        <Center>
          <BPCLogo width="50%" />
        </Center>
        <Heading pb={2}>Créer un compte</Heading>

        <GoogleLoginButton />

        <Flex width={'100%'} py={3} alignItems={'center'}>
          <Divider />
          <Box as="span" px={2}>
            ou
          </Box>
          <Divider />
        </Flex>

        <SignUpForm />
        <Box>
          Déjà un compte ?&nbsp;
          <BPCLink variant="link" to={RoutePath.login}>
            Se connecter
          </BPCLink>
        </Box>
      </VStack>
    </Container>
  );
}
