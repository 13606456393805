import Loader from 'common/components/Loader';
import { useGetChampionnatById } from 'features/championnats/championnat.api';
import { useNavigate, useParams } from 'react-router-dom';
import { NotFound } from './ErrorPage';
import { ChampionnatDetail } from 'features/championnats/components/ChampionnatDetail';
import { RoutePath } from 'router/routes.paths';
import BPCPageHeading from 'common/components/BPCPageHeading';
import { FaEdit } from 'react-icons/fa';
import { useBreakpointValue } from '@chakra-ui/react';

export default function ChampionnatDetailPage() {
  const { id } = useParams<{ id: string }>();
  const { championnat, isLoading } = useGetChampionnatById(id!);
  const navigate = useNavigate();
  const modifierTitle = useBreakpointValue(
    { base: '', md: 'Modifier' },
    { fallback: 'md' },
  );

  if (isLoading) {
    return <Loader fullScreen />;
  }

  if (!championnat) {
    return <NotFound />;
  }

  const { name } = championnat;

  return (
    <>
      <BPCPageHeading
        title={name}
        breadcrumbItems={[
          { to: RoutePath.home, name: 'Home' },
          { to: RoutePath.championnats, name: 'Championnats' },
        ]}
        buttonProps={[
          {
            leftIcon: <FaEdit />,
            onClick: () => navigate(`${RoutePath.championnats}/${id}/edition`),
            title: modifierTitle,
          },
        ]}
      />

      <ChampionnatDetail championnat={championnat} />
    </>
  );
}
