// https://stackoverflow.com/questions/40929260/find-last-index-of-element-inside-array-by-certain-condition
export function findLastIndex<T>(
  array: Array<T>,
  predicate: (value: T, index: number, obj: T[]) => boolean,
): number {
  let l = array.length;
  while (l--) {
    if (predicate(array[l], l, array)) return l;
  }
  return -1;
}

export function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function deleteUndefinedKeys<T extends Record<any, any>>(
  dataToUpdate: T,
) {
  Object.keys(dataToUpdate).forEach((key) => {
    const dataToUpdateValue = dataToUpdate[key];
    if (dataToUpdateValue === undefined) {
      delete dataToUpdate[key];
    }
    if (typeof dataToUpdateValue === 'object' && dataToUpdateValue !== null) {
      deleteUndefinedKeys(dataToUpdateValue);
    }
  });
}
