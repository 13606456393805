import { Container } from '@chakra-ui/react';
import Loader from 'common/components/Loader';
import { updatePlayer, useGetPlayerById } from 'features/players/players.api';
import { Player } from 'features/players/players.types';
import EditProfileForm from 'features/user/components/EditProfileForm';
import { useConnectedUserContext } from 'features/user/useConnectedUserContext';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { RoutePath } from 'router/routes.paths';
import { NotFound } from './ErrorPage';
import BPCPageHeading from 'common/components/BPCPageHeading';

export default function EditionJoueurPage() {
  const { id } = useParams<{ id: string }>();
  const { data: player, isLoading: isPlayerLoading } = useGetPlayerById(id);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const navigate = useNavigate();
  const { user } = useConnectedUserContext();

  if (user && user.role !== 'admin' && user.id !== id) {
    return <NotFound />;
  }

  if (isPlayerLoading || !player) {
    return <Loader fullScreen />;
  }

  function handleSubmit(playerUpdated: Omit<Player, 'id'>) {
    setErrorMessage(undefined);
    setIsLoading(true);
    updatePlayer({ id: player!.id, ...playerUpdated })
      .then(() => {
        navigate(RoutePath.joueurs);
      })
      .finally(() => setIsLoading(false));
  }

  return (
    <Container maxW="md">
      <BPCPageHeading
        breadcrumbItems={[
          { to: RoutePath.home, name: 'Home' },
          { to: RoutePath.joueurs, name: 'Joueurs' },
        ]}
        helmetName={player.name}
      />

      <EditProfileForm
        user={player}
        onSubmit={handleSubmit}
        isLoading={isLoading}
        errorMessage={errorMessage}
        //je ne peux pas changer mon email sinon je perds la connexion avec le user authentifié
        isEmailDisabled={true}
      />
    </Container>
  );
}
