// Import the functions you need from the SDKs you need
import { initializeApp, getApps } from 'firebase/app';
import { getFirestore } from '@firebase/firestore';
import { getAuth } from '@firebase/auth';
import { getDatabase } from '@firebase/database';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
};

// Initialize Firebase (the ternary is to avoid running it on every page load)
const firebase_app =
  getApps().length === 0 ? initializeApp(firebaseConfig) : getApps()[0];

const firebase_db = getFirestore(firebase_app);
const firebase_auth = getAuth(firebase_app);
firebase_auth.useDeviceLanguage();
const firebase_realtime_db = getDatabase(firebase_app);
const firebase_storage = getStorage(firebase_app);

export {
  firebase_app,
  firebase_db,
  firebase_auth,
  firebase_realtime_db,
  firebase_storage,
};
