import { deleteDoc as deleteDocFirebase, doc } from 'firebase/firestore';
import { firebase_db } from '../firebase.config';
import {
  BPCCollection,
  transformCollectionForFirestore,
} from './firestore.helpers';

export const deleteDoc = async (collection: BPCCollection, id: string) => {
  const ref = doc(
    firebase_db,
    ...transformCollectionForFirestore(collection),
    id,
  );

  return deleteDocFirebase(ref);
};
