import { getAudioPlayerName } from 'features/players/players.helpers';
import { TournoiPlayer } from 'features/tournois/tournoi.types';
import { formatMoney } from './string.helper';

export type SonType =
  | 'MISE_EN_PAUSE'
  | 'REPRISE'
  | 'PAUSE_1_MIN'
  | 'PAUSE'
  | 'RECAVE'
  | 'ELIMINATION';

export const SONS: Record<
  SonType,
  (killer?: TournoiPlayer, victime?: TournoiPlayer) => string[]
> = {
  MISE_EN_PAUSE: () => [
    'Tournoi mis en pause',
    'Pause pipi',
    'Pause',
    'Levez les bras',
    'On ne touche plus à rien',
  ],
  REPRISE: () => [
    'Reprise',
    'Reprise des hostilités',
    'On y retourne',
    'Allez hop hop hop ça redémarre',
  ],
  PAUSE_1_MIN: () => [
    'Dernière minute avant la pause',
    "Allez plus qu'une minute à tenir avant la délivrance",
    'Une minute restante avant la pause',
    'Enfin une pause dans une minute',
  ],
  PAUSE: () => [
    'Vous êtes en pause, on se détend',
    'Enfin la pause !',
    "Youhou on peut souffler, c'est la pause",
  ],
  RECAVE: (killer, victime) => {
    const killerName = killer ? getAudioPlayerName(killer) : undefined;
    const victimeName = victime ? getAudioPlayerName(victime) : undefined;
    return [
      `${victimeName} a recavé${
        killerName ? `, la faute à ${killerName}` : ''
      }`,
      `Aïe, accident pour ${victimeName}. ${
        killerName ? `Un kill pour  ${killerName} !` : ''
      }`,
      `Ba alors ${victimeName}, t'as pas cru ${killerName} ? Du coup tu passes à la caisse...`,
    ];
  },
  ELIMINATION: (killer, victime) => {
    const killerName = killer ? getAudioPlayerName(killer) : undefined;
    const victimeName = victime ? getAudioPlayerName(victime) : undefined;
    const victimePosition = victime?.position;
    const victimePrize = victime?.prizeInEuro;
    return [
      `${victimeName} a été éliminé en ${victimePosition}ème position${
        killerName ? `, par ${killerName}` : ''
      }${victimePrize ? `, et gagne ${formatMoney(victimePrize)}` : ''}`,
      `Bien joué ${killerName}, tu élimines ${victimeName} en ${victimePosition}ème position. ${
        victimePrize
          ? ` ${victimeName}, tu gagnes ${formatMoney(
              victimePrize,
            )} pour te consoler`
          : ''
      }`,
      `Aïe, accident pour ${victimeName}, une belle ${victimePosition}ème place${
        victimePrize ? `, et ${formatMoney(victimePrize)} pour te consoler` : ''
      }. ${killerName ? `Bravo, ${killerName}, un kill pour toi !` : ''}`,
      `Ba alors ${victimeName}, t'as pas cru ${killerName} ? Du coup tu termines à la ${victimePosition}ème position${
        victimePrize
          ? `, et tu gagnes quand même ${formatMoney(victimePrize)}`
          : ''
      }`,
    ];
  },
};
