import { updateDoc as updateDocFirebase, doc } from 'firebase/firestore';
import { firebase_db } from '../firebase.config';
import { deleteUndefinedKeys } from 'common/common.helper';
import {
  BPCCollection,
  transformCollectionForFirestore,
} from './firestore.helpers';

export const updateDoc = async <T extends { id: string } & Record<string, any>>(
  collection: BPCCollection,
  data: T,
) => {
  const { id, ...restData } = data;
  const ref = doc(
    firebase_db,
    ...transformCollectionForFirestore(collection),
    id,
  );

  const dataToUpdate = { ...restData };
  deleteUndefinedKeys(dataToUpdate);
  await updateDocFirebase(ref, dataToUpdate);
  return id;
};
