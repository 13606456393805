import { ChampionnatFirebase } from './championnat.types';
import { useGetCollection } from 'api/firestore/getCollection';
import { getDocById, useGetDocById } from 'api/firestore/getDocById';
import { createDoc } from 'api/firestore/createDoc';
import { updateDoc } from 'api/firestore/updateDoc';
import { CHAMPIONNATS_COLLECTION_NAME } from 'api/collectionNames.constants';
import { useMemo } from 'react';
import { useGetTournoiList } from 'features/tournois/tournoi.api';
import { mapChampionnatFirebaseToChampionnat } from './championnat.mappers';
import { Tournoi } from 'features/tournois/tournoi.types';
import { useGetTournoiImportedList } from 'features/tournois/imported/tournoi.imported.api';

export async function createChampionnat(
  championnat: Omit<ChampionnatFirebase, 'id'>,
) {
  return (
    await createDoc<ChampionnatFirebase>(
      CHAMPIONNATS_COLLECTION_NAME,
      championnat,
    )
  ).id;
}

export function updateChampionnat(championnat: ChampionnatFirebase) {
  return updateDoc<ChampionnatFirebase>(
    CHAMPIONNATS_COLLECTION_NAME,
    championnat,
  );
}
export async function closeOrOpenChampionnat(championnatId: string) {
  const championnat = await getDocById<ChampionnatFirebase>(
    CHAMPIONNATS_COLLECTION_NAME,
    championnatId,
  );
  if (championnat) {
    return updateDoc<ChampionnatFirebase>(CHAMPIONNATS_COLLECTION_NAME, {
      ...championnat,
      isActive: !championnat.isActive,
    });
  }
  return undefined;
}

export function useGetChampionnatList() {
  const { data: championnatsFirebase, isLoading } =
    useGetCollection<ChampionnatFirebase>(CHAMPIONNATS_COLLECTION_NAME);
  const { tournois, isLoading: isTournoisLoading } = useGetTournoiList();
  const { tournoiImportedList, isLoading: isTournoiImportedLoading } =
    useGetTournoiImportedList();

  const championnats = useMemo(
    () =>
      !isLoading && !isTournoisLoading && !isTournoiImportedLoading
        ? championnatsFirebase.map((championnat) =>
            mapChampionnatFirebaseToChampionnat(
              championnat,
              getTournoiListForChampionnatId(
                [...tournois, ...tournoiImportedList],
                championnat.id,
              ),
            ),
          )
        : [],
    [
      isLoading,
      isTournoisLoading,
      isTournoiImportedLoading,
      championnatsFirebase,
      tournois,
      tournoiImportedList,
    ],
  );

  return {
    championnats,
    tournoisWithoutChampionnat: getTournoiListForChampionnatId(
      [...tournois, ...tournoiImportedList],
      null,
    ),
    isLoading,
  };
}

export function useGetChampionnatById(championnatId?: string) {
  const { data: championnatFirebase, isLoading } =
    useGetDocById<ChampionnatFirebase>(
      CHAMPIONNATS_COLLECTION_NAME,
      championnatId,
    );

  const { tournois, isLoading: isTournoisLoading } = useGetTournoiList();
  const { tournoiImportedList, isLoading: isTournoiImportedLoading } =
    useGetTournoiImportedList();

  const championnat = useMemo(
    () =>
      championnatFirebase && !isTournoisLoading && !isTournoiImportedLoading
        ? mapChampionnatFirebaseToChampionnat(
            championnatFirebase,
            getTournoiListForChampionnatId(
              [...tournois, ...tournoiImportedList],
              championnatFirebase.id,
            ),
          )
        : undefined,
    [
      championnatFirebase,
      isTournoisLoading,
      isTournoiImportedLoading,
      tournois,
      tournoiImportedList,
    ],
  );
  return { championnat, isLoading: isLoading || isTournoisLoading };
}

function getTournoiListForChampionnatId(
  tournois: Tournoi[],
  championnatId: string | null,
) {
  return (tournois || []).filter(({ championnatIds }) =>
    championnatId
      ? championnatIds.includes(championnatId)
      : championnatIds.length === 0,
  );
}
