import { PokerRange } from '../ranges.type';
import { Box, SimpleGrid } from '@chakra-ui/react';
import RangeCard from './RangeCard';
import { RoutePath } from 'router/routes.paths';

export default function RangeList({
  ranges,
}: Readonly<{ ranges: PokerRange[] }>) {
  return ranges.length ? (
    <SimpleGrid columns={{ base: 1, md: 2 }} spacing={2}>
      {ranges.map((range) => (
        <RangeCard
          key={range.id}
          range={range}
          linkTo={`${RoutePath.ranges}/${range.id}`}
        />
      ))}
    </SimpleGrid>
  ) : (
    <Box>
      <Box mb={2}>Pas de range correspondante</Box>
    </Box>
  );
}
