import { Tournoi } from '../tournoi.types';
import { NouveauTournoiImportFormValues } from './components/NouveauTournoiImportForm';

export function generateTournoiImportedToDuplicate(
  tournoi: Tournoi,
): NouveauTournoiImportFormValues {
  const { id: _id, players, championnatIds, ...restTournoiToCopy } = tournoi;

  return {
    ...restTournoiToCopy,
    name: undefined,
    players: players.map(({ id, name, photo, nbKills, nbRecave }) => ({
      id,
      name,
      nameForAudio: '',
      photo: photo,
      depense: restTournoiToCopy.montantBuyIn,
      nbRecave,
      nbKills,
      killedBy: [],
    })),
    championnatId: championnatIds?.length ? championnatIds[0] : undefined,
  };
}
