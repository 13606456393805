import { Formik } from 'formik';
import {
  Button,
  VStack,
  Box,
  Alert,
  AlertIcon,
  AlertDescription,
  ButtonGroup,
} from '@chakra-ui/react';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BPCYup } from 'common/form.validation';
import { BPCInput } from 'common/components/form/BPCInput';
import { createChampionnat, updateChampionnat } from '../championnat.api';
import { RoutePath } from 'router/routes.paths';
import { Championnat, ChampionnatFirebase } from '../championnat.types';
import ChampionnatFormuleForm from './ChampionnatFormuleForm';
import { CHAMPIONNAT_FORMULES_DEFAULT } from '../championnat.constants';
import { mapChampionnatToChampionnatFirebase } from '../championnat.mappers';

const ChampionnatSchema = BPCYup.object().shape({
  name: BPCYup.string()
    .min(3, 'Le nom doit contenir au moins 3 caractères')
    .required(),
  methodeCalculPoints: BPCYup.string().required(),
});

const defaultInitialValues: Omit<ChampionnatFirebase, 'id'> = {
  name: '',
  methodeCalculPoints: CHAMPIONNAT_FORMULES_DEFAULT[0].formule!,
  isActive: true,
};

export default function NouveauChampionnatForm({
  championnat,
  submitButtonLabel = 'Créer le championnat',
  onCancel,
}: {
  championnat?: Championnat;
  submitButtonLabel?: string;
  onCancel?: () => void;
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const navigate = useNavigate();

  const initialValues = useMemo(
    () =>
      championnat
        ? {
            ...defaultInitialValues,
            ...mapChampionnatToChampionnatFirebase(championnat),
          }
        : defaultInitialValues,
    [championnat],
  );

  return (
    <Box pb={2}>
      <Formik
        initialValues={initialValues}
        validationSchema={ChampionnatSchema}
        onSubmit={async (championnatToSave) => {
          setErrorMessage(undefined);
          setIsLoading(true);
          let id: string;
          try {
            if (championnat) {
              id = await updateChampionnat({
                id: championnat.id,
                ...championnatToSave,
              });
            } else {
              id = await createChampionnat(championnatToSave);
            }
            navigate(`${RoutePath.championnats}/${id}`);
          } catch (e: any) {
            const { code, message } = e;
            console.error(
              `Erreur lors de ${
                championnat ? "l'édition" : 'la création'
              } du championnat:${code} message:${message}`,
            );
            setErrorMessage(message);
          }
          setIsLoading(false);
        }}
        enableReinitialize
      >
        {({ handleSubmit, setFieldValue, values }) => (
          <form onSubmit={handleSubmit}>
            <VStack spacing={4} align="strech">
              {errorMessage ? (
                <Alert status="error">
                  <AlertIcon />
                  <AlertDescription>{errorMessage}</AlertDescription>
                </Alert>
              ) : undefined}
              <BPCInput fieldName="name" label="Nom" type="text" />

              <ChampionnatFormuleForm
                methodeCalculPoints={values.methodeCalculPoints}
                onSubmit={(newVal) =>
                  setFieldValue('methodeCalculPoints', newVal)
                }
              />
              <ButtonGroup gap={4} mt={4}>
                {onCancel && (
                  <Button width="full" onClick={onCancel} variant={'outline'}>
                    Annuler
                  </Button>
                )}
                <Button isLoading={isLoading} type="submit" width="full">
                  {submitButtonLabel}
                </Button>
              </ButtonGroup>
            </VStack>
          </form>
        )}
      </Formik>
    </Box>
  );
}
