import { ref, onValue } from 'firebase/database';
import { firebase_realtime_db } from '../firebase.config';
import { RealtimeOptions } from '../types';

type GetRealtimeListParams<T> = {
  setList: (list: T[]) => void;
  options?: RealtimeOptions;
};

export type GetRealtimeList<T> = (params: GetRealtimeListParams<T>) => void;

export const getRealtimeList = <T>(
  listName: string,
  { setList, options }: GetRealtimeListParams<T>,
) => {
  const { onlyOnce = false } = options || {};
  const listRef = ref(firebase_realtime_db, listName);
  onValue(
    listRef,
    (snapshot) => {
      const list: T[] = [];
      snapshot.forEach((childSnapshot) => {
        const id = childSnapshot.key;
        const data = childSnapshot.val();
        list.push({ id, ...data });
      });
      setList(list);
    },
    {
      onlyOnce,
    },
  );
};
