import {
  Box,
  Icon,
  StyleProps,
  Table,
  TableCellProps,
  TableColumnHeaderProps,
  TableContainer,
  TableContainerProps,
  TableHeadProps,
  TableProps,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { ForwardedRef, ReactNode, forwardRef, useMemo, useState } from 'react';
import { FaArrowDown, FaArrowUp } from 'react-icons/fa';
import { NAVBAR_HEIGHT } from 'router/layouts/ConnectedLayout';
import orderBy from 'lodash/orderBy';
import {
  formatMoney,
  formatNumber2Digits,
  formatPercentage,
} from 'common/string.helper';
import { formatDateWithDayMonth } from 'common/date.helper';

type BPCTableColumnType =
  | 'text'
  | 'textCentered'
  | 'nombre'
  | 'money'
  | 'custom'
  | 'hidden'
  | 'date'
  | 'percent';
export type BPCTableProps<T extends Record<string, ReactNode | Date>> = {
  stickyHeader?: boolean;
  stickyFirstCol?: boolean;
  fullWidth?: boolean;
  columns: {
    property: keyof T;
    sortingProperty?: keyof T;
    defaultSortingAsc?: boolean;
    name: string;
    type: BPCTableColumnType;
  }[];
  defaultSortBy?: {
    property: keyof T;
    asc: boolean;
  };
  data: T[];
} & TableContainerProps &
  Pick<TableProps, 'size' | 'variant'>;

function getValuesFromType({
  sortingProperty,
  type,
}: {
  sortingProperty?: any;
  type: BPCTableColumnType;
}) {
  let sortable = true;
  let textAlign: StyleProps['textAlign'] = 'left';
  switch (type) {
    case 'money':
    case 'percent':
    case 'date': {
      textAlign = 'right';
      break;
    }
    case 'custom': {
      sortable = !!sortingProperty;
      break;
    }
    case 'nombre':
    case 'textCentered': {
      textAlign = 'center';
      break;
    }
    case 'hidden':
    case 'text': {
      break;
    }
    default: {
      const switchCheck: never = type;
      return switchCheck;
    }
  }
  return { sortable, textAlign };
}

function BPCTable<T extends Record<string, ReactNode | Date>>(
  {
    stickyHeader,
    stickyFirstCol,
    fullWidth,
    columns,
    defaultSortBy,
    data,
    size,
    variant,
    ...restStyleProps
  }: BPCTableProps<T>,
  ref: ForwardedRef<HTMLDivElement>,
) {
  const [sortBy, setSortBy] = useState(defaultSortBy);
  const tableContainerProps: TableContainerProps = useMemo(
    () =>
      stickyHeader
        ? {
            overflowY: 'auto',
            maxHeight: `calc(100vh - 1rem - ${NAVBAR_HEIGHT})`,
            ...restStyleProps,
          }
        : restStyleProps,
    [restStyleProps, stickyHeader],
  );
  const tHeadProps: TableHeadProps = useMemo(
    () =>
      stickyHeader
        ? {
            position: 'sticky',
            top: '0',
            bg: 'white',
            zIndex: 2,
          }
        : {},
    [stickyHeader],
  );

  const sortedData = useMemo(() => {
    if (!sortBy) {
      return data;
    }
    const column = columns.find(({ property }) => property === sortBy.property);
    const columnToUseToSort = column?.sortingProperty ?? sortBy.property;
    // Le defaultSortingAsc inverse la logique du sortBy.asc tout simplement
    const sortingOrder =
      column?.defaultSortingAsc === false ? !sortBy.asc : sortBy.asc;

    return orderBy(data, columnToUseToSort, sortingOrder ? 'asc' : 'desc');
  }, [data, sortBy, columns]);

  return (
    <TableContainer ref={ref} {...tableContainerProps}>
      <Table
        width={fullWidth ? undefined : 'unset'}
        variant={variant ?? 'simple'}
        size={size ?? 'md'}
      >
        <Thead {...tHeadProps}>
          <Tr>
            {columns.map(({ property, name, type, sortingProperty }, i) => {
              const { sortable, textAlign } = getValuesFromType({
                type,
                sortingProperty,
              });
              const thProps: TableColumnHeaderProps =
                i === 0 && stickyFirstCol
                  ? { position: 'sticky', left: 0, bg: 'white', zIndex: 1 }
                  : {};
              return type !== 'hidden' ? (
                <Th
                  onClick={() => {
                    if (!sortable) {
                      return;
                    }
                    if (sortBy?.property === property) {
                      setSortBy({
                        property: sortBy.property,
                        asc: !sortBy.asc,
                      });
                    } else {
                      setSortBy({ property, asc: false });
                    }
                  }}
                  key={i}
                  cursor={sortable ? 'pointer' : undefined}
                  {...thProps}
                >
                  <Box
                    display={'flex'}
                    alignItems={'center'}
                    textAlign={textAlign}
                  >
                    {name}
                    {sortBy?.property === property && (
                      <Icon ml={2} as={sortBy.asc ? FaArrowUp : FaArrowDown} />
                    )}
                  </Box>
                </Th>
              ) : null;
            })}
          </Tr>
        </Thead>
        <Tbody>
          {sortedData.map((row, rowIndex) => (
            <Tr key={rowIndex}>
              {columns.map(
                (
                  { property, name: _name, type, sortingProperty },
                  colIndex,
                ) => {
                  const { textAlign } = getValuesFromType({
                    type,
                    sortingProperty,
                  });
                  const tdProps: TableCellProps =
                    colIndex === 0 && stickyFirstCol
                      ? { position: 'sticky', left: 0, bg: 'white', zIndex: 1 }
                      : {};

                  function formatColContent() {
                    switch (type) {
                      case 'money': {
                        const colContent = row[property] as number;
                        return colContent !== undefined
                          ? formatMoney(colContent)
                          : '-';
                      }
                      case 'nombre': {
                        const colContent = row[property] as number;
                        return colContent !== undefined
                          ? formatNumber2Digits(colContent)
                          : '-';
                      }
                      case 'date': {
                        const colContent = row[property] as Date;
                        return colContent !== undefined
                          ? formatDateWithDayMonth(colContent)
                          : '-';
                      }
                      case 'percent': {
                        const colContent = row[property] as number;
                        return colContent !== undefined
                          ? formatPercentage(colContent)
                          : '-';
                      }
                      case 'hidden':
                      case 'custom':
                      case 'text':
                      case 'textCentered': {
                        return row[property] as ReactNode;
                      }
                      default: {
                        const switchCheck: never = type;
                        return switchCheck;
                      }
                    }
                  }

                  return type !== 'hidden' ? (
                    <Td key={colIndex} {...tdProps} textAlign={textAlign}>
                      {formatColContent()}
                    </Td>
                  ) : null;
                },
              )}
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
}

export default forwardRef(BPCTable);
