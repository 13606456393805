import { Box } from '@chakra-ui/react';
import { RoutePath } from 'router/routes.paths';
import Loader from 'common/components/Loader';
import BPCPageHeading from 'common/components/BPCPageHeading';
import { useParams } from 'react-router-dom';
import { useGetChampionnatById } from 'features/championnats/championnat.api';
import { NotFound } from './ErrorPage';
import { useGetPlayerById } from 'features/players/players.api';
import JoueurDetail from 'features/players/components/JoueurDetail';

export default function ChampionnatJoueurPage() {
  const { id, joueurId } = useParams<{ id: string; joueurId: string }>();
  const { championnat, isLoading: isChampionnatLoading } =
    useGetChampionnatById(id!);
  const { data: player, isLoading: isPlayerLoading } =
    useGetPlayerById(joueurId);

  if (isChampionnatLoading || isPlayerLoading) {
    return <Loader fullScreen />;
  }

  if (!championnat || !player) {
    return <NotFound />;
  }

  return (
    <Box>
      <BPCPageHeading
        title={player.name}
        breadcrumbItems={[
          { to: RoutePath.home, name: 'Home' },
          { to: RoutePath.championnats, name: 'Championnats' },
          { to: `${RoutePath.championnats}/${id}`, name: championnat.name },
        ]}
      />
      <JoueurDetail player={player} championnat={championnat} />
    </Box>
  );
}
