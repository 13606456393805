import { Box } from '@chakra-ui/react';
import NouveauChampionnatForm from 'features/championnats/components/NouveauChampionnatForm';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetChampionnatById } from 'features/championnats/championnat.api';
import Loader from 'common/components/Loader';
import { NotFound } from './ErrorPage';
import { RoutePath } from 'router/routes.paths';
import BPCPageHeading from 'common/components/BPCPageHeading';

export default function EditionChampionnatPage() {
  const { id } = useParams<{ id: string }>();
  const { championnat, isLoading } = useGetChampionnatById(id!);
  const navigate = useNavigate();

  if (isLoading) {
    return <Loader fullScreen />;
  }

  if (!championnat) {
    return <NotFound />;
  }

  const { name } = championnat;

  return (
    <Box>
      <BPCPageHeading
        title={name}
        breadcrumbItems={[
          { to: RoutePath.home, name: 'Home' },
          { to: RoutePath.championnats, name: 'Championnats' },
          { to: `${RoutePath.championnats}/${id}`, name },
        ]}
      />

      <NouveauChampionnatForm
        championnat={championnat}
        submitButtonLabel="Modifier le championnat"
        onCancel={() => navigate(-1)}
      />
    </Box>
  );
}
