import { Player } from 'features/players/players.types';
import { createContext, useContext } from 'react';

type ConnectedUserContextType = Partial<{
  user: Player;
}>;

export const ConnectedUserContext = createContext<ConnectedUserContextType>({});

export const useConnectedUserContext = () => {
  return useContext(ConnectedUserContext);
};
