import { Box } from '@chakra-ui/react';
import { RoutePath } from 'router/routes.paths';
import BPCPageHeading from 'common/components/BPCPageHeading';
import EditRangeForm from 'features/ranges/components/EditRangeForm';
import { useParams } from 'react-router-dom';
import { useConnectedUserContext } from 'features/user/useConnectedUserContext';
import Loader from 'common/components/Loader';
import { useGetRangeById } from 'features/ranges/ranges.api';
import { NotFound } from './ErrorPage';

export default function OutilEditionRangePage() {
  const { id } = useParams<{ id: string }>();
  const { range, isLoading } = useGetRangeById(id!);
  const { user } = useConnectedUserContext();

  if (isLoading || !user) {
    return <Loader fullScreen />;
  }

  if (!range) {
    return <NotFound />;
  }

  return (
    <Box>
      <BPCPageHeading
        helmetName="Ranges - edition"
        title="Edition range"
        breadcrumbItems={[
          { to: RoutePath.home, name: 'Home' },
          { to: RoutePath.outils, name: 'Outils' },
          { to: RoutePath.ranges, name: 'Liste des ranges' },
        ]}
      />
      <EditRangeForm range={range} userId={user.id} />
    </Box>
  );
}
