import { FieldArray, Formik, ArrayHelpers, Field } from 'formik';
import {
  Button,
  VStack,
  Box,
  Alert,
  AlertIcon,
  AlertDescription,
  Icon,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  useDisclosure,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogBody,
  AlertDialog,
  AlertDialogFooter,
  AlertDialogHeader,
  ButtonGroup,
  Heading,
  FormControl,
  FormLabel,
  Select,
  Stack,
} from '@chakra-ui/react';
import { useState, useRef, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { RoutePath } from 'router/routes.paths';
import { BPCYup } from 'common/form.validation';
import { BPCInput } from 'common/components/form/BPCInput';
import { BPCDatePicker } from 'common/components/form/BPCDatePicker';
import { FaArrowDown, FaArrowUp, FaTrash } from 'react-icons/fa';
import AjouterJoueursModal from 'features/players/components/AjouterJoueursModal';
import { formatMoney } from 'common/string.helper';
import { useGetChampionnatList } from 'features/championnats/championnat.api';
import TournoiPlayerAvatar from 'features/players/components/TournoiPlayerAvatar';
import CreerJoueurButton from 'features/players/components/CreerJoueurButton';
import { mapPlayerToNewTournoiPlayer } from 'features/players/players.mappers';
import { TournoiImportedFirebase } from '../tournoi.imported.types';
import {
  createTournoiImported,
  updateTournoiImported,
} from '../tournoi.imported.api';
import { TournoiPlayer } from 'features/tournois/tournoi.types';
import { calculerPoints } from 'features/championnats/championnat.helper';

export type NouveauTournoiImportFormValues = Omit<
  TournoiImportedFirebase,
  'id' | 'championnatIds' | 'status' | 'date' | 'players'
> & {
  championnatId?: string;
  date: Date;
  players: TournoiPlayer[];
};

const TournoiImportedSchema = BPCYup.object().shape({
  name: BPCYup.string().min(3, 'Le nom doit contenir au moins 3 caractères'),
  date: BPCYup.date().required(),
  championnatId: BPCYup.string(),
  montantBuyIn: BPCYup.number()
    .min(1, 'Le buy-in ne peut pas être inférieur à 1€')
    .required(),
  jetonsRecus: BPCYup.number()
    .min(100, 'Le nombre de jetons reçus ne peut pas être inférieur à 100')
    .required(),
  nbRecavesMax: BPCYup.string(),
  players: BPCYup.array()
    .required()
    .min(2, 'Le tournoi doit avoir au moins 2 joueurs'),
  prizePool: BPCYup.array()
    .of(BPCYup.number().required().min(1, 'Le prix doit être supérieur à 1%'))
    .required()
    .min(1, 'Le tournoi doit avoir au moins 1 prix')
    .test('sum', 'La somme des prix doit être égal à 100%', (prizes = []) => {
      const total = prizes.reduce((total, prize) => total + prize, 0);
      return total === 100;
    }),
});

const defaultInitialValues: NouveauTournoiImportFormValues = {
  name: undefined,
  date: dayjs().toDate(),
  montantBuyIn: 10,
  jetonsRecus: 30000,
  nbRecavesMax: 1,
  players: [],
  prizePool: [70, 30],
};

const AUCUN_CHAMPIONNAT_ID = 'aucun-championnat-id';
export default function NouveauTournoiImportForm({
  tournoi,
  submitButtonLabel = 'Importer le tournoi',
  onCancel,
}: Readonly<{
  tournoi?: NouveauTournoiImportFormValues;
  submitButtonLabel?: string;
  onCancel?: () => void;
}>) {
  const ajouterJoueursDisclosure = useDisclosure();
  const dernierPrixSuprimeDisclosure = useDisclosure();

  const cancelRefPrix = useRef(null);

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const navigate = useNavigate();

  const { championnats } = useGetChampionnatList();

  const initialValues: NouveauTournoiImportFormValues = useMemo(() => {
    const {
      name,
      prizePool,
      championnatId: existingChampionnatId,
      ...restTournoi
    } = tournoi || {};
    const championnatId = existingChampionnatId
      ? existingChampionnatId
      : championnats.length > 0
      ? championnats[championnats.length - 1].id
      : undefined;
    return {
      ...defaultInitialValues,
      name: name ?? '',
      championnatId,
      prizePool:
        prizePool?.map((p) => p * 100) ?? defaultInitialValues.prizePool,
      ...restTournoi,
    };
  }, [championnats, tournoi]);

  return (
    <Box pb={2}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={TournoiImportedSchema}
        onSubmit={async (tournoiToSave: NouveauTournoiImportFormValues) => {
          setErrorMessage(undefined);
          setIsLoading(true);
          const { prizePool, championnatId, date, players, ...restTournoi } =
            tournoiToSave;
          const mappedTournoiToSave: Omit<TournoiImportedFirebase, 'id'> & {
            id?: string;
          } = {
            ...restTournoi,
            date: date.toISOString(),
            prizePool: prizePool.map((montant) => montant / 100),
            championnatIds:
              !championnatId || championnatId === AUCUN_CHAMPIONNAT_ID
                ? []
                : [championnatId],
            status: 'finished',
            players: players.map(
              ({ id, nbRecave, nbKills, killedBy }, index) => ({
                id,
                position: index + 1,
                nbRecave,
                nbKills,
                killedBy,
              }),
            ),
          };
          try {
            const id = await (mappedTournoiToSave.id
              ? //@ts-expect-error id est optionel et il attend un id défini
                updateTournoiImported(mappedTournoiToSave)
              : createTournoiImported(mappedTournoiToSave));
            navigate(`${RoutePath.tournoiImport}/${id}`);
          } catch (e: any) {
            const { code, message } = e;
            console.error(
              `Erreur lors de la création du tournoi:${code} message:${message}`,
            );
            setErrorMessage(message);
          }
          setIsLoading(false);
        }}
      >
        {({ handleSubmit, values, setFieldValue, errors }) => {
          const nbJoueurs = values.players.length;
          const nbCaves =
            nbJoueurs +
            values.players.reduce((acc, { nbRecave }) => acc + nbRecave, 0);
          const selectedChampionnat = championnats.find(
            ({ id }) => values.championnatId === id,
          );

          return (
            <form onSubmit={handleSubmit}>
              <VStack spacing={4} align="strech">
                {errorMessage ? (
                  <Alert status="error">
                    <AlertIcon />
                    <AlertDescription>{errorMessage}</AlertDescription>
                  </Alert>
                ) : undefined}
                <BPCInput fieldName="name" label="Nom" type="text" />
                <BPCDatePicker fieldName="date" label="Date" isRequired />
                <FormControl>
                  <FormLabel htmlFor="championnatId">Championnat</FormLabel>
                  <Field as={Select} id="championnatId" name="championnatId">
                    <option value={AUCUN_CHAMPIONNAT_ID}>Aucun</option>
                    {championnats.map(({ name, id }) => (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    ))}
                  </Field>
                </FormControl>
                <BPCInput
                  fieldName="montantBuyIn"
                  label="Buy-in"
                  type="number"
                  suffix="€"
                  inputProps={{
                    min: 0,
                    step: 5,
                  }}
                  isRequired
                />
                <BPCInput
                  fieldName="jetonsRecus"
                  label="Nombre de jetons reçus"
                  type="number"
                  isRequired
                />
                <BPCInput
                  fieldName="nbRecavesMax"
                  label="Nombre de recave maximum"
                  type="number"
                />

                <Heading size="md">
                  {`Joueurs (${values.players.length} selectionné${
                    values.players.length > 1 ? 's' : ''
                  })`}
                </Heading>

                <Stack direction={'row'}>
                  <Button onClick={ajouterJoueursDisclosure.onOpen} mr={3}>
                    Ajouter un joueur
                  </Button>
                  <AjouterJoueursModal
                    playersInTournament={values.players}
                    isOpen={ajouterJoueursDisclosure.isOpen}
                    onClose={ajouterJoueursDisclosure.onClose}
                    onSubmit={(newPlayers) => {
                      setFieldValue('players', newPlayers);
                    }}
                    tournoiBuyIn={values.montantBuyIn}
                  />

                  <CreerJoueurButton
                    onSubmit={(newPlayer) => {
                      const newTournoiPlayer = mapPlayerToNewTournoiPlayer(
                        newPlayer,
                        values.montantBuyIn,
                      );
                      setFieldValue('players', [
                        ...values.players,
                        newTournoiPlayer,
                      ]);
                    }}
                  />
                </Stack>

                <FieldArray
                  name="players"
                  render={(arrayHelpers: ArrayHelpers) => (
                    <>
                      {typeof errors.players === 'string' ? (
                        <Box color="red.500">{errors.players}</Box>
                      ) : null}

                      {values.players.length ? (
                        <TableContainer mt={3}>
                          <Table variant="simple" size="md">
                            <Thead>
                              <Tr>
                                <Th />
                                <Th />
                                <Th>Nb recaves</Th>
                                <Th>Nb kills</Th>
                                <Th>Prix</Th>
                                <Th>Points</Th>
                                <Th />
                              </Tr>
                            </Thead>
                            <Tbody>
                              {values.players.map((player, index) => {
                                const b = values.montantBuyIn;
                                const r = player.nbRecave;
                                const d = b * (1 + r);
                                const mathVariables = {
                                  n: nbJoueurs,
                                  c: nbCaves,
                                  b,
                                  d,
                                  p: index + 1,
                                  r,
                                  k: player.nbKills,
                                };
                                return (
                                  <Tr key={index}>
                                    <Td>{mathVariables.p}</Td>
                                    <Td>
                                      <Stack direction={'row'}>
                                        <ButtonGroup
                                          isAttached
                                          flexDir={'column'}
                                          size={'xs'}
                                          variant={'ghost'}
                                        >
                                          <Button
                                            isDisabled={index === 0}
                                            onClick={() => {
                                              arrayHelpers.swap(
                                                index,
                                                index - 1,
                                              );
                                            }}
                                          >
                                            <Icon as={FaArrowUp} />
                                          </Button>
                                          <Button
                                            isDisabled={
                                              index ===
                                              values.players.length - 1
                                            }
                                            onClick={() => {
                                              arrayHelpers.swap(
                                                index,
                                                index + 1,
                                              );
                                            }}
                                          >
                                            <Icon as={FaArrowDown} />
                                          </Button>
                                        </ButtonGroup>
                                        <TournoiPlayerAvatar
                                          player={{
                                            name: player.name,
                                            photo: player.photo,
                                          }}
                                          namePosition="right"
                                        />
                                      </Stack>
                                    </Td>
                                    <Td>
                                      <BPCInput
                                        fieldName={`players.${index}.nbRecave`}
                                        type="number"
                                      />
                                    </Td>
                                    <Td>
                                      <BPCInput
                                        fieldName={`players.${index}.nbKills`}
                                        type="number"
                                      />
                                    </Td>
                                    <Td>
                                      {index < values.prizePool.length
                                        ? formatMoney(
                                            Math.round(
                                              ((values.prizePool[index] / 100) *
                                                (nbCaves *
                                                  values.montantBuyIn)) /
                                                5,
                                            ) * 5,
                                          )
                                        : '-'}
                                    </Td>
                                    <Td>
                                      {selectedChampionnat
                                        ? calculerPoints(
                                            selectedChampionnat.methodeCalculPoints,
                                            mathVariables,
                                          ) ?? '-'
                                        : '-'}
                                    </Td>
                                    <Td>
                                      <Button
                                        type="button"
                                        onClick={() =>
                                          arrayHelpers.remove(index)
                                        }
                                        size="xs"
                                      >
                                        <Icon as={FaTrash} />
                                      </Button>
                                    </Td>
                                  </Tr>
                                );
                              })}
                            </Tbody>
                          </Table>
                        </TableContainer>
                      ) : (
                        <Box mt={3}>Aucun joueur ajouté</Box>
                      )}
                    </>
                  )}
                />

                <Heading size="md">{`Prize pool (${
                  values.prizePool.length
                } place${values.prizePool.length > 1 ? 's' : ''} payée${
                  values.prizePool.length > 1 ? 's' : ''
                })`}</Heading>
                {typeof errors.prizePool === 'string' ? (
                  <Box color="red.500">{errors.prizePool}</Box>
                ) : null}
                <Stack direction={'row'}>
                  <Button
                    onClick={() =>
                      setFieldValue('prizePool', [...values.prizePool, 0])
                    }
                    mr={3}
                  >
                    Ajouter un prix
                  </Button>
                </Stack>
                <FieldArray
                  name="prizePool"
                  render={(arrayHelpers: ArrayHelpers) => (
                    <TableContainer mt={3}>
                      <Table variant="simple" size="md">
                        <Thead>
                          <Tr>
                            <Th>Place</Th>
                            <Th isNumeric>Pourcentage</Th>
                            <Th isNumeric>Montant</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {values.prizePool.map((price, index) => (
                            <Tr key={index}>
                              <Td>{index + 1}</Td>
                              <Td isNumeric>
                                <BPCInput
                                  fieldName={`prizePool.${index}`}
                                  type="number"
                                  suffix="%"
                                  inputProps={{
                                    min: 0,
                                    max: 100,
                                    step: 5,
                                  }}
                                />
                              </Td>
                              <Td isNumeric>
                                {formatMoney(
                                  Math.round(
                                    ((price / 100) *
                                      (nbCaves * values.montantBuyIn)) /
                                      5,
                                  ) * 5,
                                )}
                              </Td>
                              <Td>
                                <Button
                                  type="button"
                                  onClick={() => {
                                    if (values.prizePool.length === 1) {
                                      dernierPrixSuprimeDisclosure.onOpen();
                                    } else {
                                      arrayHelpers.remove(index);
                                    }
                                  }}
                                  size="xs"
                                >
                                  <Icon as={FaTrash} />
                                </Button>
                              </Td>
                            </Tr>
                          ))}
                        </Tbody>
                        <AlertDialog
                          isOpen={dernierPrixSuprimeDisclosure.isOpen}
                          leastDestructiveRef={cancelRefPrix}
                          onClose={dernierPrixSuprimeDisclosure.onClose}
                        >
                          <AlertDialogOverlay>
                            <AlertDialogContent>
                              <AlertDialogHeader
                                fontSize="lg"
                                fontWeight="bold"
                              >
                                Attention
                              </AlertDialogHeader>

                              <AlertDialogBody>
                                Il n'est pas possible de supprimer le dernier
                                prix d'un tournoi
                              </AlertDialogBody>

                              <AlertDialogFooter>
                                <Button
                                  ref={cancelRefPrix}
                                  onClick={dernierPrixSuprimeDisclosure.onClose}
                                >
                                  Ok
                                </Button>
                              </AlertDialogFooter>
                            </AlertDialogContent>
                          </AlertDialogOverlay>
                        </AlertDialog>
                      </Table>
                    </TableContainer>
                  )}
                />

                <ButtonGroup gap={4} mt={4}>
                  {onCancel && (
                    <Button width="full" onClick={onCancel} variant={'outline'}>
                      Annuler
                    </Button>
                  )}
                  <Button isLoading={isLoading} type="submit" width="full">
                    {submitButtonLabel}
                  </Button>
                </ButtonGroup>
              </VStack>
            </form>
          );
        }}
      </Formik>
    </Box>
  );
}
