import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/react';
import { Field, FieldValidator, useFormikContext } from 'formik';
import { SingleDatepicker } from 'chakra-dayzed-datepicker';

export function BPCDatePicker<T>({
  fieldName,
  label,
  isDisabled,
  validate,
  isRequired,
}: {
  fieldName: keyof T;
  label: string;
  variant?: string;
  isDisabled?: boolean;
  validate?: FieldValidator;
  isRequired?: boolean;
}) {
  const { errors, touched, setFieldValue, values } = useFormikContext<T>();

  return (
    <FormControl
      isInvalid={Boolean(!!errors[fieldName] && touched[fieldName])}
      isRequired={isRequired}
    >
      <FormLabel htmlFor={fieldName as string}>{label}</FormLabel>

      <Field
        as={SingleDatepicker}
        id={fieldName}
        name={fieldName}
        validate={validate}
        disabled={isDisabled}
        onDateChange={(date: Date) => setFieldValue(fieldName as string, date)}
        date={values[fieldName]}
        configs={{
          // dateFormat: 'yyyy-MM-dd',
          dayNames: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
          monthNames: [
            'Jan',
            'Fev',
            'Mar',
            'Avr',
            'Mai',
            'Juin',
            'Juil',
            'Aout',
            'Sep',
            'Oct',
            'Nov',
            'Déc',
          ],
          firstDayOfWeek: 1,
        }}
        propsConfigs={{
          dayOfMonthBtnProps: {
            selectedBtnProps: {
              background: 'orange.400',
              color: 'white',
            },
            defaultBtnProps: {
              _hover: {
                background: 'orange.300',
                color: 'white',
              },
              color: 'gray.600',
            },
          },
        }}
      />
      <FormErrorMessage>{errors[fieldName] as string}</FormErrorMessage>
    </FormControl>
  );
}
