import {
  Avatar,
  AvatarBadge,
  AvatarProps,
  Flex,
  StyleProps,
} from '@chakra-ui/react';
import { computePositionColor } from 'features/tournois/tournoi.helper';
import { TournoiPlayer } from 'features/tournois/tournoi.types';
import { useMemo } from 'react';

import './TournoiPlayerAvatar.scss';

export default function TournoiPlayerAvatar({
  player: { name, photo, position },
  size = 'sm',
  bg,
  withPosition = true,
  namePosition,
  avatarProps,
  responsiveFontSize,
  ...restStyleProps
}: {
  size?: AvatarProps['size'];
  bg?: AvatarProps['bg'];
  player: Pick<TournoiPlayer, 'name' | 'photo' | 'position'>;
  withPosition?: boolean;
  namePosition?: 'right' | 'bottom';
  avatarProps?: StyleProps;
  responsiveFontSize?: boolean;
} & StyleProps) {
  const positionBgColor = useMemo(
    () => computePositionColor(position),
    [position],
  );

  const positionColor = useMemo(() => {
    if (position && position <= 3) {
      return 'white';
    }
    return 'black';
  }, [position]);
  return (
    <Flex
      flexDir={namePosition === 'bottom' ? 'column' : undefined}
      alignItems="center"
      {...restStyleProps}
    >
      <Avatar
        size={size}
        name={name}
        src={photo}
        mr={namePosition === 'right' ? 3 : undefined}
        mb={namePosition === 'bottom' ? 3 : undefined}
        bg={bg}
        className={
          responsiveFontSize ? 'responsive-avatar-initials-size' : undefined
        }
        {...avatarProps}
      >
        {withPosition && position && (
          <AvatarBadge
            boxSize="1.5em"
            color={positionColor}
            bg={positionBgColor}
            border={'1px'}
          >
            {position}
          </AvatarBadge>
        )}
      </Avatar>
      {namePosition && name}
    </Flex>
  );
}
