import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  InputGroup,
  InputProps,
  InputRightAddon,
  StyleProps,
} from '@chakra-ui/react';
import { Field, FieldValidator, useFormikContext, getIn } from 'formik';
import { ReactNode } from 'react';

export function BPCInput({
  fieldName,
  label,
  type = 'text',
  variant = 'outline',
  isDisabled,
  validate,
  suffix,
  placeholder,
  inputProps,
  isRequired,
  helperText,
  oneLiner,
  ...restStyleProps
}: {
  fieldName: string;
  label?: string;
  type?: string;
  variant?: string;
  isDisabled?: boolean;
  validate?: FieldValidator;
  suffix?: ReactNode;
  placeholder?: string;
  inputProps?: InputProps;
  isRequired?: boolean;
  helperText?: ReactNode;
  oneLiner?: boolean;
} & StyleProps) {
  const { errors, touched } = useFormikContext();

  const parsedFieldName = fieldName.split('.');
  let errorForFieldName;
  let touchedForFieldName;
  if (
    parsedFieldName.length > 1 &&
    typeof getIn(errors, parsedFieldName[0]) === 'string'
  ) {
    errorForFieldName = undefined;
    touchedForFieldName = undefined;
  } else {
    errorForFieldName = getIn(errors, fieldName as string);
    touchedForFieldName = getIn(touched, fieldName as string);
  }

  return (
    <FormControl
      isRequired={isRequired}
      isInvalid={Boolean(!!errorForFieldName && touchedForFieldName)}
      {...restStyleProps}
    >
      <Flex
        direction={oneLiner ? 'row' : 'column'}
        alignItems={oneLiner ? 'flex-start' : undefined}
      >
        {label && (
          <FormLabel
            pt={oneLiner ? 2 : undefined}
            htmlFor={fieldName as string}
          >
            {label}
          </FormLabel>
        )}
        <Box width={'100%'}>
          <InputGroup>
            <Field
              as={Input}
              id={fieldName}
              name={fieldName}
              type={type}
              variant={variant}
              validate={validate}
              isDisabled={isDisabled}
              placeholder={placeholder}
              textAlign={type === 'number' ? 'right' : undefined}
              minWidth="4rem"
              {...inputProps}
            />
            {suffix && <InputRightAddon children={suffix} />}
          </InputGroup>
          {helperText && <FormHelperText>{helperText}</FormHelperText>}
          <FormErrorMessage>{errorForFieldName}</FormErrorMessage>
        </Box>
      </Flex>
    </FormControl>
  );
}
