import { Box, StyleProps, Text } from '@chakra-ui/react';
import { FC } from 'react';
import { StructureLevel } from '../tournoi.types';
import { getStructureLevelLabel } from '../structure.helper';

export const TournamentStructureLevel: FC<
  { structure: StructureLevel[]; levelIndex: number } & StyleProps
> = ({ structure, levelIndex, ...restStyleProps }) => {
  return (
    <Box {...restStyleProps}>
      <Text>{getStructureLevelLabel(structure, levelIndex)}</Text>
    </Box>
  );
};
