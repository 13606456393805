import { Formik } from 'formik';
import {
  Button,
  VStack,
  Box,
  Alert,
  AlertIcon,
  AlertDescription,
} from '@chakra-ui/react';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { firebase_auth } from 'api/firebase.config';
import { BPCInput } from 'common/components/form/BPCInput';
import { BPCYup } from 'common/form.validation';

const LoginSchema = BPCYup.object().shape({
  email: BPCYup.string().email("L'email est invalide").required(),
  password: BPCYup.string()
    .min(6, 'Ton mot de passe doit contenir au moins 6 caractères')
    .required(),
});

export default function LoginForm() {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const navigate = useNavigate();
  return (
    <Box pb={2}>
      <Formik
        validationSchema={LoginSchema}
        initialValues={{
          email: '',
          password: '',
        }}
        onSubmit={({ email, password }) => {
          setErrorMessage(undefined);
          setIsLoading(true);
          signInWithEmailAndPassword(firebase_auth, email, password)
            .then(() => navigate('/'))
            .catch((e) => {
              const { code, message } = e;
              console.error(`Error on login code:${code} message:${message}`);
              setErrorMessage(message);
            })
            .finally(() => setIsLoading(false));
        }}
      >
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <VStack spacing={4} align="flex-start">
              {errorMessage ? (
                <Alert status="error">
                  <AlertIcon />
                  <AlertDescription>{errorMessage}</AlertDescription>
                </Alert>
              ) : undefined}
              <BPCInput fieldName="email" label="Email" type="email" />
              <BPCInput
                fieldName="password"
                label="Mot de passe"
                type="password"
              />
              <Button isLoading={isLoading} type="submit" width="full">
                Se connecter
              </Button>
            </VStack>
          </form>
        )}
      </Formik>
    </Box>
  );
}
