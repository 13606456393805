import { Button, ButtonGroup } from '@chakra-ui/react';
import { useSons } from 'common/sons.helper';
import { useParle } from 'common/textToSpeech.helper';

export function TesterSonsPage() {
  const { parle } = useParle();
  const { joueSon } = useSons();
  return (
    <ButtonGroup m={8}>
      <Button
        onClick={async () => {
          await joueSon('paquebot', 4, false);
          parle('Cheuffeule up, and dil');
        }}
      >
        Debut
      </Button>
      <Button
        onClick={async () => {
          await joueSon('toast', 2, true);
          parle('Tournoi mis en pause');
        }}
      >
        Pause
      </Button>
      <Button
        onClick={async () => {
          await joueSon('cassette_audio', 2, true);
          parle('Reprise des hostilités');
        }}
      >
        Reprise
      </Button>
      <Button
        onClick={async () => {
          await joueSon('tictac', 2, true);
          parle(`Dans 1 minute, passage aux blindes 200, 400`);
        }}
      >
        Bientot fin du niveau
      </Button>
      <Button
        onClick={async () => {
          await joueSon('gong', 2, false);
          parle(`Passage aux blindes 200, 400`);
        }}
      >
        Passage de niveau
      </Button>
      <Button
        onClick={async () => {
          await joueSon('piece', 2, false);
          parle(`Jérôme a recavé, la faute à Selda`);
        }}
      >
        Recave
      </Button>
      <Button
        onClick={async () => {
          await joueSon('pistolet', 2, false);
          parle(
            `Jérôme a été éliminé en 5ème position par Selda, et gagne 5,00€`,
          );
        }}
      >
        Kill
      </Button>
      <Button
        onClick={async () => {
          await joueSon('joie', 2, true);
          parle(
            `Selda a gagné le tournoi, et repart avec 70,00€, Jérôme termine en 2ème position, et gagne 10,00€`,
          );
        }}
      >
        Tournoi fini
      </Button>
    </ButtonGroup>
  );
}
