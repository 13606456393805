import { Box } from '@chakra-ui/react';
import { RoutePath } from 'router/routes.paths';
import Loader from 'common/components/Loader';
import { useGetChampionnatList } from 'features/championnats/championnat.api';
import ChampionnatList from 'features/championnats/components/ChampionnatList';
import BPCPageHeading from 'common/components/BPCPageHeading';

export default function ChampionnatsListPage() {
  const { championnats, isLoading } = useGetChampionnatList();

  if (isLoading || !championnats) {
    return <Loader fullScreen />;
  }

  return (
    <Box>
      <BPCPageHeading
        helmetName="Championnats"
        title="Liste des championnats"
        breadcrumbItems={[{ to: RoutePath.home, name: 'Home' }]}
      />

      <ChampionnatList
        withCreateCard
        flexWrap="wrap"
        championnats={championnats}
      />
    </Box>
  );
}
