import {
  StyleProps,
  Box,
  StackDivider,
  Stack,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Td,
  Tbody,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Icon,
  AvatarGroup,
  Button,
  Text,
  Flex,
} from '@chakra-ui/react';
import { FC, useMemo } from 'react';

import { formatMoney, formatPercentage } from 'common/string.helper';
import BPCCardRow from 'common/components/BPCCardRow';
import {
  computeMoneyFromPriceList,
  computeTournoiTotalCave,
  computeTournoiTotalKill,
  getTournoiStatusText,
} from '../tournoi.helper';
import { useGetChampionnatById } from 'features/championnats/championnat.api';
import { FiCoffee } from 'react-icons/fi';
import { getLevelNameAtIndex } from '../structure.helper';
import {
  formatDateWithDayAndHourSec,
  formatDateWithDayNameDayMonthYear,
  formatMinutesToHourAndMin,
  formatSecondsToHourdMinAndSec,
} from 'common/date.helper';
import { calculerPointsFromTournoiPlayer } from 'features/championnats/championnat.helper';
import TournoiPlayerAvatar from 'features/players/components/TournoiPlayerAvatar';
import BPCTable from 'common/components/BPCTable';
import { TournoiHistoryCard } from './TournoiHistoryCard';
import { TournoiLive } from '../live/tournoi.live.types';
import { pauseOrResumeTournoi } from '../live/tournoi.live.helpers';
import { TournamentStructureLevel } from './TournamentStructureLevel';
import { MdLiveTv } from 'react-icons/md';
import { RoutePath } from 'router/routes.paths';
import { useNavigate } from 'react-router-dom';
import { useSons } from 'common/sons.helper';
import { useConnectedUserContext } from 'features/user/useConnectedUserContext';
import { BPCLink } from 'common/components/BPCLink';

export const TournoiDetail: FC<{ tournoi: TournoiLive } & StyleProps> = ({
  tournoi,
  ...restStyleProps
}) => {
  const {
    players,
    status,
    championnatIds,
    montantBuyIn,
    date,
    jetonsRecus,
    nbRecavesMax,
    prizePool,
    structure,
  } = tournoi;
  const { championnat } = useGetChampionnatById(
    championnatIds.length ? championnatIds[0] : undefined,
  );
  const navigate = useNavigate();
  const { joueSon } = useSons();
  const { user } = useConnectedUserContext();

  const playersTableColumns = useMemo(() => {
    const totalRecave =
      computeTournoiTotalCave(tournoi.players, tournoi.killsHistory) -
      tournoi.players.length;
    const totalKill = computeTournoiTotalKill(tournoi.players);

    return [
      {
        property: 'avatar' as const,
        sortingProperty: 'position' as const,
        defaultSortingAsc: false,
        name: '',
        type: 'custom' as const,
      },
      { property: 'name' as const, name: 'Nom', type: 'text' as const },
      {
        property: 'points' as const,
        name: 'Points',
        type: 'nombre' as const,
      },
      {
        property: 'nbRecave' as const,
        name: `Recaves${totalRecave > 0 ? ` (${totalRecave})` : ''}`,
        type: 'nombre' as const,
      },
      {
        property: 'nbKills' as const,
        name: `Kills${totalKill > 0 ? ` (${totalKill})` : ''}`,
        type: 'nombre' as const,
      },
      { property: 'prize' as const, name: 'Gains', type: 'money' as const },
      { property: 'killers' as const, name: 'Killer', type: 'custom' as const },
      //Important de mettre les hidden en dernier pour ne pas interférer avec le stickyFirstcol
      {
        property: 'position' as const,
        name: '',
        type: 'hidden' as const,
      },
    ];
  }, [tournoi.killsHistory, tournoi.players]);

  const playersTableData = useMemo(
    () =>
      players.map((p, i) => ({
        position: p.position,
        avatar: tournoi.championnatIds.length ? (
          <BPCLink
            to={`${RoutePath.championnats}/${tournoi.championnatIds[0]}/joueurs/${p.id}`}
          >
            <TournoiPlayerAvatar player={p} />
          </BPCLink>
        ) : (
          <TournoiPlayerAvatar player={p} />
        ),
        name: tournoi.championnatIds.length ? (
          <BPCLink
            to={`${RoutePath.championnats}/${tournoi.championnatIds[0]}/joueurs/${p.id}`}
          >
            {p.name}
          </BPCLink>
        ) : (
          p.name
        ),
        points: championnat?.methodeCalculPoints
          ? calculerPointsFromTournoiPlayer(
              championnat?.methodeCalculPoints,
              tournoi,
              p,
            )
          : undefined,
        nbRecave: p.nbRecave,
        nbKills: p.nbKills,
        prize: p.prizeInEuro,
        killers: p.killedBy.length ? (
          <AvatarGroup size="sm">
            {p.killedBy
              .map((killerId) => players.find(({ id }) => killerId === id))
              .map((k, i) => (
                <TournoiPlayerAvatar
                  key={i}
                  //Si on supprime du tournoi un joueur qui a déjà fait un kill ca merde ici sinon
                  player={k ?? { name: 'Inconnu' }}
                  withPosition={false}
                />
              ))}
          </AvatarGroup>
        ) : null,
      })),
    [championnat, players, tournoi],
  );

  return (
    <Box {...restStyleProps}>
      <Tabs colorScheme="orange">
        <TabList>
          <Tab>Résultats</Tab>
          <Tab>Infos</Tab>
          <Tab>Structure</Tab>
          <Tab>Historique</Tab>
        </TabList>

        <TabPanels>
          {/* Tab resultats */}
          <TabPanel>
            <BPCTable
              stickyFirstCol
              stickyHeader
              data={playersTableData}
              columns={playersTableColumns}
              defaultSortBy={{
                property:
                  tournoi.status === 'finished'
                    ? 'position'
                    : tournoi.status === 'running' ||
                      tournoi.status === 'paused'
                    ? 'position'
                    : tournoi.status === 'notStarted'
                    ? 'name'
                    : 'name',
                asc: true,
              }}
            />
          </TabPanel>

          {/* Tab infos */}
          <TabPanel>
            <Stack divider={<StackDivider />} spacing="4">
              <BPCCardRow titre="Championnat">
                {championnat ? championnat.name : 'Aucun championnat'}
              </BPCCardRow>
              <BPCCardRow titre="Date" textTransform="capitalize">
                {formatDateWithDayNameDayMonthYear(date)}
              </BPCCardRow>
              <BPCCardRow titre="Durée">
                <Box>{`Démarrage : ${
                  tournoi.startDate
                    ? formatDateWithDayAndHourSec(tournoi.startDate)
                    : 'Pas encore démarré'
                }`}</Box>
                {tournoi.endDate && (
                  <>
                    <Box>{`Fin : ${formatDateWithDayAndHourSec(
                      tournoi.endDate,
                    )}`}</Box>
                    <Box>{`Durée : ${formatMinutesToHourAndMin(
                      tournoi.dureeInMinute ?? 0,
                    )}`}</Box>
                  </>
                )}
              </BPCCardRow>
              <BPCCardRow titre="Statut">
                <Flex alignItems={'center'} gap={4}>
                  <Text>{getTournoiStatusText(status)}</Text>

                  <Button
                    onClick={() => {
                      pauseOrResumeTournoi(tournoi);
                    }}
                    isDisabled={tournoi.status === 'finished'}
                    variant={'ghost'}
                  >
                    {tournoi.status === 'notStarted'
                      ? 'Démarrer le tournoi'
                      : tournoi.status === 'running'
                      ? 'Mettre en pause'
                      : tournoi.status === 'paused'
                      ? 'Relancer le tournoi'
                      : 'Tournoi terminé'}
                  </Button>
                </Flex>
              </BPCCardRow>
              <BPCCardRow titre="Buy-in">
                {formatMoney(montantBuyIn)}
              </BPCCardRow>
              <BPCCardRow titre="Jetons reçus">{jetonsRecus}</BPCCardRow>
              <BPCCardRow titre="Nombre de recaves maximum">
                {nbRecavesMax}
              </BPCCardRow>

              {(tournoi.status === 'running' ||
                tournoi.status === 'paused') && (
                <Stack divider={<StackDivider />} spacing="4">
                  <BPCCardRow titre="Niveau en cours">
                    <TournamentStructureLevel
                      structure={structure}
                      levelIndex={tournoi.currentLevel.index}
                    />
                    {formatSecondsToHourdMinAndSec(tournoi.secondsLeftInLevel)}{' '}
                    restantes
                  </BPCCardRow>
                  <BPCCardRow titre="Prochain niveau">
                    {tournoi.nextLevelNotPause ? (
                      <TournamentStructureLevel
                        structure={structure}
                        levelIndex={tournoi.nextLevelNotPause.index}
                      />
                    ) : (
                      <Box>Pas de prochain niveau</Box>
                    )}
                  </BPCCardRow>
                  <BPCCardRow titre="Prochaine pause">
                    {tournoi.nextPause ? (
                      `Dans ${formatSecondsToHourdMinAndSec(
                        tournoi.secondsLeftToNextPause,
                      )} et pendant ${tournoi.nextPause.level.duree}min`
                    ) : (
                      <Box>Pas de prochaine pause</Box>
                    )}
                  </BPCCardRow>
                </Stack>
              )}
              <BPCCardRow titre="Prizepool">
                <TableContainer>
                  <Table variant="simple" size="md">
                    <Thead>
                      <Tr>
                        <Th>Place</Th>
                        <Th isNumeric>Pourcentage</Th>
                        <Th isNumeric>Montant</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {prizePool.map((price, index) => (
                        <Tr key={index}>
                          <Td>{index + 1}</Td>
                          <Td isNumeric>{formatPercentage(price)}</Td>
                          <Td isNumeric>
                            {formatMoney(
                              computeMoneyFromPriceList({
                                prices: prizePool,
                                priceIndex: index,
                                partialTournoi: tournoi,
                              }),
                            )}
                          </Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </TableContainer>
              </BPCCardRow>
            </Stack>
          </TabPanel>

          {/* Tab structure */}
          <TabPanel>
            <TableContainer>
              <Table variant="simple" size="md">
                <Thead>
                  <Tr>
                    <Th>Niveau</Th>
                    <Th textAlign="center">Blindes</Th>
                    <Th isNumeric>Duree (min)</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {structure.map(
                    ({ smallBlind, bigBlind, duree, isPause }, index) => (
                      <Tr
                        key={index}
                        color={
                          (tournoi.status === 'running' ||
                            tournoi.status === 'paused') &&
                          index < tournoi.currentLevel.index
                            ? isPause
                              ? 'orange.300'
                              : 'gray.300'
                            : isPause
                            ? 'orange.500'
                            : undefined
                        }
                      >
                        <Td>{getLevelNameAtIndex(structure, index)}</Td>
                        <Td textAlign="center">
                          {isPause ? (
                            <Icon as={FiCoffee} />
                          ) : (
                            `${smallBlind}/${bigBlind}`
                          )}
                        </Td>
                        <Td isNumeric>{duree}</Td>
                      </Tr>
                    ),
                  )}
                </Tbody>
              </Table>
            </TableContainer>
          </TabPanel>

          {/* Tab historique */}
          <TabPanel>
            <TournoiHistoryCard tournoi={tournoi} mb={6} />
            <Button
              leftIcon={<MdLiveTv />}
              onClick={async () => {
                if (user?.role === 'admin') {
                  await joueSon('porte', 0.1, false);
                }
                navigate(`${RoutePath.tournois}/${tournoi.id}/live`);
              }}
              variant="outline"
            >
              Accéder au live
            </Button>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};
