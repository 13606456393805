import {
  Center,
  VStack,
  StyleProps,
  Text,
  Card,
  CardBody,
  Menu,
  MenuButton,
  IconButton,
  MenuList,
  MenuItem,
  Box,
} from '@chakra-ui/react';
import { PokerRange } from '../ranges.type';
import BPCHandMatrix from './BPCHandMatrix/BPCHandMatrix';
import {
  computeRangePercentageForNbCombos,
  computeTotalCombosForRange,
  formatRangeDescription,
  generateRangeToDuplicate,
} from '../ranges.helper';
import { useNavigate } from 'react-router-dom';
import { BiDotsVerticalRounded } from 'react-icons/bi';
import { RoutePath } from 'router/routes.paths';

export default function RangeCard({
  range,
  linkTo,
  ...restStyleProps
}: { range: PokerRange; linkTo?: string } & StyleProps) {
  const navigate = useNavigate();

  return (
    <Card
      cursor={linkTo ? 'pointer' : undefined}
      onClick={() => {
        if (!linkTo) {
          return;
        }

        navigate(linkTo);
      }}
      {...restStyleProps}
    >
      <CardBody position={'relative'}>
        {linkTo && (
          <Menu>
            <MenuButton
              onClick={(e) => e.stopPropagation()}
              as={IconButton}
              icon={<BiDotsVerticalRounded />}
              variant={'ghost'}
              colorScheme="gray"
              position={'absolute'}
              top={0}
              right={0}
            />
            <MenuList>
              <MenuItem
                onClick={(e) => {
                  e.stopPropagation();
                  navigate(`${RoutePath.ranges}/${range.id}/edition`);
                }}
              >
                Modifier
              </MenuItem>
              <MenuItem
                onClick={(e) => {
                  e.stopPropagation();
                  navigate(`${RoutePath.ranges}/creation`, {
                    state: { range: generateRangeToDuplicate(range) },
                  });
                }}
              >
                Dupliquer
              </MenuItem>
            </MenuList>
          </Menu>
        )}
        <Box my={4}>
          <BPCHandMatrix
            smallFontSize={true}
            rangeDetails={range.details}
            style={{ mb: 2, mx: 'auto' }}
          />

          <Center>
            <VStack spacing={2}>
              <Text>{`${range.category}${
                range.name ? ` - ${range.name}` : ''
              }`}</Text>
              <Text fontSize={'sm'}>{`${formatRangeDescription(
                range,
              )} (${computeRangePercentageForNbCombos(
                computeTotalCombosForRange(range.details).total,
              )})`}</Text>
            </VStack>
          </Center>
        </Box>
      </CardBody>
    </Card>
  );
}
