import {
  Button,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Switch,
  UseDisclosureReturn,
} from '@chakra-ui/react';
import EditNiveauForm from './EditNiveauForm';
import { StructureLevel } from '../tournoi.types';
import { ChangeEvent, useMemo, useRef, useState } from 'react';
import {
  getDefaultEcartFromSmallBlind,
  getPreviousAndNextLevelsAtIndex,
  getStructureLevelLabel,
} from '../structure.helper';
import { FormikProps } from 'formik';

export const DEFAULT_DUREE_PAUSE = 10;
export const DEFAULT_DUREE_NIVEAU = 30;
export const DEFAULT_SMALL_BLIND = 100;
export const DEFAULT_BIG_BLIND = 200;

export default function NouveauNiveauModal({
  isPause,
  structure,
  isOpen,
  onClose,
  onSubmit,
}: {
  isPause: boolean;
  structure: StructureLevel[];
  onSubmit: (newStructure: StructureLevel[]) => void;
} & Pick<UseDisclosureReturn, 'onClose' | 'isOpen'>) {
  const formRef = useRef<FormikProps<StructureLevel>>(null);
  const [selectedNiveauIndex, setSelectedNiveauIndex] = useState(
    structure.length > 0 ? structure.length - 1 : 0,
  );
  const [keepOpened, setKeepOpened] = useState(false);

  const initialValues: StructureLevel = useMemo(() => {
    //Besoin de ce vieuw hack pour que la suppression d'un niveau fonctionne : sinon l'index du selectedNiveau est trop haut et ça pete
    let correctedSelectedNiveauIndex = selectedNiveauIndex;
    if (selectedNiveauIndex >= structure.length) {
      correctedSelectedNiveauIndex = structure.length - 1;
      setSelectedNiveauIndex(correctedSelectedNiveauIndex);
    }
    const { currentLevel, previousLevelNotPause } =
      getPreviousAndNextLevelsAtIndex(structure, correctedSelectedNiveauIndex);
    if (isPause) {
      if (currentLevel.isPause) {
        return {
          isPause: true,
          smallBlind: -1,
          bigBlind: -1,
          duree: currentLevel.duree,
        };
      } else if (previousLevelNotPause) {
        return {
          isPause: true,
          smallBlind: -1,
          bigBlind: -1,
          duree: previousLevelNotPause.duree,
        };
      }
      return {
        isPause: true,
        smallBlind: -1,
        bigBlind: -1,
        duree: DEFAULT_DUREE_PAUSE,
      };
    }
    const niveauToCompare = currentLevel.isPause
      ? previousLevelNotPause
      : currentLevel;

    if (niveauToCompare) {
      const ecart = getDefaultEcartFromSmallBlind(niveauToCompare.smallBlind);
      return {
        isPause: false,
        smallBlind: niveauToCompare.smallBlind + ecart,
        bigBlind: niveauToCompare.bigBlind + 2 * ecart,
        duree: niveauToCompare.duree,
      };
    }
    return {
      isPause: false,
      smallBlind: DEFAULT_SMALL_BLIND,
      bigBlind: DEFAULT_BIG_BLIND,
      duree: DEFAULT_DUREE_NIVEAU,
    };
  }, [isPause, selectedNiveauIndex, structure]);

  function onLevelChange(event: ChangeEvent<HTMLSelectElement>) {
    setSelectedNiveauIndex(+event.target.value);
  }

  function onEditNiveauSubmit(newNiveau: StructureLevel) {
    const newStructure = [...structure];
    newStructure.splice(selectedNiveauIndex + 1, 0, newNiveau);
    setSelectedNiveauIndex(selectedNiveauIndex + 1);
    if (!keepOpened) {
      onClose();
    }
    return onSubmit(newStructure);
  }
  return (
    <Modal isOpen={isOpen} onClose={onClose!}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {isPause ? 'Nouvelle pause' : 'Nouveau niveau'}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl>
            <FormLabel htmlFor="nouveau-niveau-select">
              Ajouter après le niveau
            </FormLabel>
            <Select
              onChange={onLevelChange}
              id="nouveau-niveau-select"
              value={selectedNiveauIndex}
            >
              {structure.map((_, i) => (
                <option key={i} value={i}>
                  {getStructureLevelLabel(structure, i)}
                </option>
              ))}
            </Select>
          </FormControl>
          <EditNiveauForm
            initialValues={initialValues}
            onSubmit={onEditNiveauSubmit}
            formRef={formRef}
          />
          <FormControl display="flex" alignItems="center" mt={3}>
            <FormLabel htmlFor="keep-opened">
              Ajouter un nouveau niveau après celui-ci
            </FormLabel>
            <Switch
              id="keep-opened"
              isChecked={keepOpened}
              colorScheme="orange"
              onChange={(event) => {
                setKeepOpened(event.target.checked);
              }}
            />
          </FormControl>
        </ModalBody>

        <ModalFooter>
          <Button variant="ghost" mr={3} onClick={onClose}>
            Annuler
          </Button>
          <Button
            onClick={() => {
              if (formRef.current) {
                formRef.current.handleSubmit();
              }
            }}
          >
            Valider
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
