import { computeTournoiTotalMoney } from 'features/tournois/tournoi.helper';
import {
  Championnat,
  ChampionnatFirebase,
  ChampionnatPlayer,
} from './championnat.types';
import { computeChampionnatPlayerWithTournoiPlayer } from 'features/players/players.helpers';
import { Tournoi } from 'features/tournois/tournoi.types';
import { deleteUndefinedKeys } from 'common/common.helper';

export function mapChampionnatFirebaseToChampionnat(
  championnatFirebase: ChampionnatFirebase,
  championnatTournois: Tournoi[],
): Championnat {
  const playersInChampionnat = (championnatTournois || []).reduce<
    ChampionnatPlayer[]
  >((acc, championnatTournoi) => {
    (championnatTournoi?.players || []).forEach((player) => {
      const existingChampionnatPlayer = acc.find(({ id }) => id === player.id);
      const playerToUpdate = computeChampionnatPlayerWithTournoiPlayer(
        championnatTournoi,
        player,
        existingChampionnatPlayer,
        championnatFirebase.methodeCalculPoints,
      );
      acc = [
        ...acc.filter(({ id }) => id !== playerToUpdate.id),
        playerToUpdate,
      ];
    });

    return acc;
  }, []);

  return {
    ...championnatFirebase,
    tournois: championnatTournois.sort(
      (a, b) => b.date.getTime() - a.date.getTime(),
    ),
    players: playersInChampionnat.sort((a, b) => b.points - a.points),
    nbTournois: championnatTournois.length,
    totalPrizePool: championnatTournois.reduce<number>(
      (acc, tournoi) => acc + computeTournoiTotalMoney(tournoi),
      0,
    ),
  };
}

export function mapChampionnatToChampionnatFirebase({
  id,
  name,
  isActive,
  methodeCalculPoints,
}: Championnat): ChampionnatFirebase {
  const mappedChampionnat = {
    id,
    name,
    isActive,
    methodeCalculPoints,
  };
  deleteUndefinedKeys(mappedChampionnat);
  return mappedChampionnat;
}
