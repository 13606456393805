import { Combo } from '@holdem-poker-tools/hand-matrix';

export const ALL_SEATS = [
  'smallBlind',
  'bigBlind',
  'utg',
  'utg1',
  'utg2',
  'utg3',
  'lowJack',
  'highJack',
  'cutoff',
  'button',
] as const;
export type Seat = (typeof ALL_SEATS)[number];

export const rangeTypes = [
  'openFold',
  'openCall',
  'open4bet',
  'open4betAllIn',
  'allIn',
  'threeBet',
] as const;
export type RangeType = (typeof rangeTypes)[number];

export const rangeCategories = ['Open', '3bet', 'Call'] as const;
export type RangeCategory = (typeof rangeCategories)[number];

export type PokerRangePosition = {
  table?: number;
  seats: Seat[];
};

export type PokerRangeFirebase = {
  id: string;
  name?: string;
  category: RangeCategory;
  positions: PokerRangePosition[];
  stack?: { min: number; max?: number };
  note?: string; //taille de la relance par exemple
  details: Partial<Record<Combo, Partial<Record<RangeType, number>>>>;
};

export type PokerRange = PokerRangeFirebase;

export type ComboType = 'offsuit' | 'suited' | 'pocketPair';
