import {
  Alert,
  AlertDescription,
  AlertIcon,
  Button,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Switch,
  UseDisclosureReturn,
} from '@chakra-ui/react';
import { useRef, useState } from 'react';
import { createPlayer, useGetPlayersAndAdminList } from '../players.api';
import Loader from 'common/components/Loader';
import { Player } from '../players.types';
import EditProfileForm from 'features/user/components/EditProfileForm';
import { FormikProps } from 'formik';

export default function NouveauPlayerModal({
  onSubmit,
  isOpen,
  onClose,
  playerToComplete = { name: '' },
  allowMultipleCreation = true,
}: {
  onSubmit: (newPlayer: Player) => void;
  playerToComplete?: Omit<Player, 'id'>;
  allowMultipleCreation?: boolean;
} & Pick<UseDisclosureReturn, 'onClose' | 'isOpen'>) {
  const formRef = useRef<FormikProps<Omit<Player, 'id'>>>(null);
  const [keepOpened, setKeepOpened] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);

  const { data: playerList, isLoading: isPlayersLoading } =
    useGetPlayersAndAdminList();

  async function handleSubmit(newPlayer: Omit<Player, 'id'>) {
    setErrorMessage(undefined);
    setIsLoading(true);
    const similarPlayer = playerList.filter((p) => p.name === newPlayer.name);
    if (similarPlayer.length) {
      setErrorMessage(`Il y a déjà un joueur avec le nom ${newPlayer.name}`);
      setIsLoading(false);
      return;
    }

    const { id } = await createPlayer(newPlayer);

    if (!keepOpened) {
      onClose();
    }
    setIsLoading(false);
    return onSubmit({ id, ...newPlayer });
  }

  if (isPlayersLoading) {
    return <Loader />;
  }
  return (
    <Modal isOpen={isOpen} onClose={onClose!}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Créer un nouveau joueur</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {errorMessage ? (
            <Alert status="error" mb={3}>
              <AlertIcon />
              <AlertDescription>{errorMessage}</AlertDescription>
            </Alert>
          ) : undefined}
          <EditProfileForm
            onSubmit={handleSubmit}
            user={playerToComplete}
            isEmailDisabled={!!playerToComplete?.email}
            hideSubmitButton
            formRef={formRef}
            isLoading={isLoading}
          />
          {allowMultipleCreation && (
            <FormControl display="flex" alignItems="center" mt={3}>
              <FormLabel htmlFor="keep-opened">
                Créer un nouveau joueur après celui-ci
              </FormLabel>
              <Switch
                id="keep-opened"
                isChecked={keepOpened}
                colorScheme="orange"
                onChange={(event) => {
                  setKeepOpened(event.target.checked);
                }}
              />
            </FormControl>
          )}
        </ModalBody>
        <ModalFooter>
          <Button variant="ghost" mr={3} onClick={onClose}>
            Annuler
          </Button>
          <Button
            isLoading={isLoading}
            onClick={() => {
              if (formRef.current) {
                formRef.current.handleSubmit();
              }
            }}
          >
            Valider
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
