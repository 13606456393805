import { Button, Image } from '@chakra-ui/react';
import { firebase_auth } from 'api/firebase.config';
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';

export default function GoogleLoginButton() {
  function signInWithGoogle() {
    const provider = new GoogleAuthProvider();
    signInWithPopup(firebase_auth, provider)
      .then((_result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        // const credential = GoogleAuthProvider.credentialFromResult(result);
        // if (credential) {
        //   const token = credential.accessToken;
        //   console.info(`token = `, token);
        // }
        // // The signed-in user info.
        // const user = result.user;
        // console.info(`user = `, user);
        // IdP data available using getAdditionalUserInfo(result)
        // ...
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.customData?.email;
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);
        console.error(
          `Erreur lors de l'auhentification Google  - code:${errorCode} message:${errorMessage} email:${email} credentials:${credential}`,
        );
      });
  }
  return (
    <Button
      colorScheme="black"
      leftIcon={<Image boxSize={'20px'} alt="google" src="/google-icon.png" />}
      variant="outline"
      onClick={signInWithGoogle}
    >
      Continue avec Google
    </Button>
  );
}
