import { Tournoi } from '../tournoi.types';
import { TournoiLive } from './tournoi.live.types';
import { getCurrentStateOfTournamentStructure } from './tournoi.live.helpers';

export function mapTournoiToTournoiLive(
  tournoi: Tournoi,
  currentTimestamp: number,
): TournoiLive {
  const stateOfTournamentStructure = getCurrentStateOfTournamentStructure(
    tournoi,
    currentTimestamp,
  );

  return {
    ...tournoi,
    ...stateOfTournamentStructure,
    currentTimestamp,
  };
}

export function mapTournoiLiveToTournoi({
  currentLevel: _currentLevel,
  previousLevel: _previousLevel,
  nextLevel: _nextLevel,
  nextLevelNotPause: _nextLevelNotPause,
  nextPause: _nextPause,
  secondsLeftInLevel: _secondsLeftInLevel,
  secondsLeftToNextPause: _secondsLeftToNextPause,
  currentTimestamp: _currentTimestamp,
  ...restTournoi
}: TournoiLive): Tournoi {
  return restTournoi;
}
