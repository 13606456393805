import { Card, CardBody, Flex, StyleProps } from '@chakra-ui/react';
import { Championnat } from '../championnat.types';
import { ChampionnatCard } from './ChampionnatCard';
import { BPC_CARD_WIDTH } from 'common/common.constants';
import { RoutePath } from 'router/routes.paths';
import { useNavigate } from 'react-router-dom';
import { FaPlus } from 'react-icons/fa';

export default function ChampionnatList({
  championnats,
  withCreateCard,
  flexWrap,
  ...restStyleProps
}: {
  championnats: Championnat[];
  withCreateCard?: boolean;
} & StyleProps) {
  const navigate = useNavigate();
  const mustWrap = flexWrap === 'wrap';
  return (
    <Flex
      display={!mustWrap ? undefined : { base: 'block', md: 'flex' }}
      {...restStyleProps}
    >
      {championnats.length ? (
        <>
          {withCreateCard && (
            <Card
              border="1px"
              borderColor="gray.200"
              m={4}
              minWidth={
                !mustWrap
                  ? BPC_CARD_WIDTH
                  : { base: 'unset', md: BPC_CARD_WIDTH }
              }
              maxWidth={
                !mustWrap
                  ? BPC_CARD_WIDTH
                  : { base: 'unset', md: BPC_CARD_WIDTH }
              }
              cursor={'pointer'}
              onClick={() => navigate(RoutePath.championnatNew)}
            >
              <CardBody textColor={'orange.500'}>
                <FaPlus style={{ margin: 'auto', height: '100%' }} size="3em" />
              </CardBody>
            </Card>
          )}
          {championnats
            .sort((champA, champB) => (champA.name < champB.name ? 1 : -1))
            .map((championnat) => (
              <ChampionnatCard
                key={championnat.id}
                border="1px"
                borderColor="gray.200"
                minWidth={
                  !mustWrap
                    ? BPC_CARD_WIDTH
                    : { base: 'unset', md: BPC_CARD_WIDTH }
                }
                maxWidth={
                  !mustWrap
                    ? BPC_CARD_WIDTH
                    : { base: 'unset', md: BPC_CARD_WIDTH }
                }
                m={4}
                championnat={championnat}
              />
            ))}
        </>
      ) : (
        <Card m={4}>
          <CardBody>Aucun championnat à afficher</CardBody>
        </Card>
      )}
    </Flex>
  );
}
