import { Player } from 'features/players/players.types';
import { Tournoi, TournoiPlayer } from '../tournoi.types';
import {
  TournoiImportedFirebase,
  TournoiPlayerImported,
} from './tournoi.imported.types';
import dayjs from 'dayjs';
import { getAudioPlayerName } from 'features/players/players.helpers';

export function mapTournoiImportedFirebaseToTournoi(
  tournoiImported: TournoiImportedFirebase,
  playerList: Player[],
): Tournoi {
  const {
    date: dateRaw,
    name,
    players,
    prizePool,
    championnatIds,
    id,
    montantBuyIn,
    jetonsRecus,
    status,
  } = tournoiImported;
  const date = dayjs(dateRaw).toDate();
  return {
    date,
    name,
    startDate: date,
    lastRestartDate: date,
    players: (players || [])
      .map((tournoiPlayer) =>
        mapTournoiPlayerImportedToTournoiPlayer(
          tournoiPlayer,
          playerList,
          tournoiImported,
        ),
      )
      .sort((a, b) => (a.position ?? -1) - (b.position ?? -1)),
    structure: [],
    prizePool: prizePool ?? [],
    killsHistory: [],
    championnatIds: championnatIds ?? [],
    id,
    montantBuyIn,
    jetonsRecus,
    status,
    elapsedSecInTournament: 0,
    type: 'imported',
  };
}

export function mapTournoiPlayerImportedToTournoiPlayer(
  player: TournoiPlayerImported,
  playerList: Player[],
  tournoi: TournoiImportedFirebase,
): TournoiPlayer {
  const { montantBuyIn, prizePool } = tournoi;
  const { nbRecave, nbKills, killedBy } = player;
  const playerInList = playerList.find(({ id }) => id === player.id);

  const prizeInPercent =
    prizePool && player.position && player.position <= prizePool.length
      ? prizePool[player.position - 1]
      : undefined;

  return {
    ...player,
    nbRecave,
    nbKills,
    killedBy,
    name: playerInList?.name ?? '',
    photo: playerInList?.photo ?? '',
    depense: montantBuyIn * (nbRecave + 1),
    nameForAudio: playerInList ? getAudioPlayerName(playerInList) : '',
    prizeInPercent,
    prizeInEuro: prizeInPercent
      ? computePrizeInEuro(prizeInPercent, tournoi)
      : undefined,
  };
}

function computePrizeInEuro(
  price: number,
  {
    montantBuyIn,
    players,
  }: Pick<TournoiImportedFirebase, 'montantBuyIn' | 'players'>,
  arrondi = 5,
) {
  const tournoiTotalCave =
    (players || []).length +
    (players || []).reduce((acc, { nbRecave }) => acc + (nbRecave ?? 0), 0);

  const tournoiTotalMoney = montantBuyIn * tournoiTotalCave;
  return Math.round((price * tournoiTotalMoney) / arrondi) * arrondi;
}
