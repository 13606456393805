export enum RoutePath {
  home = '/home',
  championnats = '/championnats',
  championnatNew = '/championnats/creation',
  tournois = '/tournois',
  tournoiNew = '/tournois/creation',
  tournoiImport = '/tournois/import',
  joueurs = '/joueurs',
  joueurNew = '/joueurs/creation',
  signUp = '/inscription',
  editIncompleteProfile = '/edition-profil-incomplet',
  login = '/login',
  editProfile = '/profil',
  outils = '/outils',
  formule = '/outils/formule',
  ranges = '/outils/ranges',
}
