import { ref, onValue } from 'firebase/database';
import { firebase_realtime_db } from '../firebase.config';
import { RealtimeOptions } from '../types';

type GetRealtimeObjectParams<T> = {
  objectId: string;
  setObject: (o: T) => void;
  options?: RealtimeOptions;
};

export type GetRealtimeObject<T> = (params: GetRealtimeObjectParams<T>) => void;

export const getRealtimeObject = <T>(
  listName: string,
  { objectId, setObject, options }: GetRealtimeObjectParams<T>,
) => {
  const { onlyOnce = false } = options || {};
  const objRef = ref(firebase_realtime_db, `${listName}/${objectId}`);
  onValue(
    objRef,
    (snapshot) => {
      const id = snapshot.key;
      const data = snapshot.val();
      if (data) {
        setObject({ id, ...data });
      }
    },
    {
      onlyOnce,
    },
  );
};
