import { Tournoi, TournoiRealtime } from './tournoi.types';
import {
  upsertRealtimeObject,
  upsertRealtimeObjectProp,
} from 'api/realtime/upsertRealtimeObject';
import { getRealtimeList } from 'api/realtime/getRealtimeList';
import { useEffect, useMemo, useState } from 'react';
import {
  mapTournoiRealTimeToTournoi,
  mapTournoiToTournoiRealTime,
} from './tournoi.mappers';
import { useGetPlayersList } from 'features/players/players.api';
import { getRealtimeObject } from 'api/realtime/getRealtimeObject';
import { TOURNOI_COLLECTION_NAME } from 'api/collectionNames.constants';
import { getUnixTimestamp } from 'common/date.helper';
import { mapTournoiToTournoiLive } from './live/tournoi.live.mappers';
import { TournoiLive } from './live/tournoi.live.types';

export function useGetTournoiList() {
  const [tournoisRealTime, setTournois] = useState<TournoiRealtime[]>([]);
  const { data: players, isLoading } = useGetPlayersList();

  const tournois = useMemo(
    () =>
      tournoisRealTime
        .map((tournoi) => mapTournoiRealTimeToTournoi(tournoi, players))
        .sort((a, b) => b.date.getTime() - a.date.getTime()),
    [tournoisRealTime, players],
  );

  useEffect(() => {
    async function getData() {
      await getRealtimeList(TOURNOI_COLLECTION_NAME, { setList: setTournois });
    }

    getData();
  }, []);
  return { tournois, setTournois, isLoading };
}

export function useGetTournoiById(id: string) {
  const [tournoiRealTime, setTournoi] = useState<TournoiRealtime>();
  const { data: players, isLoading: isLoadingPlayerList } = useGetPlayersList();

  const tournoi = useMemo(
    () =>
      tournoiRealTime && !isLoadingPlayerList
        ? mapTournoiRealTimeToTournoi(tournoiRealTime, players)
        : undefined,
    [tournoiRealTime, isLoadingPlayerList, players],
  );

  useEffect(() => {
    async function getData() {
      await getRealtimeObject(TOURNOI_COLLECTION_NAME, {
        objectId: id,
        setObject: setTournoi,
      });
    }

    getData();
  }, [id]);

  return {
    tournoi,
    setTournoi,
    isLoading: isLoadingPlayerList,
  };
}

export async function upsertTournoi(
  tournoiToUpsert: Omit<Tournoi, 'id' | 'type'> & { id?: string },
) {
  const tournoiRealTime = mapTournoiToTournoiRealTime(tournoiToUpsert);

  const tournoiId = (
    await upsertRealtimeObject(TOURNOI_COLLECTION_NAME, {
      objectId: tournoiToUpsert.id,
      object: tournoiRealTime,
    })
  ).id;

  return tournoiId;
}

export async function upsertTournoiStatus(
  tournoiToUpsert: Pick<
    TournoiLive,
    'id' | 'status' | 'startDate' | 'lastRestartDate' | 'elapsedSecInTournament'
  >,
) {
  if (tournoiToUpsert.startDate) {
    await upsertRealtimeObjectProp(TOURNOI_COLLECTION_NAME, {
      objectId: tournoiToUpsert.id,
      propVal: tournoiToUpsert.startDate.toISOString(),
      propPath: '/startDate',
    });
  }

  if (tournoiToUpsert.lastRestartDate) {
    await upsertRealtimeObjectProp(TOURNOI_COLLECTION_NAME, {
      objectId: tournoiToUpsert.id,
      propVal: tournoiToUpsert.lastRestartDate.toISOString(),
      propPath: '/lastRestartDate',
    });
  }
  if (tournoiToUpsert.elapsedSecInTournament) {
    await upsertRealtimeObjectProp(TOURNOI_COLLECTION_NAME, {
      objectId: tournoiToUpsert.id,
      propVal: tournoiToUpsert.elapsedSecInTournament,
      propPath: '/elapsedSecInTournament',
    });
  }
  await upsertRealtimeObjectProp(TOURNOI_COLLECTION_NAME, {
    objectId: tournoiToUpsert.id,
    propVal: tournoiToUpsert.status,
    propPath: '/status',
  });
}

export function useGetTournoiLiveById(id: string) {
  const [currentTimestamp, setCurrentTimestamp] = useState(getUnixTimestamp());
  const { tournoi, isLoading } = useGetTournoiById(id);

  // Rafraichit le tournoi chaque seconde pour mettre à jour les compteurs de temps
  useEffect(() => {
    if (tournoi?.status === 'running' || tournoi?.status === 'paused') {
      const currentTimestampInterval = setInterval(() => {
        setCurrentTimestamp(getUnixTimestamp());
      }, 1000);
      return () => clearInterval(currentTimestampInterval);
    }
    return;
  }, [tournoi?.status]);

  const tournoiLive: TournoiLive | undefined = useMemo(() => {
    return tournoi && !isLoading
      ? mapTournoiToTournoiLive(tournoi, currentTimestamp)
      : undefined;
  }, [tournoi, currentTimestamp, isLoading]);
  return {
    tournoiLive,
    isLoading,
  };
}
