import { Box } from '@chakra-ui/react';
import NouveauChampionnatForm from 'features/championnats/components/NouveauChampionnatForm';
import { RoutePath } from 'router/routes.paths';
import BPCPageHeading from 'common/components/BPCPageHeading';

export default function NouveauChampionnatPage() {
  return (
    <Box>
      <BPCPageHeading
        title="Nouveau championnat"
        breadcrumbItems={[
          { to: RoutePath.home, name: 'Home' },
          { to: RoutePath.championnats, name: 'Championnats' },
        ]}
      />
      <NouveauChampionnatForm />
    </Box>
  );
}
