import { Navigate, Outlet } from 'react-router-dom';
import { RoutePath } from 'router/routes.paths';
import useFirebaseAuthentication from 'features/auth/useFirebaseAuthentication';
import { ConnectedUserContext } from 'features/user/useConnectedUserContext';
import Loader from 'common/components/Loader';
import { useGetPlayerByUserId } from 'features/players/players.api';

export default function ConnectedRoute() {
  const { firebaseAuthUser } = useFirebaseAuthentication();
  const { player: user, isLoading } = useGetPlayerByUserId(
    firebaseAuthUser?.uid,
  );

  if (isLoading) {
    return <Loader fullScreen />;
  }
  if (!user) {
    return <Navigate to={RoutePath.editIncompleteProfile} />;
  }

  return (
    <ConnectedUserContext.Provider value={{ user }}>
      <Outlet />
    </ConnectedUserContext.Provider>
  );
}
