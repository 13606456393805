import { useEffect, useMemo, useState } from 'react';
import { Player } from './players.types';
import { useGetCollection } from 'api/firestore/getCollection';
import { useGetDocById } from 'api/firestore/getDocById';
import { where } from 'firebase/firestore';
import { useGetDocsByQuery } from 'api/firestore/getDocsByQuery';
import { createDoc } from 'api/firestore/createDoc';
import { updateDoc } from 'api/firestore/updateDoc';
import { PLAYERS_COLLECTION_NAME } from 'api/collectionNames.constants';

export function createPlayer(player: Omit<Player, 'id'>) {
  return createDoc<Player>(PLAYERS_COLLECTION_NAME, player);
}

export function updatePlayer(player: Player) {
  return updateDoc<Player>(PLAYERS_COLLECTION_NAME, player);
}

export function useGetPlayersList() {
  const { data: rawData, isLoading } = useGetCollection<Player>(
    PLAYERS_COLLECTION_NAME,
  );
  const data = useMemo(
    () => rawData.filter(({ role }) => role !== 'admin'),
    [rawData],
  );
  return { data, isLoading };
}
export function useGetPlayersAndAdminList() {
  return useGetCollection<Player>(PLAYERS_COLLECTION_NAME);
}

export function useGetPlayerById(playerId?: string) {
  return useGetDocById<Player>(PLAYERS_COLLECTION_NAME, playerId);
}

export function useGetPlayerByUserId(userId?: string) {
  const [player, setPlayer] = useState<Player>();
  const [isLoading, setIsLoading] = useState(true);
  const queryConstraints = useMemo(
    () => [where('userAccountId', '==', userId ?? 'noUserId')],
    [userId],
  );
  const { data, isLoading: isPlayerLoading } = useGetDocsByQuery<Player>(
    PLAYERS_COLLECTION_NAME,
    queryConstraints,
    { skip: !userId },
  );
  useEffect(() => {
    if (!isPlayerLoading) {
      setPlayer(data?.length ? data[0] : undefined);
      setIsLoading(false);
    }
  }, [data, isPlayerLoading]);
  return { player, isLoading };
}
