import { getStorage } from 'api/storage/getStorage';
import { createDoc } from 'api/firestore/createDoc';
import { deleteDoc } from 'api/firestore/deleteDoc';
import { useCallback, useState } from 'react';
import { sleep } from './common.helper';
import { getDocsByQuery } from 'api/firestore/getDocsByQuery';
import { where } from 'firebase/firestore';
import { Howl } from 'howler';

const TTS_COLLECTION = 'text_to_speech';
const AUDIO_STORAGE_FOLDER = 'audio';

const AUDIO_COLLECTION = 'audio';

export type AudioFirebase = {
  id: string;
  message: string;
  mp3Url: string;
};

async function waitForNewMp3Url(
  nbRetry: number,
  mp3id: string,
): Promise<string> {
  const audioFileStorageUrl = `${AUDIO_STORAGE_FOLDER}/${mp3id}.mp3`;
  try {
    const url = await getStorage(audioFileStorageUrl);
    return url;
  } catch (e) {
    if (nbRetry > 0) {
      return sleep(200).then(() => waitForNewMp3Url(nbRetry - 1, mp3id));
    } else {
      throw e;
    }
  }
}

export async function parle(message: string) {
  let url: string;
  // J'essaye de récupérer un fichier audio déjà généré
  const existingAudioFiles = await getDocsByQuery<AudioFirebase>(
    AUDIO_COLLECTION,
    where('message', '==', message),
  );
  if (existingAudioFiles.length) {
    url = existingAudioFiles[0].mp3Url;
  } else {
    //Je n'ai pas trouvé le fichier

    //1. je crée un fichier dans la TTS_COLLECTION pour que le MP3 soit généré, je le joue et je le stocke
    const { id } = await createDoc(TTS_COLLECTION, {
      text: message,
    });

    //2. j'attend l'url du fichier généré
    url = await waitForNewMp3Url(20, id);

    //3. je supprimer le fichier de demande dans la TTS_COLLECTION
    await deleteDoc(TTS_COLLECTION, id);

    //4. j'ajoute une entrée dans l'AUDIO_COLLECTION pour éviter la regénération future du même message
    await createDoc<AudioFirebase>(AUDIO_COLLECTION, {
      message,
      mp3Url: url,
    });
  }

  const audio = new Howl({ src: [url], autoplay: true });
  while (audio.playing()) {
    await sleep(200);
  }
}

export function useParle() {
  const [isParleLoading, setIsLoading] = useState(false);
  const parleCallback = useCallback(async (message: string) => {
    setIsLoading(true);
    await parle(message);
    setIsLoading(false);
  }, []);
  return {
    parle: parleCallback,
    isParleLoading,
  };
}
