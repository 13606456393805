import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  StyleProps,
} from '@chakra-ui/react';
import { BPCLink } from './BPCLink';

export type BPCBreadcrumbItem = { to: string; name: string };

export default function BPCBreadcrumb({
  items,
  mb = 6,
  ...restStyleProps
}: {
  items: BPCBreadcrumbItem[];
} & StyleProps) {
  return (
    <Breadcrumb
      textTransform={'uppercase'}
      fontSize={'xs'}
      fontStyle={'italic'}
      separator={'>'}
      mb={mb}
      {...restStyleProps}
    >
      {items.map(({ to, name }, i) => (
        <BreadcrumbItem key={i}>
          <BreadcrumbLink as={BPCLink} to={to}>
            {name}
          </BreadcrumbLink>
        </BreadcrumbItem>
      ))}
    </Breadcrumb>
  );
}
