import { StructureLevel } from '../tournoi.types';
import { BPCInput } from 'common/components/form/BPCInput';
import { Formik, FormikProps } from 'formik';
import { BPCYup } from 'common/form.validation';
import { RefObject } from 'react';

const NouveauNiveauSchema = BPCYup.object().shape({
  smallBlind: BPCYup.number().required(),
  bigBlind: BPCYup.number().required(),
  duree: BPCYup.number().min(
    1,
    'La durée du niveau ne peut pas être inférieure à 1 minute',
  ),
  isPause: BPCYup.bool(),
});

export default function EditNiveauForm({
  initialValues,
  onSubmit,
  formRef,
}: {
  initialValues: StructureLevel;
  onSubmit: (newValues: StructureLevel) => void;
  formRef: RefObject<FormikProps<StructureLevel>>;
}) {
  return (
    <Formik
      innerRef={formRef}
      initialValues={initialValues}
      enableReinitialize
      validationSchema={NouveauNiveauSchema}
      onSubmit={onSubmit}
    >
      {({ values }) => (
        <>
          {!values.isPause && (
            <>
              <BPCInput
                fieldName="smallBlind"
                label="Small blind"
                type="number"
              />
              <BPCInput fieldName="bigBlind" label="Big blind" type="number" />
            </>
          )}
          <BPCInput
            fieldName="duree"
            label="Durée"
            type="number"
            suffix="min"
          />
        </>
      )}
    </Formik>
  );
}
