import { Avatar, StyleProps } from '@chakra-ui/react';
import { FC, useMemo } from 'react';

import { Tournoi } from '../tournoi.types';
import { RoutePath } from 'router/routes.paths';
import { FaFileImport, FaTrophy } from 'react-icons/fa6';
import { BPCCard } from 'common/components/BPCCard';
import { useNavigate } from 'react-router-dom';
import {
  filterNameForAvatarDisplay,
  formatTournoiDate,
  generateTournoiToDuplicate,
  getTournoiName,
  getTournoiStatusText,
} from '../tournoi.helper';
import { useConnectedUserContext } from 'features/user/useConnectedUserContext';
import { useSons } from 'common/sons.helper';
import { generateTournoiImportedToDuplicate } from '../imported/tournoi.imported.helper';

//TODO: s'inscrire à un tournoi
export const TournoiCard: FC<{ tournoi: Tournoi } & StyleProps> = ({
  tournoi,
  ...restStyleProps
}) => {
  const { joueSon } = useSons();
  const { id, name, date, players, status, type } = tournoi;
  const navigate = useNavigate();
  const { user } = useConnectedUserContext();

  const winnerPlayer = useMemo(
    () => players.find(({ position }) => position === 1),
    [players],
  );

  const nbJoueurs = useMemo(() => players.length, [players]);
  const subTitles = useMemo(() => {
    let st = [`${nbJoueurs} joueurs`, getTournoiStatusText(status)];
    if (name) {
      const formattedDate = formatTournoiDate(date);
      st = [formattedDate, ...st];
    }
    return st;
  }, [date, name, nbJoueurs, status]);

  const basePath =
    type === 'imported' ? RoutePath.tournoiImport : RoutePath.tournois;

  const tournoiAvatarProps = {
    name:
      name && type === 'live' && !winnerPlayer
        ? filterNameForAvatarDisplay(name)
        : undefined,
    bg:
      name && !winnerPlayer
        ? undefined
        : type === 'live'
        ? 'orange.500'
        : 'gray.500',
    icon:
      type === 'imported' ? (
        <FaFileImport fontSize={winnerPlayer ? '1rem' : '2.5rem'} />
      ) : (
        <FaTrophy fontSize={winnerPlayer ? '1rem' : '2.5rem'} />
      ),
  };
  const winnerAvatarProps = winnerPlayer
    ? {
        name: winnerPlayer.name,
        src: winnerPlayer.photo,
      }
    : undefined;

  return (
    <BPCCard
      onClick={async () => {
        if (user?.role === 'admin' && status !== 'finished') {
          await joueSon('porte', 0.1, false);
          navigate(`${basePath}/${id}/live`);
        } else {
          navigate(`${basePath}/${id}`);
        }
      }}
      title={getTournoiName({ name, date }, 'short')}
      subTitles={subTitles}
      avatarProps={winnerAvatarProps ?? tournoiAvatarProps}
      avatarBadgeProps={
        winnerPlayer
          ? {
              children: <Avatar size="sm" {...tournoiAvatarProps} />,
            }
          : undefined
      }
      menuItems={[
        {
          name: 'Modifier',
          onClick: () => navigate(`${basePath}/${id}/edition`),
        },
        {
          name: 'Dupliquer',
          onClick: () =>
            navigate(`${basePath}/creation`, {
              state: {
                tournoi:
                  type === 'imported'
                    ? generateTournoiImportedToDuplicate(tournoi)
                    : generateTournoiToDuplicate(tournoi),
              },
            }),
        },
      ]}
      {...restStyleProps}
    />
  );
};
