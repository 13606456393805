import { Tournoi, TournoiPlayer } from 'features/tournois/tournoi.types';
import { Player } from './players.types';
import { ChampionnatPlayer } from 'features/championnats/championnat.types';
import { calculerPointsFromTournoiPlayer } from 'features/championnats/championnat.helper';
import { formatNumber2Digits } from 'common/string.helper';

export function getAudioPlayerName({
  nameForAudio,
  name,
}: Pick<Player, 'nameForAudio' | 'name'>) {
  return nameForAudio && nameForAudio.length ? nameForAudio : name;
}

export function computeChampionnatPlayerWithTournoiPlayer(
  tournoi: Tournoi,
  tournoiPlayer: TournoiPlayer,
  existingChampionnatPlayer: ChampionnatPlayer | undefined,
  formuleDeCalcul: string | undefined,
): ChampionnatPlayer {
  const nbTournoiPlayed =
    (existingChampionnatPlayer?.nbTournoiPlayed || 0) +
    (tournoi.status === 'finished' ? 1 : 0);
  const points =
    (existingChampionnatPlayer?.points || 0) +
    (tournoi.status === 'finished'
      ? calculerPointsFromTournoiPlayer(
          formuleDeCalcul,
          tournoi,
          tournoiPlayer,
        ) ?? 0
      : 0);
  const { id, prizeInEuro, position, depense, nbKills, name, photo } =
    tournoiPlayer;
  const positions: number[] = [...(existingChampionnatPlayer?.positions ?? [])];
  if (position) {
    positions.push(position);
  }
  const isBubbleBoy = position === tournoi.prizePool.length + 1;
  return {
    id,
    nbTournoiPlayed,
    nbItm: (existingChampionnatPlayer?.nbItm || 0) + (prizeInEuro ? 1 : 0),
    nbBubble:
      (existingChampionnatPlayer?.nbBubble || 0) + (isBubbleBoy ? 1 : 0),
    nbVictoires:
      (existingChampionnatPlayer?.nbVictoires || 0) + (position === 1 ? 1 : 0),
    totalDepense:
      (existingChampionnatPlayer?.totalDepense || 0) +
      (tournoi.status === 'finished' ? depense : 0),
    totalGain: (existingChampionnatPlayer?.totalGain || 0) + (prizeInEuro || 0),
    totalKill: (existingChampionnatPlayer?.totalKill || 0) + nbKills,
    points,
    moyennePointParTournoi: formatNumber2Digits(
      nbTournoiPlayed > 0 ? points / nbTournoiPlayed : 0,
    ),
    name,
    photo,
    positions,
    positionMoy: formatNumber2Digits(
      nbTournoiPlayed > 0
        ? positions.reduce((acc, pos) => acc + pos, 0) / nbTournoiPlayed
        : 0,
    ),
  };
}
