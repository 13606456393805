import {
  QueryConstraint,
  query,
  collection,
  getDocs,
} from 'firebase/firestore';
import { firebase_db } from '../firebase.config';
import { useEffect, useState } from 'react';
import {
  BPCCollection,
  addIdOnDoc,
  transformCollectionForFirestore,
} from './firestore.helpers';

export const getDocsByQuery = async <T>(
  collectionName: BPCCollection,
  ...queryConstraints: QueryConstraint[]
) => {
  const collectionRef = collection(
    firebase_db,
    ...transformCollectionForFirestore(collectionName),
  );
  const q = query(collectionRef, ...queryConstraints);
  const querySnapshot = await getDocs(q);
  const data: T[] = [];
  querySnapshot.forEach((doc) => {
    data.push(addIdOnDoc(doc) as T);
  });
  return data;
};

export const useGetDocsByQuery = <T>(
  collectionName: BPCCollection,
  queryConstraints: QueryConstraint[],
  options?: Partial<{ skip: boolean }>,
) => {
  const { skip = false } = options ?? {};
  const [data, setData] = useState<T[]>();
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    async function getData() {
      setIsLoading(true);
      const dataFetched = await getDocsByQuery<T>(
        collectionName,
        ...queryConstraints,
      );
      setData(dataFetched);
      setIsLoading(false);
    }
    if (!skip) {
      getData();
    }
  }, [collectionName, queryConstraints, skip]);
  return { data, isLoading };
};
