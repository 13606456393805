import { Box, useBreakpointValue, useDisclosure } from '@chakra-ui/react';
import { RoutePath } from 'router/routes.paths';
import Loader from 'common/components/Loader';
import JoueursList, {
  JoueurForList,
} from 'features/players/components/JoueursList';
import { useGetTournoiList } from 'features/tournois/tournoi.api';
import { useMemo } from 'react';
import { useGetPlayersList } from 'features/players/players.api';
import BPCPageHeading from 'common/components/BPCPageHeading';
import NouveauPlayerModal from 'features/players/components/NouveauPlayerModal';
import { FaPlus } from 'react-icons/fa';

export default function JoueursListPage() {
  const { tournois, isLoading: isTournoisLoading } = useGetTournoiList();
  const { data: rawPlayers, isLoading: isPlayersLoading } = useGetPlayersList();
  const nouveauJoueurDisclosure = useDisclosure();

  const tournoisPlayers = useMemo(
    () =>
      tournois.reduce<JoueurForList[]>((acc, tournoi) => {
        tournoi.players.forEach((p) => {
          const index = acc.findIndex(({ id }) => id === p.id);
          if (index > -1) {
            acc[index] = {
              ...acc[index],
              nbTournoiPlayed:
                acc[index].nbTournoiPlayed +
                (tournoi.status === 'finished' ? 1 : 0),
              nbItm: p.prizeInPercent ? acc[index].nbItm + 1 : acc[index].nbItm,
              nbVictoires:
                p.position === 1
                  ? acc[index].nbVictoires + 1
                  : acc[index].nbVictoires,
              nbKills: acc[index].nbKills + (p.nbKills ?? 0),
            };
            return acc;
          } else {
            acc = [
              ...acc,
              {
                id: p.id,
                name: p.name,
                photo: p.photo,
                nbTournoiPlayed: tournoi.status === 'finished' ? 1 : 0,
                nbItm: p.prizeInPercent ? 1 : 0,
                nbVictoires: p.position === 1 ? 1 : 0,
                nbKills: p.nbKills ?? 0,
              },
            ];
          }
        });
        return acc;
      }, []),
    [tournois],
  );

  const players: JoueurForList[] = useMemo(
    () =>
      rawPlayers
        .map(
          (p) =>
            tournoisPlayers.find(({ id }) => p.id === id) ?? {
              id: p.id,
              name: p.name,
              photo: p.photo,
              nbTournoiPlayed: 0,
              nbItm: 0,
              nbVictoires: 0,
              nbKills: 0,
            },
        )
        .sort((a, b) => b.nbTournoiPlayed - a.nbTournoiPlayed),
    [rawPlayers, tournoisPlayers],
  );

  const creerJoueurTitle = useBreakpointValue(
    { base: '', md: 'Créer un joueur' },
    { fallback: 'md' },
  );

  if (isPlayersLoading || isTournoisLoading || !players) {
    return <Loader fullScreen />;
  }

  return (
    <Box>
      <BPCPageHeading
        helmetName="Joueurs"
        title="Liste des joueurs"
        breadcrumbItems={[{ to: RoutePath.home, name: 'Home' }]}
        buttonProps={[
          {
            leftIcon: <FaPlus />,
            title: creerJoueurTitle,
            onClick: nouveauJoueurDisclosure.onOpen,
          },
        ]}
      />
      <NouveauPlayerModal
        isOpen={nouveauJoueurDisclosure.isOpen}
        onClose={nouveauJoueurDisclosure.onClose}
        onSubmit={() => {
          window.location.reload();
        }}
      />

      <JoueursList players={players} />
    </Box>
  );
}
