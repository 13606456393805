import { useBreakpoint } from '@chakra-ui/react';
import { useCallback, useEffect, useMemo, useState } from 'react';

const DEFAULT_VUE_ENRICHIE_VALUE = 100;

export default function useVueEnrichie() {
  const currentBreakpoint = useBreakpoint();

  //Besoin d'un counter et d'un boolean pour faciliter le fait de remettre à 100 le compteur lorsque la souris bouge
  const [counterVueEnrichie, setCounterVueEnrichie] = useState(
    DEFAULT_VUE_ENRICHIE_VALUE,
  );
  const displayVueEnrichie = useMemo(
    () => counterVueEnrichie > 0,
    [counterVueEnrichie],
  );
  //Masque la vue enrichie au bout de 5s d'inactivité
  useEffect(() => {
    if (currentBreakpoint === 'base') {
      return;
    }
    if (counterVueEnrichie === DEFAULT_VUE_ENRICHIE_VALUE) {
      setCounterVueEnrichie(DEFAULT_VUE_ENRICHIE_VALUE - 1);
    }
    const timeoutVueEnrichie = setTimeout(() => {
      setCounterVueEnrichie(0);
    }, 5000);
    return () => clearInterval(timeoutVueEnrichie);
  }, [counterVueEnrichie, currentBreakpoint]);

  const resetVueEnrichie = useCallback(
    () => setCounterVueEnrichie(DEFAULT_VUE_ENRICHIE_VALUE),
    [],
  );

  return { displayVueEnrichie, resetVueEnrichie };
}
