import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  AvatarGroup,
  Box,
  Flex,
  Heading,
} from '@chakra-ui/react';
import { BPCLink } from 'common/components/BPCLink';
import BPCPageHeading from 'common/components/BPCPageHeading';
import Loader from 'common/components/Loader';
import { useGetChampionnatList } from 'features/championnats/championnat.api';
import TournoiPlayerAvatar from 'features/players/components/TournoiPlayerAvatar';
import TournoiList from 'features/tournois/components/TournoiList';
import { RoutePath } from 'router/routes.paths';

//TODO: gamification
export default function HomePage() {
  const {
    championnats,
    tournoisWithoutChampionnat,
    isLoading: isLoadingChampionnats,
  } = useGetChampionnatList();

  if (isLoadingChampionnats) return <Loader my={5} />;

  return (
    <Box>
      <BPCPageHeading helmetName="Home" />
      <Accordion defaultIndex={[0]} allowMultiple>
        {[
          ...championnats.sort((champA, champB) =>
            champA.name < champB.name ? 1 : -1,
          ),
          {
            id: 'lesSolos',
            name: 'Hors série',
            nbTournois: tournoisWithoutChampionnat.length,
            tournois: tournoisWithoutChampionnat,
            players: [],
          },
        ].map((championnat) => {
          return (
            <AccordionItem key={championnat.id}>
              <AccordionButton>
                {championnat.id === 'lesSolos' ? (
                  <Heading size="lg" mr={8}>
                    {championnat.name}
                  </Heading>
                ) : (
                  <Flex>
                    <BPCLink
                      to={`${RoutePath.championnats}/${championnat.id}`}
                      mr={3}
                    >
                      <Heading size="lg">{`${championnat.name} (${
                        championnat.nbTournois
                      } tournoi${
                        championnat.nbTournois > 1 ? 's' : ''
                      })`}</Heading>
                    </BPCLink>
                    <AvatarGroup size="sm" max={5} mx={4}>
                      {championnat.players.map((player) => (
                        <TournoiPlayerAvatar key={player.id} player={player} />
                      ))}
                    </AvatarGroup>
                  </Flex>
                )}
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel>
                <TournoiList tournois={championnat.tournois} />
              </AccordionPanel>
            </AccordionItem>
          );
        })}
      </Accordion>
    </Box>
  );
}
