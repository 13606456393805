import { useNavigate } from 'react-router-dom';
import {
  Flex,
  Avatar,
  Heading,
  Menu,
  HStack,
  Box,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  Text,
  Button,
  useDisclosure,
  Drawer,
  DrawerOverlay,
  DrawerBody,
  DrawerContent,
  DrawerCloseButton,
  VStack,
  DrawerHeader,
  DrawerFooter,
  StackDivider,
  FlexProps,
  StyleProps,
  SimpleGrid,
} from '@chakra-ui/react';
import { BPCLink } from 'common/components/BPCLink';
import { RoutePath } from 'router/routes.paths';
import BPCLogo from 'common/icons/BPCLogo';
import { FiChevronDown } from 'react-icons/fi';
import { signOut } from 'firebase/auth';
import { firebase_auth } from 'api/firebase.config';
import { BiMenu } from 'react-icons/bi';
import { FaHeart } from 'react-icons/fa';
import { Player } from 'features/players/players.types';

export const NAVBAR_HEIGHT = '4rem';

function BPCLogoWithName({
  onClick,
  ...restStyleProps
}: { onClick?: () => void } & StyleProps) {
  return (
    <BPCLink
      color="orange.500"
      to={RoutePath.home}
      display={'flex'}
      alignItems={'center'}
      _hover={{ textDecoration: 'none' }}
      onClick={onClick}
      {...restStyleProps}
    >
      <BPCLogo width={NAVBAR_HEIGHT} height={NAVBAR_HEIGHT} />
      <Heading>BPC</Heading>
    </BPCLink>
  );
}

export default function ConnectedLayoutHeader({
  user,
  ...restFlexProps
}: { user: Omit<Player, 'id'> & { id?: string } } & FlexProps) {
  const navigate = useNavigate();
  const { onOpen, onClose, isOpen } = useDisclosure();

  return (
    <>
      <SimpleGrid
        bg="white"
        px={5}
        columns={3}
        height={NAVBAR_HEIGHT}
        borderBottomColor={'gray.200'}
        borderBottomWidth={'1px'}
        {...restFlexProps}
      >
        <Flex alignItems={'center'} justifyContent={'flex-start'}>
          {user.id && (
            <Button onClick={onOpen} variant="ghost" p={0}>
              <BiMenu size={'2em'} />
            </Button>
          )}
        </Flex>

        <Flex alignItems={'center'} justifyContent={'center'}>
          <BPCLogoWithName />
        </Flex>

        <Flex alignItems={'center'} justifyContent={'flex-end'}>
          <Menu>
            <MenuButton
              py={2}
              transition="all 0.3s"
              _focus={{ boxShadow: 'none' }}
            >
              <HStack>
                <Avatar size={'md'} name={user.name} src={user.photo} />
                <VStack
                  display={{ base: 'none', md: 'flex' }}
                  alignItems="flex-start"
                  spacing="1px"
                  ml="2"
                >
                  <Text fontSize="sm">{user.name}</Text>
                  <Text fontSize="xs" color="gray.600">
                    {user.role === 'admin' ? 'Admin' : 'Joueur'}
                  </Text>
                </VStack>
                <Box display={{ base: 'none', md: 'flex' }}>
                  <FiChevronDown />
                </Box>
              </HStack>
            </MenuButton>
            <MenuList bg="white" borderColor="gray.200">
              {user.id && (
                <>
                  <MenuItem onClick={() => navigate(RoutePath.editProfile)}>
                    Profil
                  </MenuItem>
                  <MenuDivider />
                </>
              )}
              <MenuItem onClick={() => signOut(firebase_auth)} color="red.500">
                Se déconnecter
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </SimpleGrid>
      <Drawer placement="left" onClose={onClose} isOpen={isOpen}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader display={'flex'}>
            <BPCLogoWithName onClick={onClose} />
          </DrawerHeader>
          <DrawerBody>
            <VStack
              divider={<StackDivider />}
              alignItems="flex-start"
              spacing={4}
            >
              <BPCLink onClick={onClose} to={RoutePath.championnats}>
                Championnats
              </BPCLink>
              <BPCLink onClick={onClose} to={RoutePath.joueurs}>
                Joueurs
              </BPCLink>
              <BPCLink onClick={onClose} to={RoutePath.outils}>
                Outils
              </BPCLink>
            </VStack>
          </DrawerBody>
          <DrawerFooter fontSize={'xs'} alignItems={'center'}>
            <Text>Fait avec</Text>
            <Box mx={1}>
              <FaHeart color="red" />
            </Box>
            <Text>par Jéjé</Text>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
}
