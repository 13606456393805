import { Center, Spinner, StyleProps } from '@chakra-ui/react';

export default function Loader({
  fullScreen,
  ...restStyleProps
}: { fullScreen?: boolean } & StyleProps) {
  return (
    <Center height={fullScreen ? '100vh' : undefined} {...restStyleProps}>
      <Spinner
        thickness="4px"
        speed="0.65s"
        emptyColor="gray.200"
        color="orange.500"
        size="xl"
      />
    </Center>
  );
}
