export const CHAMPIONNAT_FORMULES_DEFAULT: { nom: string; formule?: string }[] =
  [
    {
      nom: 'BPC',
      formule: 'sqrt(c*b*b/d)/(p+1) + k/10',
    },
    {
      nom: 'Dr. Neau',
      formule: 'sqrt(n*b*b/d)/(p+1)',
    },
    // {
    //   nom: 'Blinds Up',
    //   formule: '5 + 3*sqrt(n-p)*log(n/p)',
    // },
    {
      nom: 'Winamax',
      formule: '100*sqrt(n/(p^exp(10/n)))*(2.2^(log10(b*d+0.01)))',
    },
    { nom: 'Custom' },
  ];
