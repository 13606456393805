import { Box, useBreakpointValue } from '@chakra-ui/react';
import { useGetTournoiLiveById } from 'features/tournois/tournoi.api';
import Loader from 'common/components/Loader';
import { useNavigate, useParams } from 'react-router-dom';
import { NotFound } from './ErrorPage';
import { RoutePath } from 'router/routes.paths';
import { TournoiDetail } from 'features/tournois/components/TournoiDetail';
import {
  generateTournoiToDuplicate,
  getTournoiName,
} from 'features/tournois/tournoi.helper';
import BPCPageHeading from 'common/components/BPCPageHeading';
import { FaCopy, FaEdit } from 'react-icons/fa';
import { MdLiveTv } from 'react-icons/md';
import { mapTournoiLiveToTournoi } from 'features/tournois/live/tournoi.live.mappers';
import { useSons } from 'common/sons.helper';
import { useConnectedUserContext } from 'features/user/useConnectedUserContext';

export default function TournoiDetailPage() {
  const { id } = useParams<{ id: string }>();
  const { tournoiLive: tournoi, isLoading } = useGetTournoiLiveById(id!);
  const navigate = useNavigate();
  const { joueSon } = useSons();
  const { user } = useConnectedUserContext();

  const liveTitle = useBreakpointValue(
    { base: '', md: 'Accéder au live' },
    { fallback: 'md' },
  );
  const modifierTitle = useBreakpointValue(
    { base: '', md: 'Modifier' },
    { fallback: 'md' },
  );
  const dupliquerTitle = useBreakpointValue(
    { base: '', md: 'Dupliquer' },
    { fallback: 'md' },
  );

  if (isLoading) {
    return <Loader fullScreen />;
  }

  if (!tournoi) {
    return <NotFound />;
  }
  const { name, date } = tournoi;

  return (
    <Box>
      <BPCPageHeading
        helmetName={getTournoiName(tournoi, 'short')}
        title={getTournoiName({ date, name }, 'with-date')}
        breadcrumbItems={[
          { to: RoutePath.home, name: 'Home' },
          { to: RoutePath.tournois, name: 'Tournois' },
        ]}
        //@ts-expect-error (je passe par un état undefined que je filtre)
        buttonProps={[
          tournoi.status !== 'finished'
            ? {
                leftIcon: <MdLiveTv />,
                title: liveTitle,
                onClick: async () => {
                  if (user?.role === 'admin') {
                    await joueSon('porte', 0.1, false);
                  }
                  navigate(`${RoutePath.tournois}/${tournoi.id}/live`);
                },
                variant: 'outline',
              }
            : undefined,
          {
            leftIcon: <FaEdit />,
            title: modifierTitle,
            onClick: () => navigate(`${RoutePath.tournois}/${id}/edition`),
          },
          {
            leftIcon: <FaCopy />,
            title: dupliquerTitle,
            onClick: () =>
              navigate(RoutePath.tournoiNew, {
                state: {
                  tournoi: generateTournoiToDuplicate(
                    mapTournoiLiveToTournoi(tournoi),
                  ),
                },
              }),
          },
        ].filter((v) => v !== undefined)}
      />

      <TournoiDetail tournoi={tournoi} />
    </Box>
  );
}
