import {
  mapTournoiPlayerRealtimeToTournoiPlayer,
  mapTournoiPlayerToTournoiPlayerRealtime,
} from 'features/players/players.mappers';
import { Tournoi, TournoiRealtime } from './tournoi.types';
import { Player } from 'features/players/players.types';
import dayjs, { Dayjs } from 'dayjs';
import { deleteUndefinedKeys } from 'common/common.helper';

export function mapTournoiToTournoiRealTime({
  date,
  players,
  startDate,
  lastRestartDate,
  ...restTournoi
}: Omit<Tournoi, 'id' | 'type'> & { id?: string }) {
  const mappedTournoi: Omit<TournoiRealtime, 'id'> & {
    id?: string;
  } = {
    ...restTournoi,
    date: date.toISOString(),
    startDate: startDate?.toISOString(),
    lastRestartDate: lastRestartDate?.toISOString(),
    players: players.map(mapTournoiPlayerToTournoiPlayerRealtime),
  };
  deleteUndefinedKeys(mappedTournoi);
  return mappedTournoi;
}

function computeTournoiEndDate(startDate: Dayjs, lastKillHourMinute: string) {
  const [lastKillHour, lastKillMinute] = lastKillHourMinute.split(':');
  const endDateSameDay = startDate
    .set('hour', +lastKillHour)
    .set('minute', +lastKillMinute);

  if (endDateSameDay.isAfter(startDate)) {
    return endDateSameDay;
  }

  return endDateSameDay.add(1, 'day');
}

export function mapTournoiRealTimeToTournoi(
  tournoi: TournoiRealtime,
  playerList: Player[],
): Tournoi {
  const {
    date: dateRealTime,
    startDate: startDateRealTime,
    lastRestartDate: lastRestartDateRealTime,
    players,
    structure,
    prizePool,
    killsHistory,
    championnatIds,
    status,
    ...restTournoi
  } = tournoi;
  const date = dayjs(dateRealTime).toDate();
  const startDate = dayjs(startDateRealTime);
  const lastKillHourMinute = killsHistory?.[killsHistory.length - 1]?.heure;
  const endDate =
    lastKillHourMinute && status === 'finished'
      ? computeTournoiEndDate(startDate, lastKillHourMinute)
      : undefined;

  const lastRestartDate = dayjs(lastRestartDateRealTime).toDate();
  return {
    status,
    date,
    startDate: startDate.toDate(),
    endDate: endDate?.toDate(),
    dureeInMinute: endDate ? endDate.diff(startDate, 'minute') : undefined,
    lastRestartDate,
    players: (players || [])
      .map((tournoiPlayer) =>
        mapTournoiPlayerRealtimeToTournoiPlayer(
          tournoiPlayer,
          playerList,
          tournoi,
        ),
      )
      .sort((a, b) => (a.position ?? -1) - (b.position ?? -1)),
    structure: structure ?? [],
    prizePool: prizePool ?? [],
    killsHistory: killsHistory ?? [],
    championnatIds: championnatIds ?? [],
    type: 'live',
    ...restTournoi,
  };
}
