import { TournoiLive } from '../live/tournoi.live.types';
import { Button, useDisclosure } from '@chakra-ui/react';
import AjouterKillModal from './AjouterKillModal';
import { useState } from 'react';
import dayjs from 'dayjs';

export default function AjouterKillButtonLive({
  tournoi,
  withHourSelect,
}: Readonly<{
  tournoi: TournoiLive;
  withHourSelect?: boolean;
}>) {
  const { onOpen, onClose, isOpen } = useDisclosure();
  const [modaleKey, setModaleKey] = useState<string>();

  return (
    <>
      <Button
        isDisabled={
          tournoi.status === 'finished' || tournoi.status === 'notStarted'
        }
        onClick={() => {
          setModaleKey(dayjs().format('DD:MM:ss'));
          onOpen();
        }}
      >
        Ajouter un kill
      </Button>
      <AjouterKillModal
        key={modaleKey}
        isOpen={isOpen}
        onClose={onClose}
        tournoi={tournoi}
        withHourSelect={withHourSelect}
      />
    </>
  );
}
