import { Button, useDisclosure } from '@chakra-ui/react';
import NouveauPlayerModal from './NouveauPlayerModal';
import { Player } from '../players.types';

export default function CreerJoueurButton({
  onSubmit,
  playerToComplete,
  allowMultipleCreation,
}: {
  onSubmit: (newPlayer: Player) => void;
  playerToComplete?: Omit<Player, 'id'>;
  allowMultipleCreation?: boolean;
}) {
  const nouveauJoueurDisclosure = useDisclosure();
  return (
    <>
      <Button onClick={nouveauJoueurDisclosure.onOpen}>Créer un joueur</Button>
      <NouveauPlayerModal
        isOpen={nouveauJoueurDisclosure.isOpen}
        onClose={nouveauJoueurDisclosure.onClose}
        onSubmit={onSubmit}
        playerToComplete={playerToComplete}
        allowMultipleCreation={allowMultipleCreation}
      />
    </>
  );
}
