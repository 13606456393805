import { Text, Flex, StyleProps } from '@chakra-ui/react';
import { FC, useMemo } from 'react';

import { Tournoi } from '../tournoi.types';
import BPCTable from 'common/components/BPCTable';
import TournoiPlayerAvatar from 'features/players/components/TournoiPlayerAvatar';
import { formatMoney } from 'common/string.helper';
import { HistoryType, getHistoryIconForType } from '../history.helper';

export const TournoiHistoryTable: FC<{ tournoi: Tournoi } & StyleProps> = ({
  tournoi,
  ...restStyleProps
}) => {
  const historyColumns = [
    { property: 'heure' as const, name: '', type: 'custom' as const },
    { property: 'type' as const, name: '', type: 'custom' as const },
    {
      property: 'message' as const,
      name: '',
      type: 'custom' as const,
    },
  ];

  const historyData = useMemo(
    () =>
      [...(tournoi.killsHistory || [])]
        .reverse()
        .map(({ heure, killerId, victimeId, isRecave }, i) => {
          const killer = tournoi.players.find(({ id }) => id === killerId)!;
          const victime = tournoi.players.find(({ id }) => id === victimeId);

          const type: HistoryType = isRecave
            ? victime
              ? 'recave'
              : 'error'
            : victime
            ? 'kill'
            : 'win';

          return {
            heure,
            type: getHistoryIconForType(type),
            message:
              type === 'recave' ? (
                <Flex gap={1} alignItems={'center'}>
                  <TournoiPlayerAvatar
                    player={victime!}
                    withPosition={false}
                    namePosition="right"
                  />
                  <Text>a recavé</Text>
                  <Text>(la faute à {killer.name})</Text>
                </Flex>
              ) : type === 'kill' ? (
                <Flex gap={1} alignItems={'center'}>
                  <TournoiPlayerAvatar
                    player={victime!}
                    withPosition={false}
                    namePosition="right"
                  />
                  <Text>
                    a été éliminé(e) en {victime!.position}ème position par
                  </Text>
                  <Text>{killer.name}</Text>
                  {victime!.prizeInEuro && (
                    <Text>et gagne {formatMoney(victime!.prizeInEuro)}</Text>
                  )}
                </Flex>
              ) : type === 'win' ? (
                <Flex gap={1} alignItems={'center'}>
                  <TournoiPlayerAvatar
                    player={killer}
                    withPosition={false}
                    namePosition="right"
                  />
                  <Text>a gagné le tournoi</Text>
                  {killer.prizeInEuro && (
                    <Text>
                      et repart avec {formatMoney(killer.prizeInEuro)}
                    </Text>
                  )}
                </Flex>
              ) : (
                "Qu'est-ce qui s'est passé?"
              ),
          };
        }),
    [tournoi],
  );

  return tournoi.killsHistory?.length ? (
    <BPCTable
      size="sm"
      data={historyData}
      columns={historyColumns}
      {...restStyleProps}
    />
  ) : (
    <Text mt={3}>Pas d'historique pour le moment</Text>
  );
};
