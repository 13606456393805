import { VStack, Container, useToast } from '@chakra-ui/react';
import Loader from 'common/components/Loader';
import useFirebaseAuthentication from 'features/auth/useFirebaseAuthentication';
import {
  updatePlayer,
  useGetPlayerByUserId,
} from 'features/players/players.api';
import { Player } from 'features/players/players.types';
import EditProfileForm from 'features/user/components/EditProfileForm';
import { useState } from 'react';
import { Helmet } from 'react-helmet';

export default function EditProfilePage() {
  const { firebaseAuthUser } = useFirebaseAuthentication();
  const { player: user, isLoading: isUserLoading } = useGetPlayerByUserId(
    firebaseAuthUser?.uid,
  );
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const toast = useToast();

  if (isUserLoading || !user) {
    return <Loader fullScreen />;
  }

  function handleSubmit(playerUpdated: Omit<Player, 'id'>) {
    setErrorMessage(undefined);
    setIsLoading(true);
    updatePlayer({ id: user!.id, ...playerUpdated })
      .then(() => {
        toast({
          title: 'Succès',
          description: 'Mise à jour du profil réussie',
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
      })
      .finally(() => setIsLoading(false));
  }

  return (
    <Container maxW="md">
      <Helmet>
        <title>BPC - edition du profil</title>
      </Helmet>
      <VStack>
        <EditProfileForm
          user={user}
          onSubmit={handleSubmit}
          isLoading={isLoading}
          errorMessage={errorMessage}
        />
      </VStack>
    </Container>
  );
}
