import { useMemo } from 'react';
import { PokerRange, RangeType } from '../ranges.type';
import {
  Box,
  Flex,
  Text,
  VStack,
  FormLabel,
  Heading,
  Stack,
} from '@chakra-ui/react';
import { rangeTypeColors, rangeTypeNames } from '../ranges.constants';
import {
  computeRangePercentageForNbCombos,
  computeTotalCombosForRange,
} from '../ranges.helper';
import BPCHandMatrix from './BPCHandMatrix/BPCHandMatrix';

export default function RangeDetail({
  range,
}: Readonly<{
  range: PokerRange;
}>) {
  const nbCombosPerType = useMemo(
    () => computeTotalCombosForRange(range.details),
    [range.details],
  );

  const rangeTypesToDisplay = useMemo(
    () =>
      Object.keys(nbCombosPerType).filter(
        (type) => type !== 'total',
      ) as RangeType[],
    [nbCombosPerType],
  );
  return (
    <VStack spacing={3} alignItems={'flex-start'} width={'100%'}>
      {range.name && <Heading size={'md'}>{range.name}</Heading>}

      {/* Details */}

      <FormLabel>Détails</FormLabel>

      <Text>
        Total : {computeRangePercentageForNbCombos(nbCombosPerType.total)}
      </Text>
      <Stack
        width="100%"
        direction={{ base: 'column-reverse', md: 'row' }}
        spacing={3}
      >
        <BPCHandMatrix rangeDetails={range.details} style={{ width: '100%' }} />
        <VStack alignItems={'flex-start'} spacing={2}>
          {rangeTypesToDisplay.map((type) => {
            const rangePercentage = computeRangePercentageForNbCombos(
              nbCombosPerType[type],
            );
            return (
              <Box key={type}>
                <Flex alignItems={'center'}>
                  <Box bg={rangeTypeColors[type]} h="1rem" w="1rem" mr={2} />
                  <Text>{`${rangeTypeNames[type]}${
                    rangePercentage ? ` (${rangePercentage})` : ''
                  }`}</Text>
                </Flex>
              </Box>
            );
          })}
        </VStack>
      </Stack>

      {range.note && <Text>{range.note}</Text>}
    </VStack>
  );
}
