import { Tournoi, TournoiPlayer } from 'features/tournois/tournoi.types';
import { parse } from 'mathjs';
import { formatNumber2Digits } from 'common/string.helper';

export function calculerPoints(
  formuleDeCalcul: string | undefined,
  variables: {
    n: number;
    c: number;
    b: number;
    d: number;
    p: number;
    r: number;
    k: number;
  },
) {
  if (!formuleDeCalcul) {
    return undefined;
  }
  try {
    return formatNumber2Digits(
      Math.round(parse(formuleDeCalcul).evaluate(variables) * 100) / 100,
    );
  } catch (_) {
    return undefined;
  }
}

export function calculerPointsFromTournoiPlayer(
  formuleDeCalcul: string | undefined,
  { players, montantBuyIn }: Pick<Tournoi, 'players' | 'montantBuyIn'>,
  { depense, position, nbRecave, nbKills }: TournoiPlayer,
) {
  const n = players.length;
  const c = n + players.reduce((acc, { nbRecave }) => acc + nbRecave, 0);

  return position
    ? calculerPoints(formuleDeCalcul, {
        n,
        c,
        b: montantBuyIn,
        d: depense,
        p: position,
        r: nbRecave,
        k: nbKills,
      })
    : undefined;
}
