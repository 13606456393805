import {
  StyleProps,
  Box,
  StackDivider,
  Stack,
  Card,
  CardBody,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
} from '@chakra-ui/react';
import { FC, useMemo } from 'react';

import { Championnat } from 'features/championnats/championnat.types';
import { formatMoney } from 'common/string.helper';
import BPCCardRow from 'common/components/BPCCardRow';
import TournoiPlayerAvatar from 'features/players/components/TournoiPlayerAvatar';
import TournoiList from 'features/tournois/components/TournoiList';
import BPCTable from 'common/components/BPCTable';
import { BPCLink } from 'common/components/BPCLink';
import { RoutePath } from 'router/routes.paths';
import JoueursPointsGraph from 'features/tournois/components/JoueursPointsGraph';

export const ChampionnatDetail: FC<
  { championnat: Championnat } & StyleProps
> = ({ championnat, ...restStyleProps }) => {
  const {
    methodeCalculPoints,
    isActive,
    nbTournois,
    players,
    totalPrizePool,
    tournois,
    id,
  } = championnat;

  const playersTableColumns = [
    {
      property: 'avatar' as const,
      sortingProperty: 'position' as const,
      defaultSortingAsc: false,
      name: '',
      type: 'custom' as const,
    },
    {
      property: 'name' as const,
      name: 'Nom',
      type: 'text' as const,
    },
    {
      property: 'points' as const,
      name: 'Points',
      type: 'nombre' as const,
    },
    {
      property: 'moyennePointParTournoi' as const,
      name: 'Points/Tournoi',
      type: 'nombre' as const,
    },
    {
      property: 'nbTournoiPlayed' as const,
      name: 'Tournois',
      type: 'nombre' as const,
    },
    { property: 'nbItm' as const, name: 'ITM', type: 'nombre' as const },
    {
      property: 'nbVictoires' as const,
      name: 'Victoires',
      type: 'nombre' as const,
    },
    {
      property: 'totalKill' as const,
      name: 'Kills',
      type: 'nombre' as const,
    },
    {
      property: 'moyenneKillParTournoi' as const,
      name: 'Kills/Tournoi',
      type: 'nombre' as const,
    },
    {
      property: 'nbBubble' as const,
      name: 'Bulles',
      type: 'nombre' as const,
    },
    {
      property: 'totalDepense' as const,
      name: 'Dépenses',
      type: 'money' as const,
    },
    {
      property: 'totalGain' as const,
      name: 'Gains',
      type: 'money' as const,
    },
    {
      property: 'benef' as const,
      name: 'Bénéfices',
      type: 'money' as const,
    },
    {
      property: 'benefParTournoi' as const,
      name: 'Bénéfices/Tournoi',
      type: 'money' as const,
    },
    {
      property: 'winRate' as const,
      name: 'Win rate',
      type: 'percent' as const,
    },
    {
      property: 'tauxVictoire' as const,
      name: 'Taux de victoire',
      type: 'percent' as const,
    },
    {
      property: 'tauxItm' as const,
      name: "Taux d'ITM",
      type: 'percent' as const,
    },
    {
      property: 'tauxBulle' as const,
      name: 'Taux de bulle',
      type: 'percent' as const,
    },
    {
      property: 'positionMoy' as const,
      name: 'Position moyenne',
      type: 'nombre' as const,
    },
    //Important de mettre les hidden en dernier pour ne pas interférer avec le stickyFirstcol
    { property: 'position' as const, name: '', type: 'hidden' as const },
  ];

  const playersTableData = useMemo(
    () =>
      players.map(
        (
          {
            name,
            photo,
            nbTournoiPlayed,
            nbItm,
            nbBubble,
            nbVictoires,
            totalDepense,
            totalGain,
            totalKill,
            points,
            moyennePointParTournoi,
            positionMoy,
            id: playerId,
          },
          i,
        ) => ({
          position: i + 1,
          avatar: (
            <BPCLink to={`${RoutePath.championnats}/${id}/joueurs/${playerId}`}>
              <TournoiPlayerAvatar player={{ name, photo, position: i + 1 }} />
            </BPCLink>
          ),
          name: (
            <BPCLink to={`${RoutePath.championnats}/${id}/joueurs/${playerId}`}>
              {name}
            </BPCLink>
          ),
          nbTournoiPlayed,
          points,
          moyennePointParTournoi,
          moyenneKillParTournoi: nbTournoiPlayed
            ? totalKill / nbTournoiPlayed
            : undefined,
          nbItm,
          nbBubble,
          nbVictoires,
          totalDepense,
          totalGain,
          benef: totalGain - totalDepense,
          benefParTournoi: nbTournoiPlayed
            ? (totalGain - totalDepense) / nbTournoiPlayed
            : undefined,
          positionMoy,
          winRate: (totalGain - totalDepense) / totalDepense,
          tauxVictoire: nbTournoiPlayed ? nbVictoires / nbTournoiPlayed : 0,
          tauxItm: nbTournoiPlayed ? nbItm / nbTournoiPlayed : 0,
          tauxBulle: nbTournoiPlayed ? nbBubble / nbTournoiPlayed : 0,
          totalKill,
        }),
      ),
    [id, players],
  );

  return (
    <Box {...restStyleProps}>
      <Tabs colorScheme="orange">
        <TabList>
          <Tab>Résultats</Tab>
          <Tab>Graph</Tab>
          <Tab>Tournois</Tab>
          <Tab>Infos</Tab>
        </TabList>

        <TabPanels>
          {/* Resultats */}
          <TabPanel>
            <BPCTable
              stickyFirstCol
              stickyHeader
              data={playersTableData}
              columns={playersTableColumns}
              defaultSortBy={{ property: 'position', asc: true }}
            />
          </TabPanel>

          {/* Graph */}
          <TabPanel>
            <JoueursPointsGraph
              players={players}
              tournois={tournois}
              methodeCalculPoints={championnat.methodeCalculPoints}
            />
          </TabPanel>

          {/* Tournois */}
          <TabPanel>
            <TournoiList tournois={tournois} />
          </TabPanel>

          {/* Infos */}
          <TabPanel>
            <Card>
              <CardBody>
                <Stack divider={<StackDivider />} spacing="4">
                  <BPCCardRow titre="Prize pool total">
                    {formatMoney(totalPrizePool)}
                  </BPCCardRow>
                  <BPCCardRow titre="Nombre de tournois">
                    {nbTournois}
                  </BPCCardRow>
                  <BPCCardRow titre="Formule de calcul des points">
                    {methodeCalculPoints}
                  </BPCCardRow>
                  <BPCCardRow titre="Statut">
                    {isActive ? 'En cours' : 'Fermé'}
                  </BPCCardRow>
                </Stack>
              </CardBody>
            </Card>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};
