import { FieldArray, Formik, useFormikContext } from 'formik';
import {
  VStack,
  Alert,
  AlertIcon,
  AlertDescription,
  SimpleGrid,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  RadioGroup,
  Radio,
  Box,
  Text,
  FormControl,
  FormLabel,
  Flex,
  Input,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  HStack,
} from '@chakra-ui/react';
import { useEffect, useMemo, useState } from 'react';
import { BPCYup } from 'common/form.validation';
import { BPCInput } from 'common/components/form/BPCInput';
import { parse } from 'mathjs';
import { CHAMPIONNAT_FORMULES_DEFAULT } from '../championnat.constants';
import { formatMoney } from 'common/string.helper';
import { calculerPoints } from '../championnat.helper';

type ChampionnatFormuleFormProps = {
  methodeCalculPoints: string;
  onSubmit: (newFormule: string) => void;
};

const FormuleSchema = BPCYup.object().shape({
  formule: BPCYup.string().required(),
  n: BPCYup.number()
    .required()
    .min(1, 'Le nombre de joueur ne peut pas être inférieur à 1'),
  b: BPCYup.number()
    .required()
    .min(1, 'Le buy-in ne peut pas être inférieur à 1€'),
  players: BPCYup.array()
    .of(
      BPCYup.object().shape({
        r: BPCYup.number()
          .required()
          .min(0, 'Le nombre de recaves ne peut pas être inférieur à 0'),

        k: BPCYup.number()
          .required()
          .min(0, 'Le nombre de kill ne peut pas être inférieur à 1'),
      }),
    )
    .required(),
});

type FormuleFormType = BPCYup.InferType<typeof FormuleSchema>;

const DEFAULT_NUMBER_OF_PLAYERS = 6;
const DEFAULT_PLAYER = {
  r: 0, //nombre de recaves
  k: 0, //nombre de kill
};

const initialValues = {
  formule: '',
  n: DEFAULT_NUMBER_OF_PLAYERS, //nombre de joueurs
  b: 10, //buyin
  players: new Array(DEFAULT_NUMBER_OF_PLAYERS).fill(DEFAULT_PLAYER),
};

export default function ChampionnatFormuleForm({
  methodeCalculPoints,
  onSubmit,
}: ChampionnatFormuleFormProps) {
  const [errorMessage, setErrorMessage] = useState<string>();

  function handleOnSubmit({ formule }: FormuleFormType) {
    setErrorMessage(undefined);
    try {
      parse(formule);
      return onSubmit(formule);
    } catch (e: any) {
      setErrorMessage(e.message);
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={FormuleSchema}
      onSubmit={handleOnSubmit}
    >
      <ChampionnatFormuleFormInner
        methodeCalculPoints={methodeCalculPoints}
        errorMessage={errorMessage}
      />
    </Formik>
  );
}
function ChampionnatFormuleFormInner({
  methodeCalculPoints,
  errorMessage,
}: Omit<ChampionnatFormuleFormProps, 'onSubmit'> & { errorMessage?: string }) {
  const [selectedFormuleIndex, setSelectedFormuleIndex] = useState(
    CHAMPIONNAT_FORMULES_DEFAULT.findIndex(
      ({ formule }) => formule === methodeCalculPoints,
    ) > -1
      ? CHAMPIONNAT_FORMULES_DEFAULT.findIndex(
          ({ formule }) => formule === methodeCalculPoints,
        )
      : CHAMPIONNAT_FORMULES_DEFAULT.length - 1,
  );
  const {
    values: { formule, n, b, players },
    setFieldValue,
    submitForm,
  } = useFormikContext<FormuleFormType>();

  useEffect(() => {
    if (n < 1) {
      return;
    }
    if (n > players.length) {
      setFieldValue('players', [...players, DEFAULT_PLAYER]);
    }
    if (n < players.length) {
      setFieldValue('players', players.slice(0, -1)); // suppression du dernier élément
    }
  }, [n, players, setFieldValue]);

  useEffect(() => {
    if (formule) {
      submitForm();
    }
  }, [formule, submitForm]);

  useEffect(() => {
    setFieldValue('formule', methodeCalculPoints);
  }, [methodeCalculPoints, setFieldValue]);

  const c = useMemo(
    () => n + players.reduce((acc, { r }) => acc + r, 0),
    [n, players],
  );

  return (
    <VStack spacing={4} align="strech">
      {errorMessage ? (
        <Alert status="error">
          <AlertIcon />
          <AlertDescription>{errorMessage}</AlertDescription>
        </Alert>
      ) : undefined}
      <RadioGroup
        value={`${selectedFormuleIndex}`}
        colorScheme="orange"
        onChange={(e) => {
          const target = CHAMPIONNAT_FORMULES_DEFAULT[+e];
          setSelectedFormuleIndex(+e);
          if (target.formule) {
            setFieldValue('formule', target.formule);
          }
        }}
      >
        <SimpleGrid columns={2} spacing={2}>
          {CHAMPIONNAT_FORMULES_DEFAULT.map(({ nom }, i) => (
            <Radio key={i} value={`${i}`}>
              {nom}
            </Radio>
          ))}
        </SimpleGrid>
      </RadioGroup>
      <BPCInput
        fieldName="formule"
        label="Formule de calcul des points"
        type="text"
        isDisabled={
          selectedFormuleIndex !== CHAMPIONNAT_FORMULES_DEFAULT.length - 1
        }
      />
      <HStack mb={6} alignItems={'flex-start'} spacing={5}>
        <Box>
          <Text fontSize="xs" textTransform={'uppercase'}>
            Tournoi
          </Text>
          <Text fontSize="xs">n = nombre de joueurs du tournoi</Text>
          <Text fontSize="xs">b = montant du buy-in du tournoi</Text>
          <Text fontSize="xs">c = nombre de caves du tournoi</Text>
        </Box>
        <Box>
          <Text fontSize="xs" textTransform={'uppercase'}>
            Joueur
          </Text>
          <Text fontSize="xs">p = position du joueur</Text>
          <Text fontSize="xs">r = nombre de recaves du joueur</Text>
          <Text fontSize="xs">k = nombre de kill du joueur</Text>
          <Text fontSize="xs">
            d = dépense totale du joueur (buy-in + recaves)
          </Text>
        </Box>
      </HStack>
      <Accordion>
        <AccordionItem>
          <AccordionButton>
            <Box as="span" flex="1" textAlign="left">
              Tester la formule
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel>
            <Text fontSize="xs" textTransform={'uppercase'}>
              Tournoi
            </Text>
            <SimpleGrid columns={{ base: 1, md: 3 }} spacing={4} mb={6}>
              <BPCInput fieldName="n" label="n" type="number" oneLiner />
              <BPCInput
                fieldName="b"
                label="b"
                type="number"
                textAlign={'right'}
                suffix="€"
                oneLiner
              />
              <FormControl>
                <Flex direction={'row'} alignItems={'flex-start'}>
                  <FormLabel pt={2}>c</FormLabel>
                  <Input type="number" isDisabled={true} value={c} />
                </Flex>
              </FormControl>
            </SimpleGrid>
            <Text fontSize="xs" textTransform={'uppercase'}>
              Joueur
            </Text>
            <FieldArray
              name="players"
              render={() => (
                <TableContainer>
                  <Table variant="simple" size="md">
                    <Thead>
                      <Tr>
                        <Th textTransform={'lowercase'}>p</Th>
                        <Th textTransform={'lowercase'}>r</Th>
                        <Th textTransform={'lowercase'}>k</Th>
                        <Th textTransform={'lowercase'}>x</Th>
                        <Th>Points</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {players.map((_, i) => {
                        const d = b * (1 + players[i].r);
                        const mathVariables = {
                          n,
                          c,
                          b,
                          d,
                          p: i + 1,
                          r: players[i].r,
                          k: players[i].k,
                        };
                        return (
                          <Tr key={i}>
                            <Td>{i + 1}</Td>
                            <Td>
                              <BPCInput
                                fieldName={`players.${i}.r`}
                                type="number"
                              />
                            </Td>
                            <Td>
                              <BPCInput
                                fieldName={`players.${i}.k`}
                                type="number"
                              />
                            </Td>
                            <Td>{formatMoney(d)}</Td>
                            <Td>
                              {calculerPoints(
                                methodeCalculPoints,
                                mathVariables,
                              ) ?? '-'}
                            </Td>
                          </Tr>
                        );
                      })}
                    </Tbody>
                  </Table>
                </TableContainer>
              )}
            />
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </VStack>
  );
}
