import { Avatar, StyleProps, useToast } from '@chakra-ui/react';
import { FC, useMemo } from 'react';

import { Championnat } from 'features/championnats/championnat.types';
import { RoutePath } from 'router/routes.paths';
import { BPCCard } from 'common/components/BPCCard';
import { useNavigate } from 'react-router-dom';
import { closeOrOpenChampionnat } from '../championnat.api';

export const ChampionnatCard: FC<{ championnat: Championnat } & StyleProps> = ({
  championnat,
  ...restStyleProps
}) => {
  const { id, name, nbTournois, players } = championnat;
  const navigate = useNavigate();
  const toast = useToast();

  const winnerPlayer = useMemo(() => {
    if (players.length >= 2 && players[0].points > players[1].points) {
      return players[0];
    }
    return undefined;
  }, [players]);

  return (
    <BPCCard
      linkTo={`${RoutePath.championnats}/${id}`}
      title={name}
      subTitles={[`${nbTournois} tournoi${nbTournois > 1 ? 's' : ''}`]}
      avatarProps={{
        name: name,
      }}
      avatarBadgeProps={
        winnerPlayer
          ? {
              children: (
                <Avatar
                  size="sm"
                  name={winnerPlayer.name}
                  src={winnerPlayer.photo}
                />
              ),
            }
          : undefined
      }
      menuItems={[
        {
          name: 'Modifier',
          onClick: () => navigate(`${RoutePath.championnats}/${id}/edition`),
        },
        {
          name: championnat.isActive
            ? 'Clore le championnat'
            : 'Rouvrir le championnat',
          color: championnat.isActive ? 'red' : 'green',
          onClick: async () => {
            try {
              await closeOrOpenChampionnat(championnat.id);
              navigate(`${RoutePath.championnats}/${championnat.id}`);
            } catch (e: any) {
              const { code, message } = e;

              toast({
                title: 'Erreur',
                description: `Erreur lors de l'édition du championnat:${code} message:${message}`,
                status: 'error',
                duration: 9000,
                isClosable: true,
              });
            }
          },
        },
      ]}
      {...restStyleProps}
    />
  );
};
