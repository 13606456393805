import { useMemo } from 'react';
import { useGetPlayersList } from 'features/players/players.api';
import { TOURNOI_IMPORTED_COLLECTION_NAME } from 'api/collectionNames.constants';
import { TournoiImportedFirebase } from './tournoi.imported.types';
import { useGetCollection } from 'api/firestore/getCollection';
import { createDoc } from 'api/firestore/createDoc';
import { updateDoc } from 'api/firestore/updateDoc';
import { mapTournoiImportedFirebaseToTournoi } from './tournoi.imported.mappers';
import { useGetDocById } from 'api/firestore/getDocById';

export function useGetTournoiImportedById(tournoiId?: string) {
  const { data: tournoiImportedFirebase, isLoading: isTournoiLoading } =
    useGetDocById<TournoiImportedFirebase>(
      TOURNOI_IMPORTED_COLLECTION_NAME,
      tournoiId,
    );
  const { data: players, isLoading: isLoadingPlayers } = useGetPlayersList();

  const tournoi = useMemo(
    () =>
      tournoiImportedFirebase && !isTournoiLoading && !isLoadingPlayers
        ? mapTournoiImportedFirebaseToTournoi(tournoiImportedFirebase, players)
        : undefined,
    [isLoadingPlayers, isTournoiLoading, players, tournoiImportedFirebase],
  );
  return { tournoi, isLoading: isTournoiLoading || isLoadingPlayers };
}

export function useGetTournoiImportedList() {
  const { data: tournoiImportedListFirebase, isLoading: isLoadingTournois } =
    useGetCollection<TournoiImportedFirebase>(TOURNOI_IMPORTED_COLLECTION_NAME);

  const { data: players, isLoading: isLoadingPlayers } = useGetPlayersList();

  const tournoiImportedList = useMemo(
    () =>
      tournoiImportedListFirebase
        .map((tournoi) => mapTournoiImportedFirebaseToTournoi(tournoi, players))
        .sort((a, b) => b.date.getTime() - a.date.getTime()),
    [tournoiImportedListFirebase, players],
  );

  return {
    tournoiImportedList,
    isLoading: isLoadingTournois || isLoadingPlayers,
  };
}

export async function createTournoiImported(
  tournoiImported: Omit<TournoiImportedFirebase, 'id'>,
) {
  return (
    await createDoc<TournoiImportedFirebase>(
      TOURNOI_IMPORTED_COLLECTION_NAME,
      tournoiImported,
    )
  ).id;
}

export function updateTournoiImported(
  tournoiImported: TournoiImportedFirebase,
) {
  return updateDoc<TournoiImportedFirebase>(
    TOURNOI_IMPORTED_COLLECTION_NAME,
    tournoiImported,
  );
}
