import { Modal, UseDisclosureReturn } from '@chakra-ui/react';
import { useMemo, useState } from 'react';

import { AjouterKillValues, Tournoi } from '../tournoi.types';
import { Formik } from 'formik';
import { BPCYup } from 'common/form.validation';
import dayjs from 'dayjs';
import { ajouterKill } from '../live/tournoi.live.helpers';
import { useConnectedUserContext } from 'features/user/useConnectedUserContext';
import AjouterKillForm from './AjouterKillForm';

const AjouterKillSchema = BPCYup.object().shape({
  heure: BPCYup.string().required(),
  killerId: BPCYup.string().required(),
  victimeId: BPCYup.string().required(),
  isRecave: BPCYup.bool(),
});

export default function AjouterKillModal({
  tournoi,
  onSubmit,
  isOpen,
  onClose,
  withHourSelect,
}: {
  tournoi: Tournoi;
  onSubmit?: (newTournoi: Tournoi) => void;
  withHourSelect?: boolean;
} & Pick<UseDisclosureReturn, 'onClose' | 'isOpen'>) {
  const [errorMessage, setErrorMessage] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useConnectedUserContext();

  const initialValues: AjouterKillValues = useMemo(() => {
    return {
      heure: dayjs().format('HH:mm'),
      isRecave: false,
      victimeId: undefined,
      killerId: undefined,
    };
  }, []);

  async function handleSubmit(values: AjouterKillValues) {
    setErrorMessage(undefined);
    setIsLoading(true);

    await ajouterKill(tournoi, values, user);

    setIsLoading(false);
    onClose();
    if (onSubmit) {
      onSubmit(tournoi);
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      scrollBehavior="inside"
      size={'3xl'}
    >
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={AjouterKillSchema}
        onSubmit={handleSubmit}
      >
        <AjouterKillForm
          players={tournoi.players}
          nbRecavesMax={tournoi.nbRecavesMax ?? 0}
          errorMessage={errorMessage}
          isLoading={isLoading}
          onClose={onClose}
          withHourSelect={withHourSelect}
        />
      </Formik>
    </Modal>
  );
}
