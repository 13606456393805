import { Box } from '@chakra-ui/react';
import NouveauTournoiImportForm, {
  NouveauTournoiImportFormValues,
} from 'features/tournois/imported/components/NouveauTournoiImportForm';
import { RoutePath } from 'router/routes.paths';
import { useLocation } from 'react-router-dom';

import BPCPageHeading from 'common/components/BPCPageHeading';

export default function NouveauTournoiImportPage() {
  const { state } = useLocation();
  let tournoi: NouveauTournoiImportFormValues | undefined;
  ({ tournoi } = state || {});
  return (
    <Box>
      <BPCPageHeading
        title="Importer un nouveau tournoi"
        breadcrumbItems={[
          { to: RoutePath.home, name: 'Home' },
          { to: RoutePath.tournois, name: 'Tournois' },
        ]}
      />
      <NouveauTournoiImportForm tournoi={tournoi} />
    </Box>
  );
}
