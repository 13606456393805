import Chart from 'react-apexcharts';
import { Tournoi } from '../tournoi.types';
import { useMemo, useState } from 'react';
import { ChampionnatPlayer } from 'features/championnats/championnat.types';
import { calculerPointsFromTournoiPlayer } from 'features/championnats/championnat.helper';
import { Radio, RadioGroup, Stack } from '@chakra-ui/react';

export default function JoueursPointsGraph({
  tournois,
  players,
  methodeCalculPoints,
}: Readonly<{
  tournois: Tournoi[];
  players: ChampionnatPlayer[];
  methodeCalculPoints: string;
}>) {
  const [nbJoueurToDisplay, setNbJoueurToDisplay] = useState('5');

  const series = useMemo(() => {
    const playersPoints = players.reduce<Record<string, number>>(
      (acc, { id }) => ({
        ...acc,
        [id]: 0,
      }),
      {},
    );
    return players
      .map(({ name, id }) => ({
        name,
        data: [...tournois]
          .reverse()
          .reduce<{ x: Date; y: number }[]>((acc, tournoi) => {
            const tournoiPlayer = tournoi.players.find(
              (tournoiPlayer) => tournoiPlayer.id === id,
            );
            if (tournoiPlayer) {
              const points =
                calculerPointsFromTournoiPlayer(
                  methodeCalculPoints,
                  tournoi,
                  tournoiPlayer,
                ) ?? 0;
              playersPoints[id] = playersPoints[id] + points;
              acc.push({
                x: tournoi.date,
                y: playersPoints[id],
              });
            }
            return acc;
          }, []),
      }))
      .filter((_, i) => i < Number(nbJoueurToDisplay));
  }, [methodeCalculPoints, nbJoueurToDisplay, players, tournois]);

  return (
    <>
      <RadioGroup
        colorScheme="orange"
        onChange={setNbJoueurToDisplay}
        value={nbJoueurToDisplay}
      >
        <Stack direction="row">
          <Radio value="5">Top 5 joueurs</Radio>
          <Radio value="99">Tous les joueurs</Radio>
        </Stack>
      </RadioGroup>

      <Chart
        series={series}
        options={{
          stroke: {
            curve: 'smooth',
            width: 4,
          },
          markers: {
            size: 5,
          },
          xaxis: {
            type: 'datetime',
          },
          chart: {
            defaultLocale: 'fr',
            locales: [
              {
                name: 'fr',
                options: {
                  months: [
                    'Janvier',
                    'Février',
                    'Mars',
                    'Avril',
                    'Mai',
                    'Juin',
                    'Juillet',
                    'Août',
                    'Septembre',
                    'Octobre',
                    'Novembre',
                    'Décembre',
                  ],
                  shortMonths: [
                    'Jan',
                    'Fev',
                    'Mar',
                    'Avr',
                    'Mai',
                    'Juin',
                    'Juil',
                    'Aout',
                    'Sep',
                    'Oct',
                    'Nov',
                    'Dec',
                  ],
                  days: [
                    'Dimanche',
                    'Lundi',
                    'Mardi',
                    'Mercredi',
                    'Jeudi',
                    'Vendredi',
                    'Samedi',
                  ],
                  shortDays: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
                  toolbar: {
                    download: 'Télécharger SVG',
                    selection: 'Sélection',
                    selectionZoom: 'Sélection Zoom',
                    zoomIn: 'Zoom In',
                    zoomOut: 'Zoom Out',
                    pan: 'Déplacer',
                    reset: 'Reset Zoom',
                  },
                },
              },
            ],
          },
          theme: {
            palette: 'palette8',
          },
          legend: {
            position: 'bottom',
            onItemClick: {
              toggleDataSeries: true,
            },
          },
          yaxis: {
            labels: {
              formatter: function (value) {
                return `${Math.round(value)}`;
              },
            },
          },
        }}
        type="line"
        width="100%"
      />
    </>
  );
}
