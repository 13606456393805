import { ChakraProvider as ReactChakraProvider } from '@chakra-ui/react';

import { extendTheme } from '@chakra-ui/react';

const colorScheme = 'orange';

export const theme = extendTheme({
  components: {
    Button: {
      defaultProps: {
        colorScheme,
      },
    },
    Checkbox: {
      defaultProps: {
        colorScheme,
      },
    },
  },
});

export function ChakraProvider({ children }: { children: React.ReactNode }) {
  return <ReactChakraProvider theme={theme}>{children}</ReactChakraProvider>;
}
