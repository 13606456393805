import {
  Alert,
  AlertDescription,
  AlertIcon,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  SimpleGrid,
  StackDivider,
  VStack,
} from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import { AjouterKillValues, TournoiPlayer } from '../tournoi.types';
import { memo, useMemo } from 'react';
import { BPCInput } from 'common/components/form/BPCInput';
import TournoiPlayerAvatar from 'features/players/components/TournoiPlayerAvatar';

function AjouterKillForm({
  players,
  nbRecavesMax,
  errorMessage,
  isLoading,
  onClose,
  withHourSelect = true,
}: Readonly<{
  players: TournoiPlayer[];
  nbRecavesMax: number;
  errorMessage?: string;
  isLoading: boolean;
  onClose: () => void;
  withHourSelect?: boolean;
}>) {
  const playersInGame = useMemo(
    () => players.filter(({ position }) => !position),
    [players],
  );
  const { values, errors, touched, setFieldValue, handleSubmit } =
    useFormikContext<AjouterKillValues>();
  const possibleKillers = playersInGame.filter(
    ({ id }) => id !== values.victimeId,
  );

  const nbRecaveRestante = values.victimeId
    ? nbRecavesMax -
      (playersInGame.find(({ id }) => id === values.victimeId)?.nbRecave ?? 0)
    : undefined;

  return (
    <form onSubmit={handleSubmit}>
      <ModalOverlay />
      <ModalContent>
        {/* V2 ajouter des textes random */}
        <ModalHeader>Eliminer un joueur</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {withHourSelect && (
            <BPCInput
              type="time"
              fieldName="heure"
              label="Heure de l'élimination"
            />
          )}
          <VStack spacing={6} divider={<StackDivider />} align="strech">
            {errorMessage ? (
              <Alert status="error" mb={3}>
                <AlertIcon />
                <AlertDescription>{errorMessage}</AlertDescription>
              </Alert>
            ) : undefined}
            <FormControl
              isRequired
              isInvalid={Boolean(!!errors.victimeId && touched.victimeId)}
            >
              <FormLabel htmlFor="victimeId">Selectionner la victime</FormLabel>
              <RadioGroup
                id="victimeId"
                value={values.victimeId ?? undefined}
                onChange={(val) => setFieldValue('victimeId', val)}
              >
                <SimpleGrid columns={3} gap={2}>
                  {playersInGame.map((p) => (
                    <Radio colorScheme="orange" key={p.id} value={p.id}>
                      <TournoiPlayerAvatar player={p} namePosition="right" />
                    </Radio>
                  ))}
                </SimpleGrid>
              </RadioGroup>
              <FormErrorMessage>{errors.victimeId}</FormErrorMessage>
            </FormControl>
            <FormControl
              isRequired
              isInvalid={Boolean(!!errors.killerId && touched.killerId)}
            >
              <FormLabel htmlFor="killerId">Selectionner le killer</FormLabel>
              <RadioGroup
                id="killerId"
                value={values.killerId ?? undefined}
                onChange={(val) => setFieldValue('killerId', val)}
              >
                <SimpleGrid columns={3} gap={2}>
                  {possibleKillers.map((p) => (
                    <Radio colorScheme="orange" key={p.id} value={p.id}>
                      <TournoiPlayerAvatar player={p} namePosition="right" />
                    </Radio>
                  ))}
                </SimpleGrid>
              </RadioGroup>
              <FormErrorMessage>{errors.killerId}</FormErrorMessage>
            </FormControl>
            <FormControl>
              <RadioGroup
                id="isRecave"
                value={values.isRecave ? 'true' : 'false'}
                onChange={(val) => setFieldValue('isRecave', Boolean(val))}
              >
                <SimpleGrid columns={3} gap={2}>
                  <FormLabel m={0} htmlFor="isRecave">{`C'est une recave ${
                    nbRecaveRestante === undefined
                      ? ''
                      : nbRecaveRestante > 0
                      ? `(${nbRecaveRestante} possible${
                          nbRecaveRestante > 1 ? 's' : ''
                        })`
                      : nbRecaveRestante === 0
                      ? `(déjà toutes utilisées)`
                      : `(déjà ${Math.abs(nbRecaveRestante)} de trop)`
                  }`}</FormLabel>
                  <Radio colorScheme="orange" value={'true'}>
                    Oui
                  </Radio>
                  <Radio colorScheme="orange" value={'false'}>
                    Non
                  </Radio>
                </SimpleGrid>
              </RadioGroup>
              <FormErrorMessage>{errors.killerId}</FormErrorMessage>
            </FormControl>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button variant="ghost" mr={3} onClick={onClose}>
            Annuler
          </Button>
          <Button isLoading={isLoading} type="submit">
            Valider
          </Button>
        </ModalFooter>
      </ModalContent>
    </form>
  );
}

// AjouterKillForm.whyDidYouRender = {
//   logOnDifferentValues: true,
//   customName: 'Form',
// };

export default memo(AjouterKillForm);
