import { Box } from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router-dom';
import Loader from 'common/components/Loader';
import { NotFound } from './ErrorPage';
import { RoutePath } from 'router/routes.paths';
import { getTournoiName } from 'features/tournois/tournoi.helper';
import { useMemo } from 'react';
import BPCPageHeading from 'common/components/BPCPageHeading';
import { useGetTournoiImportedById } from 'features/tournois/imported/tournoi.imported.api';
import NouveauTournoiImportForm from 'features/tournois/imported/components/NouveauTournoiImportForm';

export default function EditionTournoiImportPage() {
  const { id } = useParams<{ id: string }>();
  const { tournoi, isLoading } = useGetTournoiImportedById(id!);
  const navigate = useNavigate();
  const tournoiName = useMemo(
    () => (tournoi ? getTournoiName(tournoi, 'short') : ''),
    [tournoi],
  );

  if (isLoading) {
    return <Loader fullScreen />;
  }

  if (!tournoi) {
    return <NotFound />;
  }

  return (
    <Box>
      <BPCPageHeading
        title={tournoiName}
        breadcrumbItems={[
          { to: RoutePath.home, name: 'Home' },
          { to: RoutePath.tournois, name: 'Tournois' },
          { to: `${RoutePath.tournoiImport}/${id}`, name: tournoiName },
        ]}
      />
      <NouveauTournoiImportForm
        tournoi={tournoi}
        submitButtonLabel="Modifier le tournoi"
        onCancel={() => navigate(-1)}
      />
    </Box>
  );
}
