import { findLastIndex } from 'common/common.helper';
import { StructureLevel } from './tournoi.types';

export function getStructureDetails(structure: StructureLevel[]): {
  timeInMin: { total: number; inPlay: number; inPause: number };
  level: { total: number; inPlay: number; inPause: number };
} {
  return structure.reduce(
    (agg, { isPause, duree }) => {
      agg.timeInMin.total += duree;
      agg.level.total += 1;
      if (isPause) {
        agg.timeInMin.inPause += duree;
        agg.level.inPause += 1;
      } else {
        agg.timeInMin.inPlay += duree;
        agg.level.inPlay += 1;
      }
      return agg;
    },
    {
      timeInMin: {
        total: 0,
        inPlay: 0,
        inPause: 0,
      },
      level: {
        total: 0,
        inPlay: 0,
        inPause: 0,
      },
    },
  );
}

export function getStructureLevelLabel(
  structure: StructureLevel[],
  levelIndex: number,
) {
  const { duree, bigBlind, smallBlind, isPause } = structure[levelIndex];
  const levelName = getLevelNameAtIndex(structure, levelIndex);
  let label = [levelName];
  if (!isPause) {
    label.push(`${smallBlind}/${bigBlind}`);
  }
  label.push(`${duree}min`);
  return label.join(' - ');
}

export function getLevelNameAtIndex(
  structure: StructureLevel[],
  index: number,
) {
  if (structure.length < index) {
    return 'Erreur';
  }
  const partialStats = getStructureDetails(structure.slice(0, index));
  const level = structure[index];
  if (level.isPause) {
    return `Pause #${partialStats.level.inPause + 1}`;
  }
  return `Niveau ${partialStats.level.inPlay + 1}`;
}

export function getPreviousAndNextLevelsAtIndex(
  structure: StructureLevel[],
  levelIndex: number,
) {
  const structureLength = structure.length;
  const currentLevel = structure[levelIndex];

  //Past
  const hasLevelBeforeThisOne = levelIndex > 0;
  const structureBeforeThisLevel = [...structure.slice(0, levelIndex)];
  const previousLevelIndex = hasLevelBeforeThisOne ? levelIndex - 1 : -1;
  const previousLevel =
    previousLevelIndex >= 0 ? structure[previousLevelIndex] : undefined;

  const previousPauseIndex = hasLevelBeforeThisOne
    ? findLastIndex(structureBeforeThisLevel, ({ isPause }) => isPause === true)
    : -1;
  const previousPause =
    previousPauseIndex >= 0 ? structure[previousPauseIndex] : undefined;

  const previousLevelNotPauseIndex =
    previousPauseIndex === previousLevelIndex
      ? levelIndex >= 2
        ? levelIndex - 2
        : -1
      : previousLevelIndex;
  const previousLevelNotPause =
    previousLevelNotPauseIndex >= 0
      ? structure[previousLevelNotPauseIndex]
      : undefined;

  //Future
  const hasLevelAfterThisOne = levelIndex < structureLength - 1;

  const structureAfterThisLevel = [...structure.slice(levelIndex + 1)];
  const nextLevelIndex = hasLevelAfterThisOne ? levelIndex + 1 : -1;
  const nextLevel =
    nextLevelIndex && nextLevelIndex >= 0
      ? structure[nextLevelIndex]
      : undefined;

  const nextPauseRelativeIndex = hasLevelAfterThisOne
    ? structureAfterThisLevel.findIndex(({ isPause }) => isPause)
    : -1;
  const nextPauseIndex =
    nextPauseRelativeIndex >= 0 && nextLevelIndex >= 0
      ? nextPauseRelativeIndex + nextLevelIndex
      : -1;
  const nextPause = nextPauseIndex >= 0 ? structure[nextPauseIndex] : undefined;

  const nextLevelNotPauseIndex =
    nextPauseIndex === nextLevelIndex
      ? levelIndex < structureLength - 2
        ? levelIndex + 2
        : -1
      : nextLevelIndex;
  const nextLevelNotPause =
    nextLevelNotPauseIndex && nextLevelNotPauseIndex >= 0
      ? structure[nextLevelNotPauseIndex]
      : undefined;

  return {
    previousLevelIndex,
    previousLevel,
    previousLevelNotPauseIndex,
    previousLevelNotPause,
    previousPauseIndex,
    previousPause,

    currentLevel,

    nextLevelIndex,
    nextLevel,
    nextLevelNotPauseIndex,
    nextLevelNotPause,
    nextPauseIndex,
    nextPause,
  };
}

export function getDefaultEcartFromSmallBlind(smallBlind: number) {
  if (smallBlind < 0) {
    return 0;
  }
  if (smallBlind < 600) {
    return 100;
  }
  if (smallBlind < 1000) {
    return 200;
  }
  if (smallBlind < 3000) {
    return 500;
  }
  if (smallBlind < 6000) {
    return 1000;
  }
  if (smallBlind < 10000) {
    return 2000;
  }
  if (smallBlind < 20000) {
    return 5000;
  }
  return 10000;
}
