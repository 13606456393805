import {
  Tournoi,
  TournoiKillHistory,
  TournoiPlayer,
  TournoiPlayerRealtime,
  TournoiRealtime,
  TournoiStatus,
} from './tournoi.types';
import dayjs from 'dayjs';
import {
  formatDateWithDayMonth,
  formatDateWithDayNameDayMonth,
  formatDateWithDayNameDayMonthYear,
} from 'common/date.helper';
import { mapPlayerToNewTournoiPlayer } from 'features/players/players.mappers';
import { NouveauTournoiFormValues } from './components/NouveauTournoiForm';

export function getTournoiStatusText(status: TournoiStatus) {
  switch (status) {
    case 'notStarted': {
      return 'A venir';
    }
    case 'running': {
      return 'En cours';
    }
    case 'paused': {
      return 'En pause';
    }
    case 'finished': {
      return 'Terminé';
    }
    default: {
      const switchCheck: never = status;
      return switchCheck;
    }
  }
}

export function computeTournoiTotalKill(players: TournoiPlayer[]) {
  return players.reduce((acc, { nbKills }) => acc + nbKills, 0);
}

export function computeTournoiTotalCave(
  players: TournoiPlayerRealtime[],
  killsHistory: TournoiKillHistory[],
) {
  return (
    players.length +
    killsHistory.reduce((acc, { isRecave }) => (isRecave ? acc + 1 : acc), 0)
  );
}

export function computeTournoiTotalRecave(
  players: TournoiPlayerRealtime[],
  killsHistory: TournoiKillHistory[],
) {
  return computeTournoiTotalCave(players, killsHistory) - players.length;
}

export function computeTournoiTotalMoney(
  tournoi: Pick<TournoiRealtime, 'montantBuyIn' | 'players' | 'killsHistory'>,
) {
  const { montantBuyIn = 0, players = [], killsHistory = [] } = tournoi || {};
  return montantBuyIn * computeTournoiTotalCave(players, killsHistory);
}

function computeMoneyFromPrice(
  price: number,
  tournoiTotalMoney: number,
  arrondi: number,
) {
  return Math.round((price * tournoiTotalMoney) / arrondi) * arrondi;
}

export function computeMoneyFromPriceList({
  prices,
  priceIndex,
  partialTournoi,
  arrondi = 5,
}: {
  prices: number[];
  priceIndex: number;
  partialTournoi: Pick<
    TournoiRealtime,
    'montantBuyIn' | 'players' | 'killsHistory'
  >;
  arrondi?: number;
}) {
  const tournoiTotalMoney = computeTournoiTotalMoney(partialTournoi);
  if (priceIndex === prices.length - 1) {
    const tournoiTotalMoneyBefore = prices.reduce(
      (acc, price, index) =>
        index < priceIndex
          ? acc + computeMoneyFromPrice(price, tournoiTotalMoney, arrondi)
          : acc,
      0,
    );
    return tournoiTotalMoney - tournoiTotalMoneyBefore;
  } else {
    return computeMoneyFromPrice(
      prices[priceIndex],
      tournoiTotalMoney,
      arrondi,
    );
  }
}

export function getTournoiName(
  { name, date }: Pick<Tournoi, 'name' | 'date'>,
  variant: 'short' | 'with-date',
): string {
  if (!name) {
    return formatTournoiDate(date);
  }
  if (variant === 'short') {
    return name;
  } else if (variant === 'with-date') {
    return `${name} (${formatDateWithDayMonth(date)})`;
  }
  return '';
}

export function filterNameForAvatarDisplay(name: string) {
  return name.replaceAll(/[!| |?|0-9]/gi, '');
}

export function generateTournoiToDuplicate(
  tournoi: Tournoi,
): NouveauTournoiFormValues {
  const { id: _id, players, championnatIds, ...restTournoiToCopy } = tournoi;

  return {
    ...restTournoiToCopy,
    name: undefined,
    date: dayjs().toDate(),
    players: players.map(({ id, name, photo }) =>
      mapPlayerToNewTournoiPlayer(
        { id, name, photo },
        restTournoiToCopy.montantBuyIn,
      ),
    ),
    championnatId: championnatIds?.length ? championnatIds[0] : undefined,
    status: 'notStarted',
    elapsedSecInTournament: 0,
    killsHistory: [],
  };
}

export function computePositionColor(position?: number) {
  // source : https://www.color-hex.com/color-palette/50061
  if (position === 1) {
    return '#d4af37';
  }
  if (position === 2) {
    return '#c0c0c0';
  }
  if (position === 3) {
    return '#cd7f32';
  }
  return 'white';
}

export function formatTournoiDate(date: dayjs.Dayjs | Date) {
  if (dayjs(date).year() === dayjs().year()) {
    return formatDateWithDayNameDayMonth(date);
  }
  return formatDateWithDayNameDayMonthYear(date);
}
