import {
  Avatar,
  AvatarBadge,
  Box,
  Card,
  CardBody,
  Stack,
  StackDivider,
  StyleProps,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';
import BPCTable from 'common/components/BPCTable';
import { useMemo } from 'react';
import { Player } from '../players.types';
import {
  filterNameForAvatarDisplay,
  getTournoiName,
} from 'features/tournois/tournoi.helper';
import { FaTrophy } from 'react-icons/fa';
import { Championnat } from 'features/championnats/championnat.types';
import { calculerPointsFromTournoiPlayer } from 'features/championnats/championnat.helper';
import BPCCardRow from 'common/components/BPCCardRow';
import { formatMoney } from 'common/string.helper';
import { BPCLink } from 'common/components/BPCLink';
import { RoutePath } from 'router/routes.paths';

export default function JoueurDetail({
  player,
  championnat: {
    tournois: tournoisNotFiltered,
    methodeCalculPoints,
    players: championnatPlayers,
  },
  ...restStyleProps
}: { player: Player; championnat: Championnat } & StyleProps) {
  const tournois = useMemo(
    () =>
      tournoisNotFiltered.filter(
        (tournoi) =>
          tournoi.status === 'finished' &&
          tournoi.players.findIndex((p) => p.id === player.id) > -1,
      ),
    [player.id, tournoisNotFiltered],
  );
  const championnatPlayer = useMemo(
    () => championnatPlayers.find((p) => p.id === player.id),
    [championnatPlayers, player.id],
  );

  const tournoisTableColumns = [
    {
      property: 'avatar' as const,
      sortingProperty: 'date' as const,
      name: '',
      type: 'custom' as const,
    },
    { property: 'date' as const, name: 'Date', type: 'date' as const },
    {
      property: 'position' as const,
      name: 'Position',
      type: 'textCentered' as const,
    },
    {
      property: 'points' as const,
      name: 'Points',
      type: 'nombre' as const,
    },
    {
      property: 'kills' as const,
      name: 'Kills',
      type: 'nombre' as const,
    },
    {
      property: 'recaves' as const,
      name: 'Recaves',
      type: 'nombre' as const,
    },
    { property: 'depense' as const, name: 'Dépenses', type: 'money' as const },
    {
      property: 'gains' as const,
      name: 'Gains',
      type: 'money' as const,
    },
    {
      property: 'benef' as const,
      name: 'Bénéfices',
      type: 'money' as const,
    },
    { property: 'name' as const, name: 'Nom', type: 'text' as const },
  ];

  const tournoisTableData = useMemo(
    () =>
      tournois.map(({ id: tournoiId, name, date, players, montantBuyIn }) => {
        const winnerPlayer = players.find(({ position }) => position === 1);
        const tournoiPlayer = players.find(({ id }) => id === player.id);
        return {
          avatar: (
            <BPCLink to={`${RoutePath.tournois}/${tournoiId}`}>
              <Avatar
                size="sm"
                name={name ? filterNameForAvatarDisplay(name) : undefined}
                bg={name ? undefined : 'orange.500'}
                icon={<FaTrophy fontSize="1rem" />}
              >
                {winnerPlayer && (
                  <AvatarBadge boxSize="1.25em">
                    <Avatar
                      size="xs"
                      name={winnerPlayer.name}
                      src={winnerPlayer.photo}
                    />
                  </AvatarBadge>
                )}
              </Avatar>
            </BPCLink>
          ),
          name: (
            <BPCLink to={`${RoutePath.tournois}/${tournoiId}`}>
              {getTournoiName({ name, date }, 'short')}
            </BPCLink>
          ),
          date,
          position: tournoiPlayer?.position
            ? `${tournoiPlayer.position}/${players.length}`
            : '-',
          points: tournoiPlayer
            ? calculerPointsFromTournoiPlayer(
                methodeCalculPoints,
                { players, montantBuyIn },
                tournoiPlayer,
              )
            : undefined,
          kills: tournoiPlayer?.nbKills,
          recaves: tournoiPlayer?.nbRecave,
          depense: tournoiPlayer?.depense,
          gains: tournoiPlayer?.prizeInEuro,
          benef: tournoiPlayer
            ? (tournoiPlayer?.prizeInEuro ?? 0) - tournoiPlayer.depense
            : undefined,
        };
      }),
    [methodeCalculPoints, player.id, tournois],
  );

  return (
    <Box {...restStyleProps}>
      <Tabs colorScheme="orange">
        <TabList>
          <Tab>Résultats</Tab>
          {/* <Tab>Statistiques</Tab> */}
          <Tab>Infos</Tab>
        </TabList>

        <TabPanels>
          {/* Resultats */}
          <TabPanel>
            <BPCTable
              stickyFirstCol
              stickyHeader
              data={tournoisTableData}
              columns={tournoisTableColumns}
            />
          </TabPanel>

          {/* Statistiques */}
          {/* <TabPanel></TabPanel> */}

          {/* Infos */}
          <TabPanel>
            <Card>
              <CardBody>
                <Stack divider={<StackDivider />} spacing="4">
                  <BPCCardRow titre="Nombre de tournois joués">
                    {tournois.length}
                  </BPCCardRow>
                  <BPCCardRow titre="Dépense totale">
                    {formatMoney(championnatPlayer?.totalDepense ?? 0)}
                  </BPCCardRow>
                  <BPCCardRow titre="Gains totaux">
                    {formatMoney(championnatPlayer?.totalGain ?? 0)}
                  </BPCCardRow>
                  <BPCCardRow titre="Gains moyen">
                    {formatMoney(
                      (championnatPlayer?.totalGain ?? 0) / tournois.length,
                    )}
                  </BPCCardRow>
                  <BPCCardRow titre="Nombre d'ITM">
                    {championnatPlayer?.nbItm}
                  </BPCCardRow>
                  <BPCCardRow titre="Nombre de victoires">
                    {championnatPlayer?.nbVictoires}
                  </BPCCardRow>
                  <BPCCardRow titre="Nombre de kills">
                    {championnatPlayer?.totalKill}
                  </BPCCardRow>
                </Stack>
              </CardBody>
            </Card>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
}
