import { Box, useBreakpointValue } from '@chakra-ui/react';
import Loader from 'common/components/Loader';
import { useNavigate, useParams } from 'react-router-dom';
import { NotFound } from './ErrorPage';
import { RoutePath } from 'router/routes.paths';
import { getTournoiName } from 'features/tournois/tournoi.helper';
import BPCPageHeading from 'common/components/BPCPageHeading';
import { FaCopy, FaEdit } from 'react-icons/fa';
import { useGetTournoiImportedById } from 'features/tournois/imported/tournoi.imported.api';
import { TournoiImportDetail } from 'features/tournois/imported/components/TournoiImportDetail';
import { generateTournoiImportedToDuplicate } from 'features/tournois/imported/tournoi.imported.helper';

export default function TournoiImportDetailPage() {
  const { id } = useParams<{ id: string }>();
  const { tournoi, isLoading } = useGetTournoiImportedById(id!);
  const navigate = useNavigate();

  const modifierTitle = useBreakpointValue(
    { base: '', md: 'Modifier' },
    { fallback: 'md' },
  );
  const dupliquerTitle = useBreakpointValue(
    { base: '', md: 'Dupliquer' },
    { fallback: 'md' },
  );

  if (isLoading) {
    return <Loader fullScreen />;
  }

  if (!tournoi) {
    return <NotFound />;
  }
  const { name, date } = tournoi;

  return (
    <Box>
      <BPCPageHeading
        helmetName={getTournoiName(tournoi, 'short')}
        title={getTournoiName({ date, name }, 'with-date')}
        breadcrumbItems={[
          { to: RoutePath.home, name: 'Home' },
          { to: RoutePath.tournois, name: 'Tournois' },
        ]}
        buttonProps={[
          {
            leftIcon: <FaEdit />,
            title: modifierTitle,
            onClick: () => navigate(`${RoutePath.tournoiImport}/${id}/edition`),
          },
          {
            leftIcon: <FaCopy />,
            title: dupliquerTitle,
            onClick: () =>
              navigate(`${RoutePath.tournoiImport}/creation`, {
                state: {
                  tournoi: generateTournoiImportedToDuplicate(tournoi),
                },
              }),
          },
        ].filter((v) => v !== undefined)}
      />

      <TournoiImportDetail tournoi={tournoi} />
    </Box>
  );
}
