import {
  Container,
  HStack,
  IconButton,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  StyleProps,
  VStack,
} from '@chakra-ui/react';
import {
  FaPause,
  FaPlay,
  FaAngleDoubleLeft,
  FaAngleDoubleRight,
} from 'react-icons/fa';
import { FaArrowRotateLeft } from 'react-icons/fa6';
import {
  getTournoiElapsedSecSinceBeginningOfLevel,
  mapIpadRemToVh,
  pauseOrResumeTournoi,
} from '../tournoi.live.helpers';
import { LevelLive, TournoiLive } from '../tournoi.live.types';
import { useMemo } from 'react';
import { upsertTournoi } from '../../tournoi.api';
import { mapTournoiLiveToTournoi } from '../tournoi.live.mappers';

export default function TournoiLiveControls({
  tournoi,
  displayControls,
  ...restStylePros
}: { tournoi: TournoiLive; displayControls: boolean } & StyleProps) {
  const { status, secondsLeftInLevel, previousLevel, currentLevel, nextLevel } =
    tournoi;

  const sliderValue = useMemo(() => {
    const dureeInSec = currentLevel.level.duree * 60;
    return ((dureeInSec - secondsLeftInLevel) / dureeInSec) * 100;
  }, [secondsLeftInLevel, currentLevel]);

  function updateTournoiElapsedSec(newElapsedSecInTournament: number) {
    upsertTournoi(
      mapTournoiLiveToTournoi({
        ...tournoi,
        elapsedSecInTournament: newElapsedSecInTournament,
        lastRestartDate: new Date(),
      }),
    );
  }

  function goToStartOfLevel(level?: LevelLive) {
    if (!level) {
      return;
    }
    const elapsedSecSinceBeginningOfLevel =
      getTournoiElapsedSecSinceBeginningOfLevel(tournoi, level.index);

    updateTournoiElapsedSec(elapsedSecSinceBeginningOfLevel);
  }

  function onSliderChange(val: number) {
    const dureeInSec = currentLevel.level.duree * 60;
    const newSecondsSpentInLevel = Math.floor(
      // on cappe la valeur à 99% sinon l'algo passe 2 niveaux d'un coup
      dureeInSec * (Math.min(val, 99) / 100),
    );
    const elapsedSecSinceBeginningOfLevel =
      getTournoiElapsedSecSinceBeginningOfLevel(tournoi, currentLevel.index);

    updateTournoiElapsedSec(
      elapsedSecSinceBeginningOfLevel + newSecondsSpentInLevel,
    );
  }
  return (
    <Container {...restStylePros}>
      <VStack gap={mapIpadRemToVh(1)}>
        <Slider
          colorScheme="orange"
          onChange={onSliderChange}
          value={sliderValue}
          isDisabled={tournoi.status === 'finished'}
          isReadOnly={!displayControls}
          maxWidth={'80vw'}
        >
          <SliderTrack>
            <SliderFilledTrack />
          </SliderTrack>
          <SliderThumb
            //  Pour eviter le petit rond bleu lorsque le slider avance tout seul
            _focusVisible={{ boxShadow: 'var(--chakra-shadows-base)' }}
          />
        </Slider>
        {displayControls && (
          <HStack gap={6} alignItems={'center'} justifyContent={'center'}>
            <IconButton
              size={mapIpadRemToVh(3)}
              cursor={'pointer'}
              aria-label="previousLevel"
              variant={'ghost'}
              icon={<FaAngleDoubleLeft size={mapIpadRemToVh(3)} />}
              isDisabled={!previousLevel || tournoi.status === 'finished'}
              onClick={() => goToStartOfLevel(previousLevel)}
            />
            <IconButton
              size={mapIpadRemToVh(3)}
              cursor={'pointer'}
              aria-label="restartLevel"
              variant={'ghost'}
              icon={<FaArrowRotateLeft size={mapIpadRemToVh(3)} />}
              isDisabled={tournoi.status === 'finished'}
              onClick={() => goToStartOfLevel(currentLevel)}
            />
            <IconButton
              size={mapIpadRemToVh(3)}
              cursor={'pointer'}
              aria-label="pauseOrResume"
              variant={'ghost'}
              icon={
                status === 'paused' || status === 'notStarted' ? (
                  <FaPlay size={mapIpadRemToVh(3)} />
                ) : (
                  <FaPause size={mapIpadRemToVh(3)} />
                )
              }
              isDisabled={tournoi.status === 'finished'}
              onClick={() => pauseOrResumeTournoi(tournoi)}
            />

            <IconButton
              size={mapIpadRemToVh(3)}
              cursor={'pointer'}
              aria-label="nextLevel"
              variant={'ghost'}
              icon={<FaAngleDoubleRight size={mapIpadRemToVh(3)} />}
              onClick={() => goToStartOfLevel(nextLevel)}
              isDisabled={!nextLevel || tournoi.status === 'finished'}
            />
          </HStack>
        )}
      </VStack>
    </Container>
  );
}
