import {
  StyleProps,
  Box,
  StackDivider,
  Stack,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Td,
  Tbody,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Text,
  Flex,
} from '@chakra-ui/react';
import { FC, useMemo } from 'react';

import { formatMoney, formatPercentage } from 'common/string.helper';
import BPCCardRow from 'common/components/BPCCardRow';
import {
  computeMoneyFromPriceList,
  computeTournoiTotalCave,
  computeTournoiTotalKill,
} from '../../tournoi.helper';
import { useGetChampionnatById } from 'features/championnats/championnat.api';
import {
  formatDateWithDayAndHourSec,
  formatDateWithDayNameDayMonthYear,
} from 'common/date.helper';
import { calculerPointsFromTournoiPlayer } from 'features/championnats/championnat.helper';
import TournoiPlayerAvatar from 'features/players/components/TournoiPlayerAvatar';
import BPCTable from 'common/components/BPCTable';
import { RoutePath } from 'router/routes.paths';
import { BPCLink } from 'common/components/BPCLink';
import { Tournoi } from 'features/tournois/tournoi.types';

export const TournoiImportDetail: FC<{ tournoi: Tournoi } & StyleProps> = ({
  tournoi,
  ...restStyleProps
}) => {
  const {
    players,
    championnatIds,
    montantBuyIn,
    date,
    jetonsRecus,
    nbRecavesMax,
    prizePool,
  } = tournoi;
  const { championnat } = useGetChampionnatById(
    championnatIds.length ? championnatIds[0] : undefined,
  );

  const playersTableColumns = useMemo(() => {
    const totalRecave =
      computeTournoiTotalCave(tournoi.players, tournoi.killsHistory) -
      tournoi.players.length;
    const totalKill = computeTournoiTotalKill(tournoi.players);

    return [
      {
        property: 'avatar' as const,
        sortingProperty: 'position' as const,
        defaultSortingAsc: false,
        name: '',
        type: 'custom' as const,
      },
      { property: 'name' as const, name: 'Nom', type: 'text' as const },
      {
        property: 'points' as const,
        name: 'Points',
        type: 'nombre' as const,
      },
      {
        property: 'nbRecave' as const,
        name: `Recaves${totalRecave > 0 ? ` (${totalRecave})` : ''}`,
        type: 'nombre' as const,
      },
      {
        property: 'nbKills' as const,
        name: `Kills${totalKill > 0 ? ` (${totalKill})` : ''}`,
        type: 'nombre' as const,
      },
      { property: 'prize' as const, name: 'Gains', type: 'money' as const },
      //Important de mettre les hidden en dernier pour ne pas interférer avec le stickyFirstcol
      {
        property: 'position' as const,
        name: '',
        type: 'hidden' as const,
      },
    ];
  }, [tournoi.killsHistory, tournoi.players]);

  const playersTableData = useMemo(
    () =>
      players.map((p, i) => {
        return {
          position: p.position,
          avatar: tournoi.championnatIds.length ? (
            <BPCLink
              to={`${RoutePath.championnats}/${tournoi.championnatIds[0]}/joueurs/${p.id}`}
            >
              <TournoiPlayerAvatar player={p} />
            </BPCLink>
          ) : (
            <TournoiPlayerAvatar player={p} />
          ),
          name: tournoi.championnatIds.length ? (
            <BPCLink
              to={`${RoutePath.championnats}/${tournoi.championnatIds[0]}/joueurs/${p.id}`}
            >
              {p.name}
            </BPCLink>
          ) : (
            p.name
          ),
          points: championnat?.methodeCalculPoints
            ? calculerPointsFromTournoiPlayer(
                championnat?.methodeCalculPoints,
                tournoi,
                p,
              )
            : undefined,
          nbRecave: p.nbRecave,
          nbKills: p.nbKills,
          prize: p.prizeInEuro,
        };
      }),
    [championnat, players, tournoi],
  );

  return (
    <Box {...restStyleProps}>
      <Tabs colorScheme="orange">
        <TabList>
          <Tab>Résultats</Tab>
          <Tab>Infos</Tab>
        </TabList>

        <TabPanels>
          {/* Tab resultats */}
          <TabPanel>
            <BPCTable
              stickyFirstCol
              stickyHeader
              data={playersTableData}
              columns={playersTableColumns}
              defaultSortBy={{
                property: 'position',
                asc: true,
              }}
            />
          </TabPanel>

          {/* Tab infos */}
          <TabPanel>
            <Stack divider={<StackDivider />} spacing="4">
              <BPCCardRow titre="Date" textTransform="capitalize">
                {formatDateWithDayNameDayMonthYear(date)}
              </BPCCardRow>
              <BPCCardRow titre="Demarrage">
                {tournoi.startDate
                  ? formatDateWithDayAndHourSec(tournoi.startDate)
                  : 'Pas encore démarré'}
              </BPCCardRow>
              <BPCCardRow titre="Statut">
                <Flex alignItems={'center'} gap={4}>
                  <Text>Importé</Text>
                </Flex>
              </BPCCardRow>
              <BPCCardRow titre="Championnat">
                {championnat ? championnat.name : 'Aucun championnat'}
              </BPCCardRow>
              <BPCCardRow titre="Buy-in">
                {formatMoney(montantBuyIn)}
              </BPCCardRow>
              <BPCCardRow titre="Jetons reçus">{jetonsRecus}</BPCCardRow>
              <BPCCardRow titre="Nombre de recaves maximum">
                {nbRecavesMax}
              </BPCCardRow>

              <BPCCardRow titre="Prizepool">
                <TableContainer>
                  <Table variant="simple" size="md">
                    <Thead>
                      <Tr>
                        <Th>Place</Th>
                        <Th isNumeric>Pourcentage</Th>
                        <Th isNumeric>Montant</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {prizePool.map((price, index) => (
                        <Tr key={index}>
                          <Td>{index + 1}</Td>
                          <Td isNumeric>{formatPercentage(price)}</Td>
                          <Td isNumeric>
                            {formatMoney(
                              computeMoneyFromPriceList({
                                prices: prizePool,
                                priceIndex: index,
                                partialTournoi: tournoi,
                              }),
                            )}
                          </Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </TableContainer>
              </BPCCardRow>
            </Stack>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};
