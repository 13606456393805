import { Box } from '@chakra-ui/react';
import { RoutePath } from 'router/routes.paths';
import BPCPageHeading from 'common/components/BPCPageHeading';
import { PokerRange } from 'features/ranges/ranges.type';
import EditRangeForm from 'features/ranges/components/EditRangeForm';
import { useLocation } from 'react-router-dom';
import { useConnectedUserContext } from 'features/user/useConnectedUserContext';
import Loader from 'common/components/Loader';

export default function OutilNouvelleRangePage() {
  const { state } = useLocation();
  const { user } = useConnectedUserContext();
  let range: PokerRange | undefined;
  ({ range } = state || {});

  if (!user) {
    return <Loader fullScreen />;
  }

  return (
    <Box>
      <BPCPageHeading
        helmetName="Ranges - nouveau"
        title="Nouvelle range"
        breadcrumbItems={[
          { to: RoutePath.home, name: 'Home' },
          { to: RoutePath.outils, name: 'Outils' },
          { to: RoutePath.ranges, name: 'Liste des ranges' },
        ]}
      />
      <EditRangeForm range={range} userId={user.id} />
    </Box>
  );
}
