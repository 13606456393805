import { doc, getDoc as getDocFirebase } from 'firebase/firestore';
import { firebase_db } from '../firebase.config';
import { useEffect, useState } from 'react';
import {
  BPCCollection,
  transformCollectionForFirestore,
} from './firestore.helpers';

export const getDocById = async <T>(collection: BPCCollection, id: string) => {
  const docRef = doc(
    firebase_db,
    ...transformCollectionForFirestore(collection),
    id,
  );
  const data = (await getDocFirebase(docRef)).data() as T;
  return data ? ({ id, ...data } as T) : undefined;
};

export const useGetDocById = <T>(
  collection: BPCCollection,
  id?: string,
  options?: Partial<{ skip: boolean }>,
) => {
  const { skip = false } = options ?? {};
  const [data, setData] = useState<T>();
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    async function getData() {
      const dataFetched = await getDocById<T>(collection, id!);
      setIsLoading(false);
      setData(dataFetched);
    }
    if (!skip && id) {
      getData();
    }
  }, [collection, id, skip]);
  return { data, isLoading };
};
