import {
  collection as collectionFirebase,
  addDoc as addDocFirebase,
} from 'firebase/firestore';
import { firebase_db } from '../firebase.config';
import { deleteUndefinedKeys } from 'common/common.helper';
import { transformCollectionForFirestore } from './firestore.helpers';

export const createDoc = async <T extends { id: string } & Record<string, any>>(
  collection: string | string[],
  data: Omit<T, 'id'>,
) => {
  const dataToAdd = { ...data };
  deleteUndefinedKeys(dataToAdd);

  return addDocFirebase(
    collectionFirebase(
      firebase_db,
      ...transformCollectionForFirestore(collection),
    ),
    dataToAdd,
  );
};
