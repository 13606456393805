const BPCLogo = ({
  width = '100%',
  height = '100%',
  fill = 'var(--chakra-colors-orange-400)',
  ...props
}: React.ComponentPropsWithoutRef<'svg'>) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 900.000000 900.000000"
      preserveAspectRatio="xMidYMid meet"
      {...props}
    >
      <g
        transform="translate(0.000000,900.000000) scale(0.100000,-0.100000)"
        fill={fill}
        stroke="none"
      >
        <path d="M1560 6951 c-65 -15 -113 -42 -164 -94 -56 -57 -78 -96 -95 -170 -8 -34 -11 -665 -11 -2240 1 -1818 3 -2202 14 -2249 24 -100 104 -187 208 -226 38 -15 130 -18 833 -22 l790 -5 5 -60 c5 -61 29 -109 61 -120 9 -3 659 -6 1445 -8 1350 -2 1431 -1 1457 16 34 22 58 69 66 130 l6 48 761 -1 c805 0 813 0 904 46 19 10 60 43 90 73 44 44 61 70 78 122 l22 65 0 2215 c0 2529 11 2267 -95 2375 -33 33 -82 72 -110 85 l-50 24 -2367 0 -2368 0 -36 -36 c-80 -80 -64 -220 32 -280 31 -19 74 -19 2297 -19 2513 0 2320 5 2345 -64 16 -44 16 -3394 1 -3413 -9 -10 -551 -13 -3000 -13 -1644 0 -2996 3 -3004 6 -13 5 -15 201 -15 1708 0 935 3 1710 6 1721 3 11 19 28 35 38 26 14 58 17 217 17 173 0 188 1 217 21 77 53 99 163 46 235 -60 83 -42 79 -330 81 -141 1 -272 -2 -291 -6z m6128 -4356 c2 -115 -1 -195 -9 -222 -21 -78 29 -73 -781 -71 l-723 3 -8 45 c-19 105 -36 136 -91 168 -29 16 -120 17 -1414 20 -1365 2 -1383 2 -1427 -18 -62 -28 -87 -69 -94 -149 l-6 -66 -699 -3 c-685 -2 -699 -2 -731 18 -18 11 -35 30 -39 43 -8 30 -8 402 1 410 3 4 1359 6 3012 5 l3006 -3 3 -180z m-1965 -449 c15 -7 16 -11 5 -17 -18 -12 -2169 -11 -2187 0 -18 12 8 19 84 24 109 8 2081 1 2098 -7z" />
        <path d="M2515 6861 c-97 -24 -146 -111 -130 -231 5 -39 15 -57 46 -89 45 -44 91 -57 165 -46 114 17 189 169 135 273 -44 85 -117 117 -216 93z" />
        <path d="M6065 6088 c-76 -40 -123 -120 -125 -210 0 -34 -3 -34 -68 -3 -94 45 -178 26 -243 -57 -32 -40 -34 -46 -33 -117 1 -58 6 -86 23 -118 29 -54 74 -98 128 -124 24 -11 43 -22 43 -24 0 -2 -20 -14 -44 -26 -82 -41 -144 -139 -145 -229 -1 -95 57 -173 151 -201 46 -15 109 9 171 63 23 21 45 38 49 38 4 0 10 -17 14 -37 10 -54 20 -70 66 -103 36 -26 50 -30 108 -30 59 0 72 4 111 32 55 39 78 67 79 93 1 45 16 48 53 10 72 -74 136 -91 206 -55 105 53 149 174 102 282 -23 54 -56 89 -120 128 -55 34 -57 44 -12 53 48 10 116 84 140 152 25 72 26 101 5 157 -30 78 -109 138 -179 138 -36 0 -105 -31 -126 -56 -23 -28 -39 -16 -39 32 0 162 -180 283 -315 212z" />
        <path d="M3004 5632 c-16 -8 -161 -148 -321 -311 -367 -371 -353 -320 -145 -518 157 -151 298 -290 400 -396 36 -38 75 -68 91 -72 31 -7 81 9 81 25 0 6 129 141 288 301 158 159 291 298 296 308 5 11 6 31 2 45 -3 14 -105 122 -238 254 -405 401 -396 394 -454 364z" />
        <path d="M4605 5341 c-210 -53 -353 -188 -426 -403 -10 -32 -19 -65 -19 -74 0 -36 -33 -126 -48 -131 -34 -13 -131 -94 -200 -168 -73 -78 -79 -86 -120 -172 -40 -81 -42 -86 -62 -158 -22 -77 -27 -250 -10 -317 30 -117 46 -156 89 -233 49 -87 189 -222 273 -263 125 -61 134 -62 630 -62 284 0 466 4 492 11 163 41 238 81 340 178 75 72 122 138 154 216 44 111 52 154 52 294 0 134 -7 179 -45 266 -7 17 -16 37 -19 45 -3 8 -15 29 -26 45 -11 17 -24 39 -29 50 -14 32 -167 185 -244 245 -70 54 -87 80 -87 129 0 103 -109 307 -206 386 -64 52 -159 105 -190 105 -7 0 -39 7 -71 15 -73 19 -140 18 -228 -4z m203 -360 c41 -21 102 -78 102 -96 0 -6 5 -16 11 -22 17 -17 46 -111 53 -174 9 -77 33 -110 118 -166 115 -76 195 -163 247 -268 52 -107 53 -113 53 -212 0 -123 -24 -176 -106 -238 -73 -55 -131 -66 -211 -38 -59 20 -187 142 -284 271 -35 46 -66 82 -71 80 -9 -3 -66 -72 -90 -108 -94 -141 -180 -219 -277 -250 -95 -30 -208 31 -261 141 -26 54 -31 188 -8 241 8 18 22 53 32 78 24 60 88 142 167 215 78 73 94 85 105 85 16 0 60 55 76 94 9 21 20 62 25 90 14 82 56 191 85 225 62 70 157 91 234 52z" />
        <path d="M7 84 c-4 -4 -7 -25 -7 -46 0 -32 3 -38 21 -38 18 0 20 5 17 42 -3 39 -16 57 -31 42z" />
      </g>
    </svg>
  );
};

export default BPCLogo;
