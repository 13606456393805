import { Box } from '@chakra-ui/react';
import NouveauTournoiForm, {
  NouveauTournoiFormValues,
} from 'features/tournois/components/NouveauTournoiForm';
import { RoutePath } from 'router/routes.paths';
import { useLocation } from 'react-router-dom';

import BPCPageHeading from 'common/components/BPCPageHeading';

export default function NouveauTournoiPage() {
  const { state } = useLocation();
  let tournoi: NouveauTournoiFormValues | undefined;
  ({ tournoi } = state || {});
  return (
    <Box>
      <BPCPageHeading
        title="Nouveau tournoi"
        breadcrumbItems={[
          { to: RoutePath.home, name: 'Home' },
          { to: RoutePath.tournois, name: 'Tournois' },
        ]}
      />
      <NouveauTournoiForm tournoi={tournoi} />
    </Box>
  );
}
