import { firebase_auth } from 'api/firebase.config';
import { User } from 'firebase/auth';
import { useEffect, useState } from 'react';

function useFirebaseAuthentication(): {
  firebaseAuthUser?: User;
  isFirstLoading: boolean;
} {
  const [firebaseAuthUser, setFirebaseAuthUser] = useState<User>();
  const [isFirstLoading, setIsFirstLoading] = useState(true);

  useEffect(() => {
    const unlisten = firebase_auth.onAuthStateChanged((authUser) => {
      setIsFirstLoading(false);
      if (authUser) {
        setFirebaseAuthUser(authUser);
      } else {
        setFirebaseAuthUser(undefined);
      }
    });
    return () => {
      unlisten();
    };
  }, []);

  return {
    firebaseAuthUser,
    isFirstLoading,
  };
}

export default useFirebaseAuthentication;
