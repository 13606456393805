import { Helmet } from 'react-helmet';
import BPCBreadcrumb, { BPCBreadcrumbItem } from './BPCBreadcrumb';
import {
  Box,
  Button,
  ButtonGroup,
  ButtonProps,
  Flex,
  Heading,
  IconButton,
} from '@chakra-ui/react';

export default function BPCPageHeading({
  title,
  helmetName,
  breadcrumbItems,
  buttonProps,
}: {
  helmetName?: string;
  title?: string;
  breadcrumbItems?: BPCBreadcrumbItem[];
  buttonProps?: (ButtonProps & {
    onClick: ButtonProps['onClick'];
  })[];
}) {
  return (
    <Box>
      {(helmetName || title) && (
        <Helmet>
          <title>BPC - {helmetName ?? title}</title>
        </Helmet>
      )}
      {breadcrumbItems && <BPCBreadcrumb items={breadcrumbItems} />}
      <Flex alignItems={'center'} justifyContent={'space-between'} mb={6}>
        {title && (
          <Heading size="md" textTransform={'uppercase'}>
            {title}
          </Heading>
        )}
        {buttonProps && (
          <ButtonGroup>
            {buttonProps.map(
              ({ leftIcon, title, onClick, ...restButtonProps }, i) =>
                title ? (
                  <Button
                    key={i}
                    leftIcon={leftIcon}
                    onClick={onClick}
                    {...restButtonProps}
                  >
                    {title}
                  </Button>
                ) : (
                  <IconButton
                    key={i}
                    aria-label=""
                    icon={leftIcon}
                    onClick={onClick}
                    {...restButtonProps}
                  />
                ),
            )}
          </ButtonGroup>
        )}
      </Flex>
    </Box>
  );
}
