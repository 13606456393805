import { getStorage } from 'api/storage/getStorage';
import { useCallback, useState } from 'react';
import { sleep } from './common.helper';
import { SONS, SonType } from './sons.constants';
import { TournoiPlayer } from 'features/tournois/tournoi.types';
import { Howl } from 'howler';

const SONS_STORAGE_FOLDER = 'sons';
export type AVAILABLE_SONS =
  | 'tictac'
  | 'gong'
  | 'paquebot'
  | 'piece'
  | 'pistolet'
  | 'cassette_audio'
  | 'porte'
  | 'joie'
  | 'toast';

export async function joueSon(
  nom: AVAILABLE_SONS,
  dureeInSec: number | undefined,
  couperApresDuree: boolean,
) {
  const audioFileStorageUrl = `${SONS_STORAGE_FOLDER}/${nom}.mp3`;
  const url = await getStorage(audioFileStorageUrl);
  const audio = new Howl({ src: [url], volume: 0.5, autoplay: true });
  let dureePlayed = 0;
  await sleep(200);
  while (audio.playing() && (!dureeInSec || dureePlayed / 1000 <= dureeInSec)) {
    await sleep(200);
    dureePlayed = dureePlayed + 200;
  }
  if (couperApresDuree) {
    audio.stop();
  }
}

export function useSons() {
  const [isSonPlaying, setIsLoading] = useState(false);
  const joueSonCallback = useCallback(
    async (
      nom: AVAILABLE_SONS,
      dureeInSec: number | undefined,
      couperApresDuree: boolean,
    ) => {
      setIsLoading(true);
      await joueSon(nom, dureeInSec, couperApresDuree);
      setIsLoading(false);
    },
    [],
  );
  return {
    joueSon: joueSonCallback,
    isSonPlaying,
  };
}

export function getRandomSon(
  sonType: SonType,
  killer?: TournoiPlayer,
  victime?: TournoiPlayer,
) {
  const sonsForType = SONS[sonType](killer, victime);
  const nbSonForType = sonsForType.length;
  const sonIndex = Math.floor(Math.random() * nbSonForType);
  const phrase = sonsForType[sonIndex];
  return phrase;
}
