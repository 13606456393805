import { ref, set, DatabaseReference, push } from 'firebase/database';
import { firebase_realtime_db } from '../firebase.config';
import { deleteUndefinedKeys } from 'common/common.helper';

type UpsertRealtimeObjectParams<
  T extends { id?: string } & Record<string, any>,
> = {
  objectId?: string;
  object: Omit<T, 'id'>;
  setObject?: (o: T) => void;
};
export type UpsertRealtimeObject<
  T extends { id?: string } & Record<string, any>,
> = (params: UpsertRealtimeObjectParams<T>) => void;

export const upsertRealtimeObject = async <
  T extends { id?: string } & Record<string, any>,
>(
  listName: string,
  { objectId, object, setObject }: UpsertRealtimeObjectParams<T>,
) => {
  let objRef: DatabaseReference;
  if (objectId) {
    objRef = ref(firebase_realtime_db, `${listName}/${objectId}`);
  } else {
    const listRef = ref(firebase_realtime_db, listName);
    objRef = push(listRef);
  }

  const objectToStore = { ...object };
  if (objectToStore.id) {
    delete objectToStore.id;
  }
  deleteUndefinedKeys(objectToStore);
  await set(objRef, objectToStore);
  const id = objRef.key ?? undefined;
  const newObject = { id, ...object } as T;

  if (setObject) {
    setObject(newObject);
  }
  return newObject;
};

export const upsertRealtimeObjectProp = async (
  listName: string,
  {
    objectId,
    propVal,
    propPath,
  }: {
    propPath: string;
    objectId: string;
    propVal: string | number;
  },
) => {
  let objRef: DatabaseReference;

  objRef = ref(firebase_realtime_db, `${listName}/${objectId}${propPath}`);

  await set(objRef, propVal);
};
