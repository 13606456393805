import { Formik, FormikProps } from 'formik';
import {
  Button,
  VStack,
  Alert,
  AlertIcon,
  AlertDescription,
  Input,
  FormControl,
  FormLabel,
  Flex,
  Center,
} from '@chakra-ui/react';
import { BPCInput } from 'common/components/form/BPCInput';
import { BPCYup } from 'common/form.validation';
import { Player } from 'features/players/players.types';
import { Avatar } from '@chakra-ui/react';
import { RefObject, useState } from 'react';
import { uploadStorage } from 'api/storage/uploadStorage';
import { v4 as uuidv4 } from 'uuid';
import { getStorage } from 'api/storage/getStorage';
import { getAudioPlayerName } from 'features/players/players.helpers';
import { useParle } from 'common/textToSpeech.helper';

const EditProfileSchema = BPCYup.object().shape({
  email: BPCYup.string(),
  name: BPCYup.string()
    .min(2, 'Ton nom doit contenir au moins 2 caractères')
    .required(),
  nameForAudio: BPCYup.string(),
  photo: BPCYup.string().url(),
});

export default function EditProfileForm({
  user,
  onSubmit,
  submitButtonLabel = 'Enregister',
  isLoading,
  errorMessage,
  isEmailDisabled = true,
  hideSubmitButton,
  formRef,
  onCancel,
}: {
  user: Omit<Player, 'id'>;
  onSubmit: (values: Omit<Player, 'id'>) => void;
  submitButtonLabel?: string;
  isLoading?: boolean;
  errorMessage?: string;
  isEmailDisabled?: boolean;
  hideSubmitButton?: boolean;
  formRef?: RefObject<FormikProps<Omit<Player, 'id'>>>;
  onCancel?: () => void;
}) {
  const [avatarPreview, setAvatarPreview] = useState<string>();
  const [photoToUpload, setPhotoToUpload] = useState<File>();
  const { parle, isParleLoading } = useParle();

  function handleSubmit(values: Omit<Player, 'id'>) {
    if (photoToUpload) {
      const photoToUploadLocation = `avatars/${uuidv4()}`;
      return uploadStorage(photoToUploadLocation, photoToUpload)
        .then(() => getStorage(photoToUploadLocation))
        .then((photoUrl) => onSubmit({ ...values, photo: photoUrl }));
    } else {
      return onSubmit(values);
    }
  }
  return (
    <Formik
      validationSchema={EditProfileSchema}
      enableReinitialize
      initialValues={user}
      onSubmit={handleSubmit}
      innerRef={formRef}
    >
      {({ handleSubmit, values, errors, touched }) => (
        <form onSubmit={handleSubmit}>
          <VStack spacing={4} align="strech">
            {errorMessage ? (
              <Alert status="error">
                <AlertIcon />
                <AlertDescription>{errorMessage}</AlertDescription>
              </Alert>
            ) : undefined}
            <FormControl isInvalid={Boolean(!!errors.photo && touched.photo)}>
              <FormLabel>Photo de profil</FormLabel>
              <FormLabel htmlFor="avatar-input">
                <Center>
                  <Avatar
                    size="2xl"
                    name={values.name}
                    src={avatarPreview ?? values.photo}
                    cursor={'pointer'}
                  />
                </Center>
              </FormLabel>
              <Input
                id="avatar-input"
                type="file"
                display={'none'}
                onChange={(event) => {
                  event.preventDefault();
                  const reader = new FileReader();
                  const file =
                    event.target.files && event.target.files.length
                      ? event.target.files[0]
                      : undefined;
                  reader.onloadend = () => {
                    setAvatarPreview(reader.result as string);
                    setPhotoToUpload(file);
                  };
                  if (file) {
                    reader.readAsDataURL(file);
                  }
                }}
              />
            </FormControl>
            <BPCInput fieldName="name" label="Nom" type="text" isRequired />
            <BPCInput
              isDisabled={isEmailDisabled}
              fieldName="email"
              label="Email"
              type="email"
            />
            <Flex>
              <BPCInput
                fieldName="nameForAudio"
                label="Nom phonétique"
                type="text"
                placeholder={values.name}
                suffix={
                  <Button
                    variant="unstyled"
                    isLoading={isParleLoading}
                    onClick={() => parle(getAudioPlayerName(values))}
                  >
                    Tester
                  </Button>
                }
              />
            </Flex>

            {!hideSubmitButton && (
              <Button isLoading={isLoading} type="submit" width="full">
                {submitButtonLabel}
              </Button>
            )}
            {onCancel && (
              <Button variant="outline" width="full" onClick={onCancel}>
                Annuler
              </Button>
            )}
          </VStack>
        </form>
      )}
    </Formik>
  );
}
