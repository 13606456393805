import {
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  Image,
  VStack,
  ModalCloseButton,
  ModalProps,
  HStack,
  Heading,
} from '@chakra-ui/react';
import {
  HistoryType,
  getHistoryIconForType,
} from 'features/tournois/history.helper';
import { useMemo } from 'react';

const NB_GIF_PER_TYPE: Record<HistoryType, number> = {
  kill: 8,
  recave: 7,
  win: 6,
  error: 0,
};

export default function TournoiLiveToast({
  title,
  description,
  type,
  onClose,
}: {
  title: string;
  description: string;
  type: HistoryType;
  onClose: ModalProps['onClose'];
}) {
  //https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Math/random
  const gifSrc = useMemo(
    () =>
      `/images/${type}-${
        Math.floor(Math.random() * NB_GIF_PER_TYPE[type]) + 1
      }.gif`,
    [type],
  );

  return (
    <Modal isCentered isOpen={true} onClose={onClose}>
      <ModalOverlay />
      <ModalContent p={8} boxShadow="lg">
        <ModalHeader>
          <HStack alignItems={'center'} justifyContent={'space-evenly'}>
            {getHistoryIconForType(type)} <Heading>{title} </Heading>
            {getHistoryIconForType(type)}
          </HStack>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack alignItems={'center'} justifyContent={'center'} spacing={3}>
            <Image src={gifSrc} objectFit="cover" />
            <Text fontSize={'lg'}>{description}</Text>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
