import { Box, useBreakpointValue } from '@chakra-ui/react';
import { RoutePath } from 'router/routes.paths';
import BPCPageHeading from 'common/components/BPCPageHeading';
import { useNavigate, useParams } from 'react-router-dom';
import Loader from 'common/components/Loader';
import { useGetRangeById } from 'features/ranges/ranges.api';
import { NotFound } from './ErrorPage';
import RangeDetail from 'features/ranges/components/RangeDetail';
import {
  formatRangeDescription,
  generateRangeToDuplicate,
} from 'features/ranges/ranges.helper';
import { FaCopy, FaEdit } from 'react-icons/fa';

export default function OutilRangeDetailPage() {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const { range, isLoading } = useGetRangeById(id);
  const modifierTitle = useBreakpointValue(
    { base: '', md: 'Modifier' },
    { fallback: 'md' },
  );
  const dupliquerTitle = useBreakpointValue(
    { base: '', md: 'Dupliquer' },
    { fallback: 'md' },
  );

  if (isLoading) {
    return <Loader fullScreen />;
  }

  if (!range) {
    return <NotFound />;
  }

  return (
    <Box>
      <BPCPageHeading
        helmetName="Range"
        title={`${range.category} - ${formatRangeDescription(range)}`}
        breadcrumbItems={[
          { to: RoutePath.home, name: 'Home' },
          { to: RoutePath.outils, name: 'Outils' },
          { to: RoutePath.ranges, name: 'Liste des ranges' },
        ]}
        buttonProps={[
          {
            leftIcon: <FaEdit />,
            onClick: () => navigate(`${RoutePath.ranges}/${id}/edition`),
            title: modifierTitle,
          },
          {
            leftIcon: <FaCopy />,
            title: dupliquerTitle,
            onClick: () =>
              navigate(`${RoutePath.ranges}/creation`, {
                state: {
                  range: generateRangeToDuplicate(range),
                },
              }),
          },
        ]}
      />
      <RangeDetail range={range} />
    </Box>
  );
}
