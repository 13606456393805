import Loader from 'common/components/Loader';
import { useParams } from 'react-router-dom';
import { NotFound } from './ErrorPage';
import { useGetTournoiLiveById } from 'features/tournois/tournoi.api';
import {
  Box,
  Heading,
  IconButton,
  SimpleGrid,
  Stack,
  StyleProps,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useMemo, useState } from 'react';
import {
  formatMinutesToHourAndMin,
  formatSecondsMinSec2Digits,
} from 'common/date.helper';

import {
  computeMoneyFromPriceList,
  computePositionColor,
  computeTournoiTotalCave,
  computeTournoiTotalRecave,
  getTournoiName,
} from 'features/tournois/tournoi.helper';
import TournoiPlayerAvatar from 'features/players/components/TournoiPlayerAvatar';
import BPCCardRow from 'common/components/BPCCardRow';
import BPCBreadcrumb from 'common/components/BPCBreadcrumb';
import { RoutePath } from 'router/routes.paths';
import TournoiLiveControls from 'features/tournois/live/components/TournoiLiveControls';
import { TournoiLive } from 'features/tournois/live/tournoi.live.types';
import { formatMoney, formatNumber1Digit } from 'common/string.helper';
import {
  appendVh,
  mapIpadRemToVh,
  useTournoiSoundsAndToast,
} from 'features/tournois/live/tournoi.live.helpers';
import { useConnectedUserContext } from 'features/user/useConnectedUserContext';
import { IoVolumeMute, IoVolumeHigh } from 'react-icons/io5';
import { getLevelNameAtIndex } from 'features/tournois/structure.helper';
import useVueEnrichie from 'features/tournois/live/useVueEnrichie.hook';
import { TournoiHistoryCard } from 'features/tournois/components/TournoiHistoryCard';
import { TbBroadcast, TbBroadcastOff } from 'react-icons/tb';

import '../../features/tournois/live/tournoi.live.styles.scss';

export default function TournoiLivePage() {
  const { id } = useParams<{ id: string }>();
  const { tournoiLive, isLoading: isLoadingTournoi } = useGetTournoiLiveById(
    id!,
  );

  if (isLoadingTournoi) {
    return <Loader fullScreen />;
  }

  if (!tournoiLive) {
    return <NotFound />;
  }

  return (
    <Box>
      <TournoiLiveContent tournoi={tournoiLive} />
    </Box>
  );
}

const livePageCardProps: StyleProps = {
  zIndex: 1,
  bg: 'blackAlpha.700',
  p: mapIpadRemToVh(0.75),
  rounded: mapIpadRemToVh(0.375),
};

const livePageCardInfoProps: StyleProps = {
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  color: 'white',
};

const HORLOGE_ROW_HEIGHT_VH = 45;
const HORLOGE_BLOCK_WIDTH_VW = 55;
const TOP_PADDING_VH = 3.5;
const TITLE_VH = 3.5;
const VSTACK_GAP_VH = 1.5 * 2;
const BOTTOM_PADDING_VH = 6;

const TAB_MAX_HEIGHT = appendVh(
  100 -
    (TOP_PADDING_VH +
      TITLE_VH +
      HORLOGE_ROW_HEIGHT_VH +
      VSTACK_GAP_VH +
      BOTTOM_PADDING_VH),
);

function TournoiLiveContent({ tournoi }: Readonly<{ tournoi: TournoiLive }>) {
  const { user } = useConnectedUserContext();
  const [isSoundActive, setIsSoundActive] = useState(user?.role === 'admin');
  useTournoiSoundsAndToast(tournoi, isSoundActive);
  const { displayVueEnrichie, resetVueEnrichie } = useVueEnrichie();

  const tournoiName = useMemo(
    () => getTournoiName(tournoi, 'with-date'),
    [tournoi],
  );

  const playersInGame = useMemo(
    () => tournoi.players.filter(({ position }) => !position),
    [tournoi.players],
  );

  const tapisMoyen = useMemo(() => {
    const nbCaves = computeTournoiTotalCave(
      tournoi.players,
      tournoi.killsHistory,
    );
    const totalJeton = tournoi.jetonsRecus * nbCaves;
    return playersInGame.length >= 1
      ? totalJeton / playersInGame.length
      : totalJeton;
  }, [tournoi, playersInGame]);

  function calculerTapisMoyenEnBb() {
    if (tournoi.currentLevel.level.isPause && !tournoi.previousLevel) {
      return '-';
    }
    const montantBb = tournoi.currentLevel.level.isPause
      ? tournoi.previousLevel?.level.bigBlind!
      : tournoi.currentLevel.level.bigBlind;
    return formatNumber1Digit(tapisMoyen / montantBb);
  }

  const totalRecave = useMemo(
    () => computeTournoiTotalRecave(tournoi.players, tournoi.killsHistory),
    [tournoi],
  );

  const { structure, prizePool, players } = tournoi;

  const [tvMode, setTvMode] = useState(false);
  const circleContainers = useMemo(() => {
    const arr = [];
    if (tvMode) {
      for (let i = 1; i < 200; i++) {
        arr.push(
          <div key={i} className="circle-container">
            <div className="circle" />
          </div>,
        );
      }
    }
    return arr;
  }, [tvMode]);

  return (
    <Box
      id="live-page-root"
      bg="gray.100"
      backgroundImage={'/background-table-poker.png'}
      backgroundSize={'cover'}
      height={{ md: '100vh' }}
      width={{ md: '100vw' }}
      display={{ md: 'flex' }}
      alignItems={{ md: 'center' }}
      onMouseMove={resetVueEnrichie}
      color="gray.400"
      className="background-container"
    >
      {circleContainers}
      {displayVueEnrichie && (
        <Box
          zIndex={1}
          color={'white'}
          position={'absolute'}
          top={0}
          width={'100%'}
        >
          {/* <ConnectedLayoutHeader user={user} mb={4} /> */}
          <BPCBreadcrumb
            m={mapIpadRemToVh(1.5)}
            items={[
              {
                to: `${RoutePath.tournois}/${tournoi.id}`,
                name: `< Retour`,
              },
            ]}
            fontSize={mapIpadRemToVh(0.75)}
          />
        </Box>
      )}

      <VStack
        height={{ md: '100vh' }}
        width={{ md: '100%' }}
        gap={mapIpadRemToVh(0.75)}
        mx={mapIpadRemToVh(1.5)}
        pt={{ base: 12, md: appendVh(TOP_PADDING_VH) }}
        overflow={'hidden'}
      >
        {/* Titre */}
        <Heading
          zIndex={1}
          color="white"
          size="md"
          textTransform={'uppercase'}
          fontSize={mapIpadRemToVh(1.25)}
        >
          {tournoiName}
        </Heading>

        {/* Horloge et controles du temps */}
        <Box
          {...livePageCardProps}
          width={'100%'}
          height={{ md: appendVh(HORLOGE_ROW_HEIGHT_VH) }}
          borderColor={
            tournoi.status === 'running'
              ? 'green.500'
              : tournoi.status === 'paused'
              ? 'red.500'
              : undefined
          }
          borderWidth={mapIpadRemToVh(0.5)}
          position={'relative'}
          textAlign={'center'}
        >
          {/* Niveau en cours */}
          <Box fontSize={mapIpadRemToVh(1.5)} mb={mapIpadRemToVh(1.5)}>
            {`${getLevelNameAtIndex(structure, tournoi.currentLevel.index)} - ${
              tournoi.currentLevel.level.duree
            }min`}
          </Box>

          {/* Horloge */}
          <Stack
            flexDirection={{ base: 'column', md: 'row' }}
            alignItems={'center'}
            justifyContent={'center'}
            //TODO: voir si celui ci il faut le changer ou pas
            gap={20}
          >
            <VStack
              gap={mapIpadRemToVh(1)}
              alignItems={'center'}
              justifyContent={'center'}
              width={`${HORLOGE_BLOCK_WIDTH_VW}vw`}
            >
              <Heading
                fontSize={{
                  base: '5xl',
                  md: displayVueEnrichie
                    ? mapIpadRemToVh(4.5)
                    : mapIpadRemToVh(8),
                }}
                whiteSpace={'nowrap'}
                color="white"
              >
                {formatSecondsMinSec2Digits(tournoi.secondsLeftInLevel)}
              </Heading>
              <TournoiLiveControls
                tournoi={tournoi}
                displayControls={displayVueEnrichie}
              />
            </VStack>
            {!tournoi.currentLevel.level.isPause && (
              <Stack
                flexDirection={{ base: 'row', md: 'column' }}
                gap={{ base: 6, md: mapIpadRemToVh(1.5) }}
              >
                <BPCCardRow
                  // {...livePageCardProps}
                  titre="Small blind"
                  titreSize={mapIpadRemToVh(0.875)}
                  textAlign={{ base: 'left', md: 'center' }}
                  jumbo
                >
                  <Text
                    fontSize={{ base: '3xl', md: mapIpadRemToVh(3.75) }}
                    lineHeight={mapIpadRemToVh(3)}
                    fontWeight={'bold'}
                    color="white"
                  >
                    {tournoi.currentLevel.level.smallBlind.toLocaleString()}
                  </Text>
                </BPCCardRow>
                <BPCCardRow
                  // {...livePageCardProps}
                  titre="Big blind"
                  titreSize={mapIpadRemToVh(0.875)}
                  textAlign={{ base: 'left', md: 'center' }}
                  jumbo
                >
                  <Text
                    fontSize={{ base: '3xl', md: mapIpadRemToVh(3.75) }}
                    lineHeight={mapIpadRemToVh(3)}
                    fontWeight={'bold'}
                    color="white"
                  >
                    {tournoi.currentLevel.level.bigBlind.toLocaleString()}
                  </Text>
                </BPCCardRow>
              </Stack>
            )}
          </Stack>
          {displayVueEnrichie && (
            <Stack
              flexDirection={{ base: 'column', md: 'row' }}
              position={'absolute'}
              top={mapIpadRemToVh(1.5)}
              right={mapIpadRemToVh(1.5)}
            >
              <IconButton
                aria-label="mute"
                onClick={() => setIsSoundActive(!isSoundActive)}
                icon={isSoundActive ? <IoVolumeHigh /> : <IoVolumeMute />}
                variant={'ghost'}
                fontSize={{ base: '24px', md: mapIpadRemToVh(2.25) }}
              />
              <IconButton
                aria-label="tv-mode"
                onClick={() => setTvMode(!tvMode)}
                icon={tvMode ? <TbBroadcast /> : <TbBroadcastOff />}
                variant={'ghost'}
                fontSize={{ base: '24px', md: mapIpadRemToVh(2.25) }}
              />
            </Stack>
          )}
        </Box>

        <Stack
          flexDirection={{ base: 'column-reverse', md: 'row' }}
          //TODO: a changer
          gap={{ base: 4, md: 4 }}
          alignItems={'flex-start'}
          height={{ md: TAB_MAX_HEIGHT }}
          width={'100%'}
        >
          {/* Prizepools */}
          <BPCCardRow
            display={'flex'}
            flexDirection={'column'}
            titre="Prizepool"
            textAlign={{ base: 'left', md: 'center' }}
            jumbo
            height={'100%'}
            width={'100%'}
            contentStyleProps={{ flex: 'auto', overflow: 'auto' }}
            flex={1}
            {...livePageCardProps}
          >
            <Stack
              flexDirection={{ base: 'row', md: 'column' }}
              height={'100%'}
              overflow={'auto'}
              pt={{ base: 2, md: mapIpadRemToVh(0.5) }}
              gap={{ base: 4, md: mapIpadRemToVh(1) }}
              justifyContent="flex-start"
              flex={1}
              color="white"
            >
              {prizePool.map((prize, i) => {
                const position = i + 1;
                const playerInPosition = players.find(
                  (p) => p.position === position,
                );
                return (
                  <VStack key={i} gap={1}>
                    <TournoiPlayerAvatar
                      size={'md'}
                      player={
                        playerInPosition ?? {
                          name: position.toString(),
                        }
                      }
                      bg={
                        playerInPosition
                          ? undefined
                          : computePositionColor(position)
                      }
                      avatarProps={{
                        width: mapIpadRemToVh(3),
                        height: mapIpadRemToVh(3),
                      }}
                      responsiveFontSize
                    />
                    <Text fontSize={{ base: 'lg', md: mapIpadRemToVh(1.125) }}>
                      {formatMoney(
                        computeMoneyFromPriceList({
                          prices: prizePool,
                          priceIndex: i,
                          partialTournoi: tournoi,
                        }),
                      )}
                    </Text>
                  </VStack>
                );
              })}
            </Stack>
          </BPCCardRow>

          {/* Cards infos du tournoi */}
          <SimpleGrid
            columns={2}
            spacingY={{ base: 2, md: mapIpadRemToVh(0.5) }}
            //TODO: alignement horizontal
            spacingX={6}
            flex={2}
            height={'100%'}
          >
            <BPCCardRow
              titre="Prochaine pause"
              textAlign={{ base: 'left', md: 'center' }}
              jumbo
              contentStyleProps={{ height: 'calc(100% - 1.5rem)' }}
              {...livePageCardProps}
            >
              {tournoi.nextPause && playersInGame.length ? (
                <Box {...livePageCardInfoProps}>
                  <Text
                    fontSize={{ base: '2xl', md: mapIpadRemToVh(1.875) }}
                    fontWeight={'bold'}
                  >
                    {formatMinutesToHourAndMin(
                      Math.floor(tournoi.secondsLeftToNextPause / 60),
                    )}
                  </Text>
                </Box>
              ) : (
                <Box
                  fontSize={{ base: '2xl', md: mapIpadRemToVh(1.5) }}
                  {...livePageCardInfoProps}
                >
                  Pas de prochaine pause
                </Box>
              )}
            </BPCCardRow>

            <BPCCardRow
              titre="Prochain niveau"
              textAlign={{ base: 'left', md: 'center' }}
              jumbo
              contentStyleProps={{ height: 'calc(100% - 1.5rem)' }}
              {...livePageCardProps}
            >
              {tournoi.nextLevelNotPause && playersInGame.length ? (
                <Box {...livePageCardInfoProps}>
                  <Text
                    fontSize={{ base: '2xl', md: mapIpadRemToVh(1.5) }}
                    fontWeight={'bold'}
                    whiteSpace={'nowrap'}
                  >
                    {`${tournoi.nextLevelNotPause.level.smallBlind} - ${tournoi.nextLevelNotPause.level.bigBlind}`}
                  </Text>
                  <Text fontSize={{ base: 'lg', md: mapIpadRemToVh(1.225) }}>
                    {tournoi.nextLevelNotPause.level.duree}min
                  </Text>
                </Box>
              ) : (
                <Box
                  {...livePageCardInfoProps}
                  fontSize={{ base: '2xl', md: mapIpadRemToVh(1.5) }}
                >
                  Pas de prochain niveau
                </Box>
              )}
            </BPCCardRow>

            <BPCCardRow
              titre="Tapis moyen"
              textAlign={{ base: 'left', md: 'center' }}
              jumbo
              contentStyleProps={{ height: 'calc(100% - 1.5rem)' }}
              {...livePageCardProps}
            >
              {playersInGame.length ? (
                <Box {...livePageCardInfoProps}>
                  <Text
                    fontSize={{ base: '2xl', md: mapIpadRemToVh(1.5) }}
                    fontWeight={'bold'}
                    whiteSpace={'nowrap'}
                  >
                    {Math.round(tapisMoyen).toLocaleString()}
                  </Text>
                  <Text fontSize={{ base: 'lg', md: mapIpadRemToVh(1.225) }}>
                    {calculerTapisMoyenEnBb()}bb
                  </Text>
                </Box>
              ) : (
                <Box {...livePageCardInfoProps}>-</Box>
              )}
            </BPCCardRow>

            <BPCCardRow
              titre="Joueurs"
              textAlign={{ base: 'left', md: 'center' }}
              jumbo
              contentStyleProps={{ height: 'calc(100% - 1.5rem)' }}
              {...livePageCardProps}
            >
              <Box {...livePageCardInfoProps}>
                <Text
                  fontSize={{ base: '2xl', md: mapIpadRemToVh(1.5) }}
                  fontWeight={'bold'}
                  whiteSpace={'nowrap'}
                >
                  {playersInGame.length} / {tournoi.players.length}
                </Text>
                <Text fontSize={{ base: 'lg', md: mapIpadRemToVh(1.225) }}>
                  {`${totalRecave} recave${totalRecave > 1 ? 's' : ''}`}
                </Text>
              </Box>
            </BPCCardRow>
          </SimpleGrid>

          <TournoiHistoryCard
            {...livePageCardProps}
            tournoi={tournoi}
            flex={2}
            overflow={'auto'}
            height={'100%'}
            position="relative"
            pt={{ base: '3rem', md: mapIpadRemToVh(3) }}
            buttonGroupProps={{
              position: 'absolute',
              top: mapIpadRemToVh(0.5),
            }}
            color="white"
            withHourSelect={false}
          />
        </Stack>
      </VStack>
    </Box>
  );
}
