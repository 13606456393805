import { Outlet } from 'react-router-dom';
import { Container } from '@chakra-ui/react';
import { useConnectedUserContext } from 'features/user/useConnectedUserContext';
import Loader from 'common/components/Loader';
import ConnectedLayoutHeader from './ConnectedLayoutHeader';

export const NAVBAR_HEIGHT = '4rem';

export default function ConnectedLayout() {
  const { user } = useConnectedUserContext();

  if (!user) {
    return <Loader fullScreen />;
  }

  return (
    <>
      <ConnectedLayoutHeader
        position={'sticky'}
        top={0}
        zIndex={3}
        mb={4}
        user={user}
      />
      <Container
        maxW="container.lg"
        overflowX={'scroll'}
        height={`calc(100vh - ${NAVBAR_HEIGHT})`}
        mb={8}
      >
        <Outlet />
      </Container>
    </>
  );
}
