import { Box, Flex } from '@chakra-ui/react';
import { RoutePath } from 'router/routes.paths';
import { BPCCard } from 'common/components/BPCCard';
import { FaPercent } from 'react-icons/fa';
import { TbCards } from 'react-icons/tb';
import BPCPageHeading from 'common/components/BPCPageHeading';
import { FaTableCells } from 'react-icons/fa6';
import { GiGraduateCap } from 'react-icons/gi';

const OUTILS = [
  {
    name: 'Poker trainer',
    linkTo: 'https://app.pokertrainer.se/',
    icon: <GiGraduateCap />,
  },
  {
    name: "Calculateur d'équité",
    linkTo: 'https://rockhopper.netlify.app/',
    icon: <FaPercent />,
  },
  {
    name: 'Push or fold',
    linkTo: 'https://poktools.com/app-trainer/',
    icon: <TbCards />,
  },
  {
    name: 'Revision des ranges',
    linkTo: RoutePath.ranges,
    icon: <FaTableCells />,
  },
];

export default function OutilsPage() {
  return (
    <Box>
      <BPCPageHeading
        helmetName="Outils"
        title="Liste des outils"
        breadcrumbItems={[{ to: RoutePath.home, name: 'Home' }]}
      />

      <Flex wrap={'wrap'}>
        {OUTILS.map(({ name, linkTo, icon }, i) => (
          <BPCCard
            m={4}
            key={i}
            linkTo={linkTo}
            title={name}
            avatarProps={{
              icon,
              name: icon ? undefined : name,
            }}
            width={'9rem'}
          />
        ))}
      </Flex>
    </Box>
  );
}
