import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  UseDisclosureReturn,
} from '@chakra-ui/react';
import EditNiveauForm from './EditNiveauForm';
import { StructureLevel } from '../tournoi.types';
import { useMemo, useRef } from 'react';
import { getLevelNameAtIndex } from '../structure.helper';
import { FormikProps } from 'formik';

export default function EditNiveauModal({
  levelIndex,
  structure,
  isOpen,
  onClose,
  onSubmit,
}: {
  levelIndex: number;
  structure: StructureLevel[];
  onSubmit: (newStructure: StructureLevel[]) => void;
} & Pick<UseDisclosureReturn, 'onClose' | 'isOpen'>) {
  const formRef = useRef<FormikProps<StructureLevel>>(null);

  const initialValues: StructureLevel = useMemo(() => {
    return structure[levelIndex];
  }, [structure, levelIndex]);

  function onEditNiveauSubmit(newNiveau: StructureLevel) {
    const newStructure = [...structure];
    newStructure[levelIndex] = newNiveau;
    onClose();
    return onSubmit(newStructure);
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose!}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {`Edition ${getLevelNameAtIndex(structure, levelIndex)}`}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <EditNiveauForm
            initialValues={initialValues}
            onSubmit={onEditNiveauSubmit}
            formRef={formRef}
          />
        </ModalBody>

        <ModalFooter>
          <Button variant="ghost" mr={3} onClick={onClose}>
            Annuler
          </Button>
          <Button
            onClick={() => {
              if (formRef.current) {
                formRef.current.handleSubmit();
              }
            }}
          >
            Valider
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
