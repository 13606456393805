import { Card, CardBody, SimpleGrid, StyleProps } from '@chakra-ui/react';
import { Tournoi } from '../tournoi.types';
import { TournoiCard } from './TournoiCard';
import { BPC_CARD_WIDTH } from 'common/common.constants';

export default function TournoiList({
  tournois,
  ...restStyleProps
}: {
  tournois: Tournoi[];
} & StyleProps) {
  return (
    <SimpleGrid columns={{ base: 2, md: 3, lg: 4 }} {...restStyleProps}>
      {tournois.length ? (
        tournois.map((tournoi) => (
          <TournoiCard
            key={tournoi.id}
            border="1px"
            borderColor="gray.200"
            minWidth={{ base: 'unset', md: BPC_CARD_WIDTH }}
            maxWidth={{ base: 'unset', md: BPC_CARD_WIDTH }}
            m={4}
            tournoi={tournoi}
          />
        ))
      ) : (
        <Card m={4}>
          <CardBody>Aucun tournoi à afficher</CardBody>
        </Card>
      )}
    </SimpleGrid>
  );
}
