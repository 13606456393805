export function formatPercentage(numberToFormat: number, nbDigit = 0) {
  return Intl.NumberFormat('fr-FR', {
    style: 'percent',
    maximumFractionDigits: nbDigit,
  }).format(numberToFormat);
}

export function formatMoney(numberToFormat: number) {
  return Intl.NumberFormat('fr-FR', {
    style: 'currency',
    currency: 'EUR',
  }).format(numberToFormat);
}

export function formatNumber2Digits(numberToFormat: number = 0) {
  return Math.round(numberToFormat * 100) / 100;
}

export function formatNumber1Digit(numberToFormat: number = 0) {
  return Math.round(numberToFormat * 10) / 10;
}
