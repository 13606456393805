import dayjs from 'dayjs';

export function fromUnixToDayjs(unixTimestamp: number) {
  return dayjs(unixTimestamp * 1000);
}

export function getUnixTimestamp(date?: Date) {
  return dayjs(date).unix();
}

// ex: 02 sept 2023 15:22:00
export function formatDateWithDayAndHourSec(date: dayjs.Dayjs | Date) {
  const dateAsDayJs = dayjs(date);
  return dateAsDayJs.format('DD MMM YYYY HH:mm:ss');
}

// ex: 02/09
export function formatDateWithDayMonth(date: dayjs.Dayjs | Date) {
  const dateAsDayJs = dayjs(date);
  return dateAsDayJs.format('DD/MM');
}

// ex: sam. 02 sept
export function formatDateWithDayNameDayMonth(date: dayjs.Dayjs | Date) {
  const dateAsDayJs = dayjs(date);
  return dateAsDayJs.format('ddd DD MMM');
}

// ex: sam. 02 sept 2023
export function formatDateWithDayNameDayMonthYear(date: dayjs.Dayjs | Date) {
  const dateAsDayJs = dayjs(date);
  return dateAsDayJs.format('ddd DD MMM YYYY');
}

//ex: 90min => 1h30min
export function formatMinutesToHourAndMin(minutes: number) {
  const nbHour = Math.floor(minutes / 60);
  const remainingMin = minutes - 60 * nbHour;
  return `${nbHour > 0 ? `${nbHour}h` : ''}${remainingMin}min`;
}

//ex: 1880sec => 31min20sec
export function formatSecondsToHourdMinAndSec(seconds: number) {
  const nbMin = Math.floor(seconds / 60);
  const nbHour = Math.floor(nbMin / 60);
  const remainingMin = nbMin - 60 * nbHour;
  const remainingSec = seconds - 60 * nbMin;
  return `${nbHour > 0 ? `${nbHour}h` : ''}${
    nbMin > 0 ? `${remainingMin}min ` : ''
  }${remainingSec}sec`;
}

//ex: 1880sec => 31:20
export function formatSecondsMinSec2Digits(seconds: number) {
  const nbMin = Math.floor(seconds / 60);
  const remainingSec = seconds - 60 * nbMin;
  return `${nbMin < 10 ? '0' : ''}${nbMin} : ${
    remainingSec < 10 ? '0' : ''
  }${remainingSec}`;
}
