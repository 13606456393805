import { Box, IconButton, StyleProps } from '@chakra-ui/react';
import TournoiPlayerAvatar from './TournoiPlayerAvatar';
import { FaEdit } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from 'router/routes.paths';
import { useConnectedUserContext } from 'features/user/useConnectedUserContext';
import BPCTable from 'common/components/BPCTable';
import { useMemo } from 'react';

export type JoueurForList = {
  id: string;
  name: string;
  photo?: string;
  nbTournoiPlayed: number;
  nbItm: number;
  nbVictoires: number;
  nbKills: number;
};

export default function JoueursList({
  players,
  ...restStyleProps
}: { players: JoueurForList[] } & StyleProps) {
  const navigate = useNavigate();
  const { user } = useConnectedUserContext();

  const playersTableColumns = [
    {
      property: 'avatar' as const,
      defaultSortingAsc: false,
      name: '',
      type: 'custom' as const,
    },
    { property: 'name' as const, name: 'Nom', type: 'text' as const },
    {
      property: 'nbTournoiPlayed' as const,
      name: 'Tournois',
      type: 'nombre' as const,
    },
    { property: 'nbItm' as const, name: 'ITM', type: 'nombre' as const },
    {
      property: 'nbKills' as const,
      name: 'Kills',
      type: 'nombre' as const,
    },
    { property: 'editButton' as const, name: '', type: 'custom' as const },
  ];

  const playersTableData = useMemo(
    () =>
      players.map(
        (
          { id, name, photo, nbTournoiPlayed, nbItm, nbVictoires, nbKills },
          i,
        ) => ({
          position: i + 1,
          avatar: <TournoiPlayerAvatar player={{ name, photo }} />,
          name,
          nbTournoiPlayed,
          nbItm,
          nbVictoires,
          nbKills,
          editButton:
            user?.id === id || user?.role === 'admin' ? (
              <IconButton
                aria-label="Edit"
                icon={<FaEdit />}
                onClick={() => navigate(`${RoutePath.joueurs}/${id}/edition`)}
              />
            ) : null,
        }),
      ),
    [navigate, players, user],
  );

  return (
    <Box {...restStyleProps}>
      <BPCTable
        stickyFirstCol
        stickyHeader
        data={playersTableData}
        columns={playersTableColumns}
        defaultSortBy={{ property: 'nbTournoiPlayed', asc: false }}
      />
    </Box>
  );
}
