/* eslint-disable func-names */
import * as Yup from 'yup';

Yup.setLocale({
  mixed: {
    required: () => {
      return `Le champ est requis`;
    },
  },
});

export { Yup as BPCYup };
