import {
  collection as collectionFirebase,
  getDocs as getDocsFirebase,
} from 'firebase/firestore';
import { firebase_db } from '../firebase.config';
import { useEffect, useState } from 'react';
import {
  BPCCollection,
  addIdOnDoc,
  transformCollectionForFirestore,
} from './firestore.helpers';

export const getCollection = async <T>(collection: BPCCollection) => {
  const data = await getDocsFirebase(
    collectionFirebase(
      firebase_db,
      ...transformCollectionForFirestore(collection),
    ),
  );
  return data.docs.map(addIdOnDoc) as T[];
};

export function useGetCollection<T>(
  collection: BPCCollection,
  options?: Partial<{ skip: boolean }>,
) {
  const { skip = false } = options ?? {};
  const [data, setData] = useState<T[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    async function getData() {
      setIsLoading(true);
      const dataFetched = await getCollection<T>(collection);
      setData(dataFetched);
      setIsLoading(false);
    }
    if (!skip) {
      getData();
    }
  }, [collection, skip]);
  return { data, isLoading };
}
