import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  UseDisclosureReturn,
  Checkbox,
  CheckboxGroup,
  SimpleGrid,
} from '@chakra-ui/react';
import { ChangeEvent, useMemo, useState } from 'react';
import { TournoiPlayer } from 'features/tournois/tournoi.types';
import { useGetPlayersList } from '../players.api';
import Loader from 'common/components/Loader';
import { mapPlayerToNewTournoiPlayer } from '../players.mappers';
import TournoiPlayerAvatar from './TournoiPlayerAvatar';
import { useGetTournoiList } from 'features/tournois/tournoi.api';

export default function AjouterJoueursModal({
  playersInTournament,
  isOpen,
  onClose,
  onSubmit,
  tournoiBuyIn,
}: {
  playersInTournament: TournoiPlayer[];
  tournoiBuyIn: number;
  onSubmit: (newPlayers: TournoiPlayer[]) => void;
} & Pick<UseDisclosureReturn, 'onClose' | 'isOpen'>) {
  const { data: playerList, isLoading: isPlayersLoading } = useGetPlayersList();
  const { tournois, isLoading: isTournoisLoading } = useGetTournoiList();
  const [selectedPlayerIds, setSelectedPlayerIds] = useState<string[]>([]);
  const filteredPlayersList = useMemo(() => {
    const playersInTournamentIds = playersInTournament.map(({ id }) => id);
    return (
      playerList
        .filter(({ id }) => !playersInTournamentIds.includes(id))
        .map(
          (p) =>
            ({
              ...mapPlayerToNewTournoiPlayer(p, tournoiBuyIn),
              nbTournoiPlayed: tournois?.filter((t) =>
                t.players.some((tp) => tp.id === p.id),
              ).length,
            }) as TournoiPlayer,
        )
        //sort by nbTournoiPlayed then by name
        .sort((a, b) => {
          if (a.nbTournoiPlayed === b.nbTournoiPlayed) {
            return a.name.localeCompare(b.name);
          }
          return (b.nbTournoiPlayed ?? 0) - (a.nbTournoiPlayed ?? 0);
        })
    );
  }, [playerList, playersInTournament, tournoiBuyIn, tournois]);

  function handleCheckboxClick(playerId: string) {
    return function (event: ChangeEvent<HTMLInputElement>) {
      const isChecked = event.target.checked;
      if (isChecked) {
        setSelectedPlayerIds([...selectedPlayerIds, playerId]);
      } else {
        setSelectedPlayerIds(selectedPlayerIds.filter((id) => id !== playerId));
      }
    };
  }

  function handleSubmit() {
    onClose();
    const newPlayers = [
      ...playersInTournament,
      ...filteredPlayersList.filter(({ id }) => selectedPlayerIds.includes(id)),
    ];
    return onSubmit(newPlayers);
  }

  if (isPlayersLoading || isTournoisLoading) {
    return <Loader />;
  }
  return (
    <Modal isOpen={isOpen} onClose={onClose!}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Ajouter des joueurs</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <CheckboxGroup>
            <SimpleGrid columns={2} gap={5}>
              {filteredPlayersList.map((player) => (
                <Checkbox
                  key={player.id}
                  checked={selectedPlayerIds.includes(player.id)}
                  onChange={handleCheckboxClick(player.id)}
                >
                  <TournoiPlayerAvatar player={player} namePosition="right" />
                </Checkbox>
              ))}
            </SimpleGrid>
          </CheckboxGroup>
        </ModalBody>

        <ModalFooter>
          <Button variant="ghost" mr={3} onClick={onClose}>
            Annuler
          </Button>
          <Button onClick={handleSubmit}>Valider</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
