import { StyleProps, Box, Button, ButtonGroup } from '@chakra-ui/react';
import { FC, useState } from 'react';

import { TournoiHistoryTable } from './TournoiHistoryTable';
import { annulerDernierKill } from '../live/tournoi.live.helpers';
import { TournoiLive } from '../live/tournoi.live.types';
import AjouterKillButtonLive from './AjouterKillButtonLive';

export const TournoiHistoryCard: FC<
  {
    tournoi: TournoiLive;
    buttonGroupProps?: StyleProps;
    withHourSelect?: boolean;
  } & StyleProps
> = ({ tournoi, buttonGroupProps, withHourSelect, ...restStyleProps }) => {
  const [isRemoveLoading, setIsRemoveLoading] = useState(false);

  async function onAnnulerDernierKill() {
    setIsRemoveLoading(true);
    await annulerDernierKill(tournoi);
    setIsRemoveLoading(false);
  }
  return (
    <Box {...restStyleProps}>
      <ButtonGroup {...buttonGroupProps}>
        <AjouterKillButtonLive
          tournoi={tournoi}
          withHourSelect={withHourSelect}
        />
        <Button
          isDisabled={!tournoi.killsHistory || !tournoi.killsHistory.length}
          isLoading={isRemoveLoading}
          onClick={onAnnulerDernierKill}
          variant={'outline'}
        >
          Annuler le dernier kill
        </Button>
      </ButtonGroup>
      <TournoiHistoryTable tournoi={tournoi} />
    </Box>
  );
};
