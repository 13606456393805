import { QueryDocumentSnapshot } from 'firebase/firestore';

export type BPCCollection = string | string[];

export function addIdOnDoc(doc: QueryDocumentSnapshot) {
  return {
    ...doc.data(),
    id: doc.id,
  };
}

export function transformCollectionForFirestore(
  collection: BPCCollection,
): [string, ...string[]] {
  const collectionAsArray =
    typeof collection === 'string' ? [collection] : [...collection];

  const firstCollection = collectionAsArray.shift();
  if (!firstCollection) {
    throw new Error(
      `Impossible de créer un doc dans la collection ${collection}`,
    );
  }
  return [firstCollection, ...collectionAsArray];
}
