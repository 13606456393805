import { Link as ReactRouterLink, To } from 'react-router-dom';
import { Link as ChakraLink, LinkProps } from '@chakra-ui/react';
import { FC, PropsWithChildren } from 'react';

export const BPCLink: FC<
  PropsWithChildren<Omit<LinkProps, 'as'> & { to: To }>
> = ({ children, to, ...restLinkProps }) => (
  <ChakraLink as={ReactRouterLink} to={to} {...restLinkProps}>
    {children}
  </ChakraLink>
);
