import { FieldArray, Formik, ArrayHelpers, Field } from 'formik';
import {
  Button,
  VStack,
  Box,
  Alert,
  AlertIcon,
  AlertDescription,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Icon,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  useDisclosure,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogBody,
  AlertDialog,
  AlertDialogFooter,
  AlertDialogHeader,
  ButtonGroup,
  Flex,
  Heading,
  FormControl,
  FormLabel,
  Select,
} from '@chakra-ui/react';
import { useState, useRef, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { StructureLevel, Tournoi } from '../tournoi.types';
import dayjs from 'dayjs';
import { RoutePath } from 'router/routes.paths';
import { BPCYup } from 'common/form.validation';
import { BPCInput } from 'common/components/form/BPCInput';
import { BPCDatePicker } from 'common/components/form/BPCDatePicker';
import { FaArrowDown, FaArrowUp, FaEdit, FaTrash } from 'react-icons/fa';
import { FiCoffee } from 'react-icons/fi';
import { getLevelNameAtIndex, getStructureDetails } from '../structure.helper';
import { formatMinutesToHourAndMin } from 'common/date.helper';
import NouveauNiveauModal, {
  DEFAULT_BIG_BLIND,
  DEFAULT_DUREE_NIVEAU,
  DEFAULT_DUREE_PAUSE,
  DEFAULT_SMALL_BLIND,
} from './NouveauNiveauModal';
import EditNiveauModal from './EditNiveauModal';
import AjouterJoueursModal from 'features/players/components/AjouterJoueursModal';
import { formatMoney } from 'common/string.helper';
import { computeMoneyFromPriceList } from '../tournoi.helper';
import { useGetChampionnatList } from 'features/championnats/championnat.api';
import { upsertTournoi } from '../tournoi.api';
import TournoiPlayerAvatar from 'features/players/components/TournoiPlayerAvatar';
import CreerJoueurButton from 'features/players/components/CreerJoueurButton';
import { mapPlayerToNewTournoiPlayer } from 'features/players/players.mappers';

export type NouveauTournoiFormValues = Omit<
  Tournoi,
  'id' | 'championnatIds' | 'type'
> & {
  championnatId?: string;
};

const TournoiSchema = BPCYup.object().shape({
  name: BPCYup.string().min(3, 'Le nom doit contenir au moins 3 caractères'),
  date: BPCYup.date().required(),
  championnatId: BPCYup.string(),
  montantBuyIn: BPCYup.number()
    .min(1, 'Le buy-in ne peut pas être inférieur à 1€')
    .required(),
  jetonsRecus: BPCYup.number()
    .min(100, 'Le nombre de jetons reçus ne peut pas être inférieur à 100')
    .required(),
  nbRecavesMax: BPCYup.string(),
  //V2: valider la structure
  structure: BPCYup.array()
    .required()
    .min(1, 'La structure doit comporter au moins 1 niveau'),
  players: BPCYup.array()
    .required()
    .min(2, 'Le tournoi doit avoir au moins 2 joueurs'),
  prizePool: BPCYup.array()
    .of(BPCYup.number().required().min(1, 'Le prix doit être supérieur à 1%'))
    .required()
    .min(1, 'Le tournoi doit avoir au moins 1 prix')
    .test('sum', 'La somme des prix doit être égal à 100%', (prizes = []) => {
      const total = prizes.reduce((total, prize) => total + prize, 0);
      return total === 100;
    }),
});

const defaultStructure: StructureLevel[] = [
  {
    smallBlind: DEFAULT_SMALL_BLIND,
    bigBlind: DEFAULT_BIG_BLIND,
    duree: DEFAULT_DUREE_NIVEAU,
    isPause: false,
  },
  {
    smallBlind: -1,
    bigBlind: -1,
    duree: DEFAULT_DUREE_PAUSE,
    isPause: true,
  },
  {
    smallBlind: DEFAULT_SMALL_BLIND * 2,
    bigBlind: DEFAULT_BIG_BLIND * 2,
    duree: DEFAULT_DUREE_NIVEAU,
    isPause: false,
  },
];

const defaultInitialValues: NouveauTournoiFormValues = {
  name: undefined,
  date: dayjs().toDate(),
  montantBuyIn: 10,
  jetonsRecus: 30000,
  nbRecavesMax: 1,
  structure: defaultStructure,
  players: [],
  prizePool: [100],
  status: 'notStarted',
  killsHistory: [],
  elapsedSecInTournament: 0,
};

const AUCUN_CHAMPIONNAT_ID = 'aucun-championnat-id';
export default function NouveauTournoiForm({
  tournoi,
  submitButtonLabel = 'Créer le tournoi',
  onCancel,
}: Readonly<{
  tournoi?: NouveauTournoiFormValues;
  submitButtonLabel?: string;
  onCancel?: () => void;
}>) {
  const nouveauNiveauDisclosure = useDisclosure();
  const nouvellePauseDisclosure = useDisclosure();
  const editionNiveauDisclosure = useDisclosure();

  const ajouterJoueursDisclosure = useDisclosure();
  const dernierNiveauSuprimeDisclosure = useDisclosure();
  const dernierPrixSuprimeDisclosure = useDisclosure();

  const cancelRefNiveau = useRef(null);
  const cancelRefPrix = useRef(null);

  const [isLoading, setIsLoading] = useState(false);
  const [levelEditionIndex, setLevelEditionIndex] = useState(0);
  const [errorMessage, setErrorMessage] = useState<string>();
  const navigate = useNavigate();

  const { championnats } = useGetChampionnatList();

  const initialValues: NouveauTournoiFormValues = useMemo(() => {
    const {
      name,
      prizePool,
      championnatId: existingChampionnatId,
      ...restTournoi
    } = tournoi || {};
    const championnatId = existingChampionnatId
      ? existingChampionnatId
      : championnats.length > 0
      ? championnats[championnats.length - 1].id
      : undefined;
    return {
      ...defaultInitialValues,
      name: name ?? '',
      championnatId,
      prizePool:
        prizePool?.map((p) => p * 100) ?? defaultInitialValues.prizePool,
      ...restTournoi,
    };
  }, [championnats, tournoi]);

  function getStructureTitleLabel(structure: StructureLevel[]) {
    const {
      timeInMin: { total: totalTime },
      level: { inPlay, inPause },
    } = getStructureDetails(structure);
    return `Structure (${inPlay} niveau${
      inPlay > 1 ? 'x' : ''
    }, ${inPause} pause${inPause > 1 ? 's' : ''}, ${formatMinutesToHourAndMin(
      totalTime,
    )})`;
  }

  return (
    <Box pb={2}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={TournoiSchema}
        onSubmit={async (tournoiToSave: NouveauTournoiFormValues) => {
          setErrorMessage(undefined);
          setIsLoading(true);
          const { prizePool, championnatId, ...restTournoi } = tournoiToSave;
          const mappedTournoiToSave: Omit<Tournoi, 'id' | 'type'> = {
            ...restTournoi,
            prizePool: prizePool.map((montant) => montant / 100),
            championnatIds:
              !championnatId || championnatId === AUCUN_CHAMPIONNAT_ID
                ? []
                : [championnatId],
          };
          try {
            const id = await upsertTournoi(mappedTournoiToSave);
            navigate(`${RoutePath.tournois}/${id}`);
          } catch (e: any) {
            const { code, message } = e;
            console.error(
              `Erreur lors de la création du tournoi:${code} message:${message}`,
            );
            setErrorMessage(message);
          }
          setIsLoading(false);
        }}
      >
        {({ handleSubmit, values, setFieldValue, errors }) => {
          return (
            <form onSubmit={handleSubmit}>
              <VStack spacing={4} align="strech">
                {errorMessage ? (
                  <Alert status="error">
                    <AlertIcon />
                    <AlertDescription>{errorMessage}</AlertDescription>
                  </Alert>
                ) : undefined}
                <BPCInput fieldName="name" label="Nom" type="text" />
                <BPCDatePicker fieldName="date" label="Date" isRequired />
                <FormControl>
                  <FormLabel htmlFor="championnatId">Championnat</FormLabel>
                  <Field as={Select} id="championnatId" name="championnatId">
                    <option value={AUCUN_CHAMPIONNAT_ID}>Aucun</option>
                    {championnats.map(({ name, id }) => (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    ))}
                  </Field>
                </FormControl>
                <BPCInput
                  fieldName="montantBuyIn"
                  label="Buy-in"
                  type="number"
                  suffix="€"
                  inputProps={{
                    min: 0,
                    step: 5,
                  }}
                  isRequired
                />
                <BPCInput
                  fieldName="jetonsRecus"
                  label="Nombre de jetons reçus"
                  type="number"
                  isRequired
                />
                <BPCInput
                  fieldName="nbRecavesMax"
                  label="Nombre de recave maximum"
                  type="number"
                />
                <Accordion defaultIndex={[0, 1]} allowMultiple>
                  <AccordionItem>
                    <AccordionButton>
                      <Box as="span" flex="1" textAlign="left">
                        <Heading size="md">
                          {`Joueurs (${values.players.length} selectionné${
                            values.players.length > 1 ? 's' : ''
                          })`}
                        </Heading>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>

                    <AccordionPanel pb={4}>
                      <>
                        <Button
                          onClick={ajouterJoueursDisclosure.onOpen}
                          mr={3}
                        >
                          Ajouter un joueur
                        </Button>
                        <AjouterJoueursModal
                          playersInTournament={values.players}
                          isOpen={ajouterJoueursDisclosure.isOpen}
                          onClose={ajouterJoueursDisclosure.onClose}
                          onSubmit={(newPlayers) => {
                            setFieldValue('players', newPlayers);
                          }}
                          tournoiBuyIn={values.montantBuyIn}
                        />
                      </>
                      <CreerJoueurButton
                        onSubmit={(newPlayer) => {
                          const newTournoiPlayer = mapPlayerToNewTournoiPlayer(
                            newPlayer,
                            values.montantBuyIn,
                          );
                          setFieldValue('players', [
                            ...values.players,
                            newTournoiPlayer,
                          ]);
                        }}
                      />

                      <FieldArray
                        name="players"
                        render={(arrayHelpers: ArrayHelpers) => (
                          <>
                            {typeof errors.players === 'string' ? (
                              <Box color="red.500">{errors.players}</Box>
                            ) : null}

                            {values.players.length ? (
                              <TableContainer mt={3}>
                                <Table variant="simple" size="md">
                                  <Tbody>
                                    {values.players.map((player, index) => (
                                      <Tr key={index}>
                                        <Td>
                                          <TournoiPlayerAvatar
                                            player={{
                                              name: player.name,
                                              photo: player.photo,
                                            }}
                                            namePosition="right"
                                          />
                                        </Td>

                                        <Td>
                                          <Button
                                            type="button"
                                            onClick={() =>
                                              arrayHelpers.remove(index)
                                            }
                                            size="xs"
                                          >
                                            <Icon as={FaTrash} />
                                          </Button>
                                        </Td>
                                      </Tr>
                                    ))}
                                  </Tbody>
                                </Table>
                              </TableContainer>
                            ) : (
                              <Box mt={3}>Aucun joueur ajouté</Box>
                            )}
                          </>
                        )}
                      />
                    </AccordionPanel>
                  </AccordionItem>

                  <AccordionItem mt={4}>
                    <AccordionButton>
                      <Box as="span" flex="1" textAlign="left">
                        <Heading size="md">{`Prize pool (${
                          values.prizePool.length
                        } place${values.prizePool.length > 1 ? 's' : ''} payée${
                          values.prizePool.length > 1 ? 's' : ''
                        })`}</Heading>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>

                    <AccordionPanel pb={4}>
                      {typeof errors.prizePool === 'string' ? (
                        <Box color="red.500">{errors.prizePool}</Box>
                      ) : null}
                      <Button
                        onClick={() =>
                          setFieldValue('prizePool', [...values.prizePool, 0])
                        }
                        mr={3}
                      >
                        Ajouter un prix
                      </Button>
                      <FieldArray
                        name="prizePool"
                        render={(arrayHelpers: ArrayHelpers) => (
                          <TableContainer mt={3}>
                            <Table variant="simple" size="md">
                              <Thead>
                                <Tr>
                                  <Th>Place</Th>
                                  <Th isNumeric>Pourcentage</Th>
                                  <Th isNumeric>Montant</Th>
                                </Tr>
                              </Thead>
                              <Tbody>
                                {values.prizePool.map((price, index) => (
                                  <Tr key={index}>
                                    <Td>{index + 1}</Td>
                                    <Td isNumeric>
                                      <BPCInput
                                        fieldName={`prizePool.${index}`}
                                        type="number"
                                        suffix="%"
                                        inputProps={{
                                          min: 0,
                                          max: 100,
                                          step: 5,
                                        }}
                                      />
                                    </Td>
                                    <Td isNumeric>
                                      {formatMoney(
                                        computeMoneyFromPriceList({
                                          prices: values.prizePool.map(
                                            (p) => p / 100,
                                          ),
                                          priceIndex: index,
                                          partialTournoi: values,
                                        }),
                                      )}
                                    </Td>
                                    <Td>
                                      <Button
                                        type="button"
                                        onClick={() => {
                                          if (values.prizePool.length === 1) {
                                            dernierPrixSuprimeDisclosure.onOpen();
                                          } else {
                                            arrayHelpers.remove(index);
                                          }
                                        }}
                                        size="xs"
                                      >
                                        <Icon as={FaTrash} />
                                      </Button>
                                    </Td>
                                  </Tr>
                                ))}
                              </Tbody>
                              <AlertDialog
                                isOpen={dernierPrixSuprimeDisclosure.isOpen}
                                leastDestructiveRef={cancelRefPrix}
                                onClose={dernierPrixSuprimeDisclosure.onClose}
                              >
                                <AlertDialogOverlay>
                                  <AlertDialogContent>
                                    <AlertDialogHeader
                                      fontSize="lg"
                                      fontWeight="bold"
                                    >
                                      Attention
                                    </AlertDialogHeader>

                                    <AlertDialogBody>
                                      Il n'est pas possible de supprimer le
                                      dernier prix d'un tournoi
                                    </AlertDialogBody>

                                    <AlertDialogFooter>
                                      <Button
                                        ref={cancelRefPrix}
                                        onClick={
                                          dernierPrixSuprimeDisclosure.onClose
                                        }
                                      >
                                        Ok
                                      </Button>
                                    </AlertDialogFooter>
                                  </AlertDialogContent>
                                </AlertDialogOverlay>
                              </AlertDialog>
                            </Table>
                          </TableContainer>
                        )}
                      />
                    </AccordionPanel>
                  </AccordionItem>

                  <AccordionItem mt={4}>
                    <AccordionButton>
                      <Box as="span" flex="1" textAlign="left">
                        <Heading size="md">
                          {getStructureTitleLabel(values.structure)}
                        </Heading>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>

                    <AccordionPanel>
                      <>
                        <Button onClick={nouveauNiveauDisclosure.onOpen} mr={3}>
                          Nouveau niveau
                        </Button>
                        <NouveauNiveauModal
                          structure={values.structure}
                          isPause={false}
                          isOpen={nouveauNiveauDisclosure.isOpen}
                          onClose={nouveauNiveauDisclosure.onClose}
                          onSubmit={(newStructure) => {
                            setFieldValue('structure', newStructure);
                          }}
                        />
                      </>
                      <>
                        <Button onClick={nouvellePauseDisclosure.onOpen}>
                          Nouvelle pause
                        </Button>
                        <NouveauNiveauModal
                          structure={values.structure}
                          isPause={true}
                          isOpen={nouvellePauseDisclosure.isOpen}
                          onClose={nouvellePauseDisclosure.onClose}
                          onSubmit={(newStructure) => {
                            setFieldValue('structure', newStructure);
                          }}
                        />
                      </>
                      <FieldArray
                        name="structure"
                        render={(arrayHelpers: ArrayHelpers) => (
                          <>
                            {typeof errors.structure === 'string' ? (
                              <Box color="red.500">{errors.structure}</Box>
                            ) : null}

                            <TableContainer mt={3}>
                              <Table variant="simple" size="md">
                                <Thead>
                                  <Tr>
                                    <Th>Niveau</Th>
                                    <Th textAlign="center">Blindes</Th>
                                    <Th isNumeric>Duree (min)</Th>
                                  </Tr>
                                </Thead>
                                <Tbody>
                                  {values.structure.map(
                                    (
                                      { smallBlind, bigBlind, duree, isPause },
                                      index,
                                    ) => (
                                      <Tr
                                        key={index}
                                        color={
                                          isPause ? 'orange.500' : undefined
                                        }
                                      >
                                        <Td>
                                          <Flex alignItems={'center'}>
                                            {getLevelNameAtIndex(
                                              values.structure,
                                              index,
                                            )}
                                            {isPause && (
                                              <ButtonGroup
                                                isAttached
                                                flexDir={'column'}
                                                size={'xs'}
                                                variant={'ghost'}
                                              >
                                                <Button
                                                  isDisabled={index === 0}
                                                  onClick={() => {
                                                    arrayHelpers.swap(
                                                      index,
                                                      index - 1,
                                                    );
                                                  }}
                                                >
                                                  <Icon as={FaArrowUp} />
                                                </Button>
                                                <Button
                                                  isDisabled={
                                                    index ===
                                                    values.structure.length - 1
                                                  }
                                                  onClick={() => {
                                                    arrayHelpers.swap(
                                                      index,
                                                      index + 1,
                                                    );
                                                  }}
                                                >
                                                  <Icon as={FaArrowDown} />
                                                </Button>
                                              </ButtonGroup>
                                            )}
                                          </Flex>
                                        </Td>
                                        <Td textAlign="center">
                                          {isPause ? (
                                            <Icon as={FiCoffee} />
                                          ) : (
                                            `${smallBlind}/${bigBlind}`
                                          )}
                                        </Td>
                                        <Td isNumeric>{duree}</Td>
                                        <Td>
                                          <Button
                                            onClick={() => {
                                              setLevelEditionIndex(index);
                                              editionNiveauDisclosure.onOpen();
                                            }}
                                            size="xs"
                                            mr={2}
                                          >
                                            <Icon as={FaEdit} />
                                          </Button>

                                          <Button
                                            type="button"
                                            onClick={() => {
                                              if (
                                                values.structure.length === 1
                                              ) {
                                                dernierNiveauSuprimeDisclosure.onOpen();
                                              } else {
                                                arrayHelpers.remove(index);
                                              }
                                            }}
                                            size="xs"
                                          >
                                            <Icon as={FaTrash} />
                                          </Button>
                                        </Td>
                                      </Tr>
                                    ),
                                  )}
                                  <EditNiveauModal
                                    key={`Edition${levelEditionIndex}`}
                                    levelIndex={levelEditionIndex}
                                    structure={values.structure}
                                    isOpen={editionNiveauDisclosure.isOpen}
                                    onClose={editionNiveauDisclosure.onClose}
                                    onSubmit={(newStructure) => {
                                      setFieldValue('structure', newStructure);
                                    }}
                                  />
                                  <AlertDialog
                                    isOpen={
                                      dernierNiveauSuprimeDisclosure.isOpen
                                    }
                                    leastDestructiveRef={cancelRefNiveau}
                                    onClose={
                                      dernierNiveauSuprimeDisclosure.onClose
                                    }
                                  >
                                    <AlertDialogOverlay>
                                      <AlertDialogContent>
                                        <AlertDialogHeader
                                          fontSize="lg"
                                          fontWeight="bold"
                                        >
                                          Attention
                                        </AlertDialogHeader>

                                        <AlertDialogBody>
                                          Il n'est pas possible de supprimer le
                                          dernier niveau d'une structure
                                        </AlertDialogBody>

                                        <AlertDialogFooter>
                                          <Button
                                            ref={cancelRefNiveau}
                                            onClick={
                                              dernierNiveauSuprimeDisclosure.onClose
                                            }
                                          >
                                            Ok
                                          </Button>
                                        </AlertDialogFooter>
                                      </AlertDialogContent>
                                    </AlertDialogOverlay>
                                  </AlertDialog>
                                </Tbody>
                              </Table>
                            </TableContainer>
                          </>
                        )}
                      />
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
                <ButtonGroup gap={4} mt={4}>
                  {onCancel && (
                    <Button width="full" onClick={onCancel} variant={'outline'}>
                      Annuler
                    </Button>
                  )}
                  <Button isLoading={isLoading} type="submit" width="full">
                    {submitButtonLabel}
                  </Button>
                </ButtonGroup>
              </VStack>
            </form>
          );
        }}
      </Formik>
    </Box>
  );
}
