import { Icon } from '@chakra-ui/react';
import { FaCross, FaMoneyBill, FaTrophy } from 'react-icons/fa';
import { GiPoliceTarget } from 'react-icons/gi';

export type HistoryType = 'kill' | 'recave' | 'win' | 'error';
export function getHistoryIconForType(type: HistoryType) {
  return type === 'recave' ? (
    <Icon as={FaMoneyBill} boxSize={8} />
  ) : type === 'kill' ? (
    <Icon as={GiPoliceTarget} boxSize={8} />
  ) : type === 'win' ? (
    <Icon as={FaTrophy} boxSize={8} />
  ) : (
    <Icon as={FaCross} boxSize={8} />
  );
}
