import { useGetDocById } from 'api/firestore/getDocById';
import { createDoc } from 'api/firestore/createDoc';
import { updateDoc } from 'api/firestore/updateDoc';
import {
  OUTILS_COLLECTION_NAME,
  RANGES_COLLECTION_NAME,
} from 'api/collectionNames.constants';
import { useEffect, useMemo, useState } from 'react';
import { PokerRangeFirebase } from './ranges.type';
import { useConnectedUserContext } from 'features/user/useConnectedUserContext';
import { useGetCollection } from 'api/firestore/getCollection';

export async function createPokerRange(
  range: Omit<PokerRangeFirebase, 'id'>,
  userId: string,
) {
  return (
    await createDoc<PokerRangeFirebase>(
      [OUTILS_COLLECTION_NAME, userId, RANGES_COLLECTION_NAME],
      range,
    )
  ).id;
}

export function updatePokerRange(range: PokerRangeFirebase, userId: string) {
  return updateDoc<PokerRangeFirebase>(
    [OUTILS_COLLECTION_NAME, userId, RANGES_COLLECTION_NAME],
    range,
  );
}

export function useGetUserPokerRanges() {
  const { user } = useConnectedUserContext();
  const [ranges, setRanges] = useState<PokerRangeFirebase[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const collection = useMemo(
    () =>
      user?.id ? [OUTILS_COLLECTION_NAME, user.id, RANGES_COLLECTION_NAME] : [],
    [user?.id],
  );
  const { data, isLoading: isRangesLoading } =
    useGetCollection<PokerRangeFirebase>(collection, { skip: !user?.id });
  useEffect(() => {
    if (!isRangesLoading) {
      setRanges(data ?? []);
      setIsLoading(false);
    }
  }, [data, isRangesLoading]);
  return { ranges, isLoading };
}

export function useGetRangeById(rangeId?: string) {
  const { user } = useConnectedUserContext();
  const collection = useMemo(
    () =>
      user?.id ? [OUTILS_COLLECTION_NAME, user.id, RANGES_COLLECTION_NAME] : [],
    [user?.id],
  );
  const { data: range, isLoading } = useGetDocById<PokerRangeFirebase>(
    collection,
    rangeId,
    { skip: !user?.id },
  );
  return { range, isLoading };
}
